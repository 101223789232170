.arrears_main_wrapper {
  height: 100%;
  background-color: #fff;
  padding: 25px;
  border-radius: clamp(15px, 1.3vw, 50px);

  @media screen and (min-width: 768px) and (max-width: 1024px),
  screen and (min-width: 1024px) and (max-width: 1366px) {
    padding: 15px 25px;
  }

  .arrear_header {
    display: flex;
    justify-content: space-between;
    height: 20%;

    >h1 {
      color: #1c1c1c;
      font-family: "Poppins", sans-serif;
      font-size: clamp(17px, 1.4vw, 60px);
      height: 100%;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    >button {
      height: fit-content;
      padding: clamp(8px, 0.9vh, 15px) clamp(18px, 1.4vw, 27px);
      border-radius: clamp(5px, 0.4vw, 15px);
      border: 1px solid #e1e9ee;
      color: #2d2d2d;
      font-family: "Poppins", sans-serif;
      font-size: clamp(13px, 1.15vw, 28px);
      font-style: normal;
      font-weight: 400;
      background-color: #fff;
    }
  }

  .graph_wrapper {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;
    height: 75%;
    position: relative;
    top: -21px;

    @media screen and (min-width: 768px) and (max-width: 1024px),
    screen and (min-width: 1024px) and (max-width: 1366px) {
      top: 24px;
    }

    @media screen and (min-width: 1920px) and (min-height: 1080px) {}

    @media screen and (max-height: 1010px) {
      position: static;
      top: 0px;
    }

    .graph {
      width: 46%;
      height: 84%;
      display: flex;
      justify-content: center;
      margin-top: -5px;
    }

    >ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      display: flex;
      flex-direction: column;
      width: 51%;
      gap: 15px;

      @media screen and (min-width: 768px) and (max-width: 1024px) {
        gap: 0px;
      }

      @media screen and (max-width: 780px) {
        gap: 0px;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px),
      screen and (min-width: 1024px) and (max-width: 1366px) {
        top: 24px;
      }

      // li:nth-of-type(4) {
      //   margin: 0;
      // }
      .animated:hover {
        transform: scale(1.03);
        box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
      }

      .animated {
        cursor: pointer;
      }

      .notAnimated {
        cursor: default;
      }

      .animated,
      .notAnimated {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 8px 10px 8px 10px;
        transition: background-position 0.2s ease, transform 0.2s ease-in-out;

        span {
          color: #2d2d2d;
          font-family: "Poppins", sans-serif;
          font-size: clamp(10px, 0.9vw, 24px);
          font-style: normal;
          font-weight: 400;
          display: flex;
          align-items: center;
          text-wrap: nowrap;

          .badge07 {
            width: max(8px, 0.7vw);
            height: max(8px, 0.7vw);
            background: rgba(0, 0, 71, 1);
            border-radius: 50%;
            margin-right: 7px;
          }

          .badge814 {
            width: max(8px, 0.7vw);
            height: max(8px, 0.7vw);
            background-color: rgba(77, 222, 135, 1);
            border-radius: 50%;
            margin-right: 7px;
          }

          .badge1530 {
            width: max(8px, 0.7vw);
            height: max(8px, 0.7vw);
            background-color: rgba(79, 195, 244, 1);
            border-radius: 50%;
            margin-right: 7px;
          }

          .badge31 {
            width: max(8px, 0.7vw);
            height: max(8px, 0.7vw);
            background-color: rgba(129, 126, 255, 1);
            border-radius: 50%;
            margin-right: 7px;
          }
        }

        >p {
          color: #2d2d2d;
          font-family: "Poppins", sans-serif;
          font-size: clamp(10px, 1vw, 28px);
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 0;
          text-wrap: nowrap;
        }
      }
    }
  }
}

.viewAll {
  position: relative;
  display: inline-block;
  padding: 50px max(13px, 1.25vw);
  border: none;
  background-color: #4fc3f4;
  color: white;
  cursor: pointer;
  overflow: hidden;
  border: 1px solid black;

  &:hover {
    background-color: rgba(79, 195, 244, 0.06);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;
    border: 2px solid white;
    transition: left 0.5s ease;
  }

  &:hover::before {
    left: 0;
  }

  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
    transition: all 0.2s ease;
  }
}

.viewAllMain {
  .cta {
    position: relative;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    cursor: pointer;
    color: #234567;
  }

  .cta:hover {
    color: white;
  }

  .cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: rgb(129, 126, 255);
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
    color: #000047;
  }

  .cta span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: inherit;
  }

  .cta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #000047;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
  }

  .cta:hover:before {
    width: 100%;
    background: rgb(129, 126, 255);
  }

  .cta:hover svg {
    transform: translateX(0);
  }

  .cta:active {
    transform: scale(0.95);
  }
}

@media screen and (max-width: 850px) {
  ul {
    li {

      span,
      p {
        // font-size: 10px !important;
      }
    }
  }
}

@media screen and (max-width: 1035px) {
  ul {
    li {

      span,
      p {
        // font-size: 10px !important;
      }
    }
  }
}