.compliance_List {
  // height: calc(100% - 221px);
  height: 100%;
  width: 100%;
  .toggle_header {
    width: 100%;
    height: 11%;
    border-radius: 0 15px 0px 0px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    p:nth-child(1) {
      height: 100%;
      font-family: "Poppins", sans-serif;
      font-size: max(18px, 1.3vw);
      font-weight: 500;
      line-height: 19.2px;
      text-align: center;
      width: 100%;
      padding: 7.3% 0px;
      cursor: pointer;
      color: rgb(45, 45, 45, 0.3);
      &.active {
        color: #817eff;
        border-bottom: 2px solid #817eff;
      }
    }
    p:nth-child(2) {
      margin-top: -0.01%;
      height: 100%;
      font-family: "Poppins", sans-serif;
      font-size: max(18px, 1.3vw);
      font-weight: 500;
      line-height: 19.2px;
      text-align: center;
      width: 100%;
      padding: 7.3% 0px;
      cursor: pointer;
      color: rgb(45, 45, 45, 0.3);
      &.active {
        color: #817eff;
        border-bottom: 2px solid #817eff;
      }
    }
  }

  .content_wrapper {
    // height: calc(100% - 200px);
    height: 87.5%;
    background-color: #fff;
    margin-top: 7px;
    // overflow: auto;
    border-bottom-right-radius: 20px;
    > ul {
      height: 100%;
      padding: 0;
      list-style: none;
      overflow: auto;
      .complainListIcons {
        background: #f3f3f7;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.3vw;
        height: 3.3vw;
        // border: 1px solid black;
        max-width: 110px;
        max-height: 110px;
        min-height: 35px;
        min-width: 35px;
        // padding: 25px;
        img {
          width: 40%;
          height: 40%;
        }
      }
      .inactive {
        width: 100%;
        height: 12%;
        padding: 0 max(15px, 1.25vw);
        // padding: 17px 24px 17px 35px;
        display: flex;
        align-items: center;
        gap: max(12px, 0.8vw);
        border-bottom: 2px solid rgb(247, 249, 251);
        cursor: pointer;

        p {
          font-family: "Poppins", sans-serif;
          font-size: max(14px, 1vw);
          font-weight: 400;
          line-height: 20.3px;
          text-align: left;
        }

        &:hover {
          background-color: rgb(247, 249, 251);
          color: #000;
        }
      }
      .active {
        width: 100%;
        height: 12%;
        // padding: 17px 24px 17px 35px;
        padding: 0 max(15px, 1.25vw);
        display: flex;
        align-items: center;
        gap: 25px;
        border-bottom: 2px solid rgb(247, 249, 251);
        cursor: pointer;

        p {
          font-family: "Poppins", sans-serif;
          font-size: max(14px, 1vw);
          font-weight: 400;
          line-height: 20.3px;
          text-align: left;
        }
        background-color: #817eff;
        color: #fff;
      }
    }
  }
}
