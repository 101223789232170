.paymentdue {
  padding: 17px 0;
  height: 100%;
  background-color: #f7f9fb;
  // height: calc(100% - 1px);
  overflow: auto;

  .custom-date-range-picker {
    border-radius: 4px;
    width: 300px;
  }

  .headerp {
    padding: 0 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    @media (max-width: "1055px") {
      flex-direction: column;
      align-items: flex-start;
    }

    .button_container {
      // margin: 15px;
      margin-top: 0;
      margin-left: 0;
      text-align: left;
      width: fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: 1300px) {
        margin-top: 10px;
      }

      .active {
        background: rgba(129, 126, 255, 1);
        color: #fff;
      }

      button {
        padding: 10px 13px;
        border-radius: 7px;
        border: 1px solid rgb(225, 233, 238);
        background-color: #fff;
        text-wrap: nowrap;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        margin-right: 15px;
        font-weight: 400;
        line-height: 17.4px;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1300px) {
          padding: 20px 13px;
        }

        span {
          padding: 5px 3px 5px 3px;
          border-radius: 3px;
          // background: rgba(230, 231, 255, 1);
          font-family: "Poppins", sans-serif;
          font-size: 13px;
          font-weight: 500;
          line-height: 13px;
          text-align: center;
          margin-left: 10px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #000 !important;
        }
      }

      button:nth-child(1) {
        span {
          background: rgba(230, 231, 255, 1);
        }
      }

      button:nth-child(2) {
        span {
          background: rgba(255, 233, 181, 1);
        }
      }

      button:nth-child(3) {
        span {
          background: rgba(206, 255, 225, 1);
        }
      }

      button:nth-child(4) {
        span {
          background: rgba(255, 211, 207, 1);
        }
      }

      button:nth-child(5) {
        span {
          background: rgba(223, 245, 255, 1);
        }
      }

      button:nth-child(6) {
        span {
          background: rgba(255, 206, 243, 1);
        }
      }

      button:nth-child(7) {
        span {
          background: rgba(168, 197, 218, 1);
        }
      }
    }

    .table_filter {
      // padding: 0px 32px 0px 32px;
      .upload_button {
        text-wrap: nowrap;
      }

      .rs-input-group.rs-input-group-inside {
        height: 49px;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 25px 0;

      @media screen and (max-width: 1300px) {
        margin: 7px 0 !important;
      }

      @media screen and (max-width: 950px) {
        flex-direction: column;
        gap: 15px;
      }

      .generate_report {
        padding: 13px 15px !important;
      }
    }
  }

  .table {
    overflow: auto;
    height: calc(100% - 0px);
    font-family: "Poppins", sans-serif;

    .custom-table {
      position: sticky;
      top: 0;
      border-bottom: 1px solid #f2f2f2 !important;
      z-index: 3;
      background-color: #f7f9fb;

      th:nth-of-type(1) {
        width: 20% !important;
      }

      th:nth-of-type(2) {
        width: 20% !important;
      }

      th:nth-of-type(3) {
        width: 30% !important;
      }

      th:nth-of-type(4) {
        width: 20% !important;
      }

      th:nth-of-type(5) {
        width: 10% !important;
      }
    }

    .contenttable {
      .subtable {
        width: 100%;
        border: none;

        thead {
          border: none;
        }

        thead tr {
          border: none !important;
        }

        thead tr th {
          background-color: #e1e9ee;
          font-family: "Poppins", sans-serif;
          font-size: 15px;
          font-weight: 500;
          line-height: 17.4px;
          text-align: left;
          text-transform: capitalize;
          padding: 15px 12px;
          border: none !important;

          @media screen and (max-width: 860px) {
            font-size: 10px;
          }
        }

        tbody {
          border-bottom: 1px solid var(--Secondary-Cyan, #a8c5da) !important;
        }

        tbody tr {
          border: none !important;
        }

        tbody tr td {
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 17.4px;
          text-align: left;
          padding: 25px 12px;
          width: 286px;
          border: none;
          text-transform: capitalize;
        }

        tbody tr:nth-child(odd) {
          background-color: #f7f9fb;
        }

        tbody tr:nth-child(even) {
          background-color: white;
        }

        tbody {
          tr {
            td:nth-of-type(1) {
              width: 20%;
            }

            td:nth-of-type(2) {
              width: 20%;
            }

            td:nth-of-type(3) {
              width: 20%;
            }

            td:nth-of-type(4) {
              width: 20%;
            }

            td:nth-of-type(5) {
              width: 20%;
            }
          }
        }

        .Pay {
          // width: 64px;
          // height: 29px;
          padding: 6px 21px 6px 21px;
          border-radius: 5px;
          font-family: "Poppins", sans-serif;
          font-size: 13px;
          font-weight: 400;
          line-height: 17.4px;
          text-align: left;
          background-color: #817eff;
          color: #fff;
          border: none;
        }

        .Paid {
          // width:69px;
          padding: 6px 21px 6px 21px;
          border-radius: 5px;
          background-color: #e1e9ee;
          font-family: "Poppins", sans-serif;
          font-size: 13px;
          font-weight: 400;
          line-height: 17.4px;
          text-align: left;
          color: #fff;
          border: none;
          cursor: not-allowed;
        }

        .PaymentDue {
          // width: 97px;
          border: none;
          padding: 6px 11px 6px 11px;
          border-radius: 5px;
          background-color: var(--Orange_light, #ffe9b5);
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 17.4px;
          text-align: left;
          color: #2d2d2d;
        }

        .PaymentProcessed {
          // width: 97px;
          border: none;
          padding: 6px 11px 6px 11px;
          border-radius: 5px;
          background-color: #ceffe1;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 17.4px;
          text-align: left;
          color: #2d2d2d;
        }
      }
    }
  }

  .tableComp {
    height: calc(100% - 93px);

    .main_table {
      height: calc(100% - 0px);
      overflow: auto;
      padding: 28px;

      @media screen and (min-width: 1920px) and (min-height: 1080px) {
        height: calc(100% - 110px);

        tbody tr td {
          font-size: 25px;
          padding: 35px 0px;
        }

        thead th div {
          font-size: 25px !important;
          padding: 25px 0px !important;
        }
      }

      thead {
        border-bottom: 1px solid #d9d9d9;
        // border-top: 1px solid rgba(0, 0, 0, 1);

        th:nth-of-type(1) {
          div {
            padding: 0 !important;
          }
        }
      }

      td:nth-of-type(1) {
        width: 10% !important;
      }

      td:nth-of-type(2) {
        width: 14% !important;
      }

      td:nth-of-type(3) {
        width: 14% !important;
      }

      td:nth-of-type(4) {
        width: 35% !important;
      }

      td:nth-of-type(5) {
        width: 14% !important;
      }

      td:nth-of-type(6) {
        width: 13% !important;
      }
    }
  }

  .card_parent {
    display: flex;
    gap: 33px;
    flex-wrap: wrap;
    padding: 0 10px;

    .card {
      cursor: pointer;
      // width: 246px;
      // width: 30%;
      // height: 86px;
      padding: 16px 20px;
      border-radius: 10px;
      border: none;
      justify-content: space-between;
      background-color: #eeeeff;
      color: #2d2d2d;
      transition: 0.3s;
      gap: 15px;

      @media screen and (max-width: 850px) {
        width: 100%;
      }

      &.active {
        background-color: #817eff;
        color: #fff;
      }

      p {
        font-family: "Poppins", sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 17.4px;
        text-align: left;
        // color: var(--Primary-Light, #F7F9FB);
      }

      h1 {
        font-family: "Poppins", sans-serif;
        font-size: 15px;
        font-weight: 300;
        line-height: 18px;
        text-align: left;
        // color: var(--Primary-Light, #F7F9FB);
        // margin: 10px 0px 0px 0px;
      }
    }
  }
}

@media (max-width: "1300px") {
  .report-search {
    width: 250px !important;
  }

  .rs-input {
    font-size: 14px !important;
  }

  .custom-date-range-picker {
    width: 300px !important;
  }
}

@media (max-width: "1000px") {
  .report-search {
    width: 200px !important;
  }
}

@media (max-width: "800px") {
  .report-search {
    width: 150px !important;
  }
}
