.client {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1%;
  overflow: hidden;
  padding: 5px;

  // border: 1px solid black;
  @media screen and (max-width: 900px) {
    // width: 90%;
  }

  .cli_acc_button {
    width: 100%;
    // height: 20%;
    // border: 1px solid black;
  }

  .cliaccHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h1 {
      text-wrap: nowrap !important;
      font-size: max(20px, 1.4vw);
    }

    .searchClientAmount {
      width: 200px;

      @media screen and (max-width: 900px) {
        // width: 65%;
      }
    }
  }

  .css-dev-only-do-not-override-j9bb5n {
    height: 100%;
  }

  h1 {
    font-family: "Poppins", sans-serif;

    font-size: 26px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
  }

  .table_headers {
    display: flex;
    justify-content: space-between;
    margin-top: 9px;
    width: 100%;

    .card {
      padding: 7px 8px;
      border-radius: 10px;
      background-color: #817eff;
      color: #fff;
      border: none;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      // width: fit-content;
      // height: 7vh;
      // max-height: 60px;
      // width: 50%;
      // max-width: 400px;
      // flex-wrap: wrap;
      // border: 1px solid black;

      img {
        width: max(25px, 2vw);
        height: max(25px, 2vw);
      }

      p {
        font-family: "Poppins", sans-serif;
        // font-size: 18px;
        font-size: max(14px, 1vw);
        font-weight: 500;
        line-height: 17.4px;
        text-align: left;
        text-wrap: nowrap;
        color: var(--Primary-Light, #f7f9fb);
      }

      h1 {
        font-family: "Poppins", sans-serif;
        // font-size: 24px;
        font-size: max(18px, 1.2vw);
        font-weight: 300;
        line-height: 18px;
        text-align: left;
        color: var(--Primary-Light, #f7f9fb);
        margin: 0px 0px 0px 0px;
      }
    }

    > div {
      display: flex;
      gap: 12px;
      justify-content: space-between;
      width: fit-content;
    }
  }

  .main_content {
    // margin-top: 12px;
    // height: calc(100% - 0px);
    flex: 1;
    // border: 1px solid red;
    overflow: hidden;

    @media screen and (max-width: 780px) {
      // height: calc(100% - 152px);
      // overflow-x: scroll;
      // width: 80%;
    }

    .main_table {
      height: 100%;
      padding: 0 !important;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // border: 1px solid black;
      @media screen and (max-width: 900px) {
        // height: calc(100% - 182px);
      }

      thead {
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
}

.table_content_InnerMain {
  height: calc(100% - 65px);
  @media screen and (min-width: 2000px) {
    height: calc(100% - 35px);
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
    height: calc(100% - 85px);
  }
  @media screen and (min-width: 600px) and (max-width: 800px) {
    height: calc(100% - 75px);
  }
  @media screen and (max-width: 600px) {
    height: calc(100% - 85px);
  }
}
