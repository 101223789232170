.tab-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: inherit;
  border-radius: 10px;
  // margin: 12px 0 0 0;
  font-weight: 500;

  @media screen and (max-width: 850px) {
    margin-top: 0 !important;
  }

  // padding: 20px 0;
  // height: 15%;
  // border: 1px solid black;

  .tab {
    padding: clamp(10px, 1px, 33px) 13px;
    cursor: pointer;
    font-size: clamp(13px, 1.1vw, 19px);
    position: relative;
    width: 60%;
    // width: 300px;
    // width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    // letter-spacing: 0.5px;
    height: 100%;
    // border: 1px solid black;

    &.active {
      color: #7c4dff;
      font-weight: 500;
      letter-spacing: 0.5px;

      &::after {
        width: 100%;
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      // bottom: 1px;
      transform: translateX(-50%);
      width: 0;
      height: 3px;
      background-color: #7c4dff;
      transition: width 0.3s ease-out;
    }
  }

  .tab:hover {
    background-color: rgb(124, 77, 255, 0.05);
    border-radius: 8px;
    color: #000000;
  }
}

@media (max-width: "1750px") {
  .tab-container {
    // margin: 15px 0 0 0 !important;
  }

  .ClientMoney .ClientMoney_main .tab-container .tab {
    padding: 22px 16px !important;
  }
}

@media (max-width: "1550px") {
  .tab-container {
    // margin: 10px 0 0 0 !important;
  }

  .ClientMoney .ClientMoney_main .tab-container .tab {
    padding: 19px 16px !important;
    // font-size: 16px !important;
  }
}

@media (max-width: "1300px") {
  .tab-container {
    // margin: 10px 0 0 0 !important;
  }

  .ClientMoney .ClientMoney_main .tab-container .tab {
    padding: 16px 16px !important;
    // font-size: 14px !important;
  }

  .ClientMoney {
    // padding: 8px 23px 23px 0px !important;
  }
}

@media (max-width: "1100px") {
  .tab-container {
    .tab {
      // font-size: 14px !important;
    }
  }
}

@media (max-width: "920px") {
  .tab-container {
    .tab {
      font-size: 12px !important;
    }
  }
}

@media (max-width: "850px") {
  .tab-container {
    .tab {
      font-size: 10px !important;
    }
  }
}
