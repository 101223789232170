.CheckButtons_wrapper {
  display: flex;
  gap: 10px;

  .cross {
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 3px solid #817eff;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 12px;
      height: 20px;
    }

    &.active {
      background-color: #817eff;
    }
  }
  .Tick {
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 3px solid #817eff;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 12px;
      height: 20px;
    }
    &.active {
      background-color: #817eff;
    }
  }
}
