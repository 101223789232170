.totalArrears {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  height: 50px;
  padding: 0 clamp(20px, 1.7vw, 58px);
  background-color: white;
  font-size: 18px;
  border-radius: 10px;
  margin-bottom: 10px;
  text-wrap: nowrap;

  @media screen and (max-width: 1400px) {
    // padding: 5px 10px;
    height: 38px;
    padding: 0 12px;
    font-size: 15px;
  }

  span:nth-of-type(2) {
    color: #817eff;
    font-weight: 500;
  }
}

.popup_main_wrapper {
  width: 100%;
  height: 100vh;

  @keyframes popupOpen {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }

    100% {
      opacity: 1;
      display: block !important;
      transform: scale(1);
    }
  }

  /* Closing Animation */
  @keyframes popupClose {
    0% {
      transform: scale(1);
      opacity: 1;
      display: block !important;
    }

    100% {
      transform: scale(0.8);
      opacity: 0;
      display: none;
    }
  }

  &.popup-open {
    opacity: 1;
    display: block !important;
  }

  &.popup-close {
    // opacity: 0;
    animation: popupClose 0.3s ease forwards;
  }

  .main_popup {
    width: 80%;
    border-radius: 11px;
    background-color: rgba(255, 255, 255, 1);
    // height: 90vh !important;
    margin-right: 5%;
    left: 18.5%;
    top: 5%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1050px) {
      width: 90% !important;
      left: 5% !important;
      margin-right: 0 !important;
    }

    &.popup-open {
      // opacity: 1;
      // display: block !important;
      animation: popupOpen 0.3s ease forwards;
    }

    &.popup-close {
      animation: popupClose 0.3s ease forwards;
    }

    .com_popup_header {
      // padding: 0 max(10px, 2%);
      width: 100%;
      // height: 25%;
      // border: 1px solid black;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;

      @media screen and (max-width: 1000px) {
        max-height: 170px;
      }

      .content {
        width: 100%;
        display: flex;
        justify-content: space-between;

        // border: 1px solid black;
        > h1 {
          font-family: "Poppins", sans-serif;
          font-size: clamp(16px, 1.3vw, 45px) !important;
          font-weight: 400;
          line-height: 28.8px;
          text-align: left;
          color: var(--Primary-Brand, #1c1c1c);
          display: flex;
          align-items: center;

          > span {
            font-family: "Poppins", sans-serif;
            font-size: clamp(16px, 1.3vw, 45px);
            font-weight: 400;
            line-height: 28.8px;
            text-align: left;
            color: rgba(149, 164, 252, 1);
            margin-left: 10px;
          }
        }

        .search_close_com {
          width: 40%;
          display: flex;
          justify-content: right;
          align-items: center;
          gap: 15px;
          // border: 1px solid black;
        }

        .close_button_wrapper {
          text-align: end;
          width: 70px;
          height: 70px;

          @media screen and (max-width: 2100px) {
            height: 50px !important;
            width: 50px !important;
          }

          @media screen and (max-width: 1700px) {
            height: 40px !important;
            width: 40px !important;
          }

          @media screen and (max-width: 1450px) {
            height: 35px !important;
            width: 35px !important;
          }

          @media screen and (max-width: 1200px) {
            height: 28px !important;
            width: 28px !important;
          }

          .close {
            cursor: pointer;
            opacity: 0.8;
            transition: all 0.3s ease;
            width: 70px;
            height: 70px;

            @media screen and (max-width: 2100px) {
              height: 50px !important;
              width: 50px !important;
            }

            @media screen and (max-width: 1700px) {
              height: 40px !important;
              width: 40px !important;
            }

            @media screen and (max-width: 1450px) {
              height: 35px !important;
              width: 35px !important;
            }

            @media screen and (max-width: 1200px) {
              height: 28px !important;
              width: 28px !important;
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      .com_popup_filter {
        width: 100%;
      }
    }

    .main_content {
      .download:hover {
        text-decoration: underline;
      }

      .main_table {
        height: 90%;
        overflow-y: auto !important;

        // padding: 0 !important;
        thead {
          border-bottom: 1px solid #d9d9d9;

          td:nth-of-type(1) {
            width: 43.33% !important;
          }

          td:nth-of-type(2) {
            width: 33.33% !important;
          }

          td:nth-of-type(3) {
            width: 33.33% !important;
          }
        }

        td:nth-of-type(1) {
          width: 43.33% !important;
        }

        td:nth-of-type(2) {
          width: 33.33% !important;
        }

        td:nth-of-type(3) {
          width: 33.33% !important;
        }
      }
    }

    .arrearsConfig {
      // height: 490px;
      height: calc(100% - 148px);

      .main_table {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 60px);

        // border: 1px solid black;
        overflow-x: auto;

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          tbody tr td {
            font-size: 25px;
            padding: 35px 0px;
          }

          thead th div {
            font-size: 25px !important;
            padding: 25px 0px !important;
          }
        }

        // height: calc(100% - 258px);
      }

      thead {
        width: 43.33% !important;
        border-bottom: 1px solid #d9d9d9;
      }

      td:nth-of-type(1) {
        width: 43.33% !important;
      }

      td:nth-of-type(2) {
        width: 33.33% !important;
      }

      td:nth-of-type(3) {
        width: 33.33% !important;
      }
    }

    .complianceConfig {
      height: 100%;
      overflow: hidden;

      .main_table {
        height: calc(100% - 65px);
        overflow-y: auto;

        @media screen and (min-width: 768px) and (max-width: 1024px),
          screen and (min-width: 1024px) and (max-width: 1366px) {
          height: calc(100% - 80px);
        }
        @media screen and (max-width: 1400px) {
          height: calc(100% - 55px);
        }

        // border: 1px solid black;
        overflow-x: auto;

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          tbody tr td {
            font-size: 25px;
            padding: 30px 0px;
          }

          thead th div {
            font-size: 25px !important;
            padding: 20px 0px !important;
          }
        }
      }

      .main_table::-webkit-scrollbar {
        width: 4px;
        /* Space for scrollbar */
      }

      .main_table::-webkit-scrollbar-thumb {
        background-color: #817eff;
        /* Scrollbar thumb color */
        border-radius: 10px;
      }

      .main_table::-webkit-scrollbar-track {
        background: #d3d4ff;
        /* Track color */
      }

      th:nth-of-type(1) {
        min-width: 20% !important;
        max-width: 20% !important;
      }

      th:nth-of-type(2) {
        min-width: 40% !important;
        max-width: 40% !important;
      }

      th:nth-of-type(3) {
        min-width: 20% !important;
        max-width: 20% !important;
      }

      th:nth-of-type(4) {
        min-width: 20% !important;
        max-width: 20% !important;
      }
    }

    .occupancyConfig {
      height: calc(100% - 109px);
      border-radius: 13px;
      overflow: hidden;

      .main_table {
        // height: calc(100% - 74px);
        height: calc(100% - 65px);
        overflow-y: auto;

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          tbody tr td {
            font-size: 25px;
            padding: 35px 0px;
          }

          thead th div {
            font-size: 25px !important;
            padding: 23px 0px !important;
          }
        }

        .custom-table td:nth-child(1) {
          padding: 5px !important;
        }
      }

      thead {
        border-bottom: 1px solid #d9d9d9;
      }

      td:nth-of-type(1) {
        width: 10% !important;
      }

      td:nth-of-type(2) {
        width: 38% !important;
      }

      td:nth-of-type(3) {
        width: 20% !important;
      }

      td:nth-of-type(4) {
        width: 20% !important;
      }

      td:nth-of-type(5) {
        width: 15% !important;
      }
    }

    .maintenanceConfig {
      // height: 90%;
      flex: 1;
      overflow: auto;

      // border: 1px solid black;
      .main_table {
        padding: 0px 10px !important;
        height: calc(100% - 67px);

        @media screen and (min-width: 768px) and (max-width: 1024px),
          screen and (min-width: 1024px) and (max-width: 1366px) {
          height: calc(100% - 80px);
        }

        // border: 1px solid black;
        overflow-x: auto;

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          tbody tr td {
            font-size: 25px;
            padding: 35px 0px;
          }

          thead th div {
            font-size: 25px !important;
            padding: 25px 0px !important;
          }
        }
      }

      thead {
        border-bottom: 1px solid #d9d9d9;
      }

      td:nth-of-type(1) {
        width: 12% !important;
      }

      td:nth-of-type(2) {
        width: 20% !important;
      }

      td:nth-of-type(3) {
        width: 15% !important;
      }

      td:nth-of-type(5) {
        width: 38% !important;
      }

      td:nth-of-type(4) {
        width: 20% !important;
      }
    }

    .infoConfig {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}
