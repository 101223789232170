.maintenace_archive {
  width: 100%;
  // position: absolute;
  // margin-left: 324px;
  top: 0;
  overflow: auto;
  // padding: 35px 34px 30px 35px;
  padding: 20px;
  background-color: #f7f9fb;
  height: 100%;
  overflow: hidden;

  .css-dev-only-do-not-override-j9bb5n {
    height: 100%;
  }

  .maintenance_content_wrapper {
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    padding: 20px;

    // height: calc(100% - 25px);
    height: 100%;

    .header {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 30px;

      @media screen and (max-width: 930px) {
        margin-bottom: 5px;
        height: 35px;
      }

      > h1 {
        //styleName: H1;
        font-family: "Poppins", sans-serif;
        // font-size: 32px;
        font-size: max(20px, 1.4vw);
        font-weight: 400;
        line-height: 28.8px;
        text-align: left;
        color: #000000;
        margin-bottom: 0px;
      }
    }

    .maintenace_log_wrapper {
      margin-top: 20px;
      height: 100%;

      .maintance_log_headwrap {
        width: 100%;
        height: 43px;
        justify-content: space-between;

        // border: 1px solid black;
        @media screen and (max-width: 945px) {
          flex-direction: column;
          column-gap: 20px;
        }

        .ssseee {
          width: 100%;
        }
      }

      .status {
        width: fit-content;
        height: 21px;
        padding: 2px 8px 2px 8px;
        gap: 10px;
        border-radius: 5px;
        background-color: #ffd3cf;
        //styleName: small button;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 17.4px;
        text-align: left;
        color: #2d2d2d;
      }

      .main_content {
        height: calc(100% - 175px);

        .main_table {
          height: 100%;
          overflow: auto;
          padding: 0 !important;

          @media screen and (max-width: 945px) {
            margin-top: 10%;
          }

          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            tbody tr td {
              font-size: 25px;
              padding: 35px 0px;
            }

            thead th div {
              font-size: 25px !important;
              padding: 25px 0px !important;
            }
          }

          thead {
            border-bottom: 1px solid #d9d9d9;
          }
        }
      }
    }
  }
}
