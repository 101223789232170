.search_main {
  // width: 316px;
  // width: 350px;
  // min-width: 200px;
  height: 100%;
  // height: max(1.8rem, 1.5rem);
  padding: 12.5px 32px 12.5px 7px;
  border-radius: 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  cursor: pointer;
  @media screen and (max-width: 2100px) {
    height: 50px !important;
  }

  @media screen and (max-width: 1700px) {
    height: 35px !important;
  }

  @media screen and (max-width: 1450px) {
    height: 35px !important;
  }

  @media screen and (max-width: 1200px) {
    height: 28px !important;
  }
  // @media screen and (max-width: 1800px) {
  //   height: 30px !important;
  //   border-radius: 5px;
  //   // border: 1px solid black;
  // }
  // @media screen and (max-width: 1600px) {
  //   height: 35px !important;
  //   border-radius: 5px;
  //   // border: 1px solid black;
  // }
  // @media screen and (max-width: 1500px) {
  //   height: 30px !important;
  //   border-radius: 5px;
  //   // border: 1px solid black;
  // }
  // @media screen and (max-width: 1400px) {
  //   height: 27px !important;
  //   border-radius: 5px;
  //   // border: 1px solid black;
  // }

  .search_field {
    font-family: "Poppins", sans-serif;

    &:focus {
      outline: none;
      border: none;
    }
  }
  .closeIconButton {
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
    position: absolute;
    right: 2px;
    top: 16%;
  }
}

.upload_button {
  // width: 5.1rem;
  // height: 2.5rem;
  border-radius: 7px;
  background-color: #817eff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  padding: clamp(8px, 0.6vw, 23px) clamp(12px, 0.95vw, 33px);
  @media screen and (max-height: 800px) {
    height: 2rem !important;
    border-radius: 5px;
    // border: 1px solid black;
  }

  > span {
    font-family: "Poppins", sans-serif;
    font-size: clamp(12px, 0.95vw, 25px);
    font-weight: 400;
    line-height: 17.4px;
    text-align: left;
  }
}

.generate_report {
  // width: 162px;
  // height: 37px;
  padding: max(3px, 0.6vw) max(12px, 1vw);
  border-radius: 5px;
  background: rgba(129, 126, 255, 1);
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  gap: max(15px, 1.3vw);

  > span {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    // line-height: 17.4px;
    text-align: left;
    @media screen and (max-width: 1000px) {
      font-size: 12px;
    }
    @media screen and (max-width: 900px) {
      font-size: 10px;
    }
  }

  > i {
    font-size: 12px;
  }
}

.DateRange {
  width: auto;

  font-size: max(12px, 1.1vw) !important;
  @media screen and (max-width: 1200px) {
    // width: 180px;
    width: auto;
  }
}
