.customButton {
  background: white;
  color: rgba(129, 126, 255, 1);
  padding: 7.5px 20px;
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid rgba(129, 126, 255, 1);
  cursor: pointer;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.activeCustomButton {
  background: rgba(129, 126, 255, 1);
  color: white !important;
  border: none !important;
}

.activeCustomButton:hover {
  background: rgba(129, 126, 255, 0.9) !important;
}

.customButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
