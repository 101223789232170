.popup_main_wrapper {
  position: fixed;
  top: 0;
  // left: 1px;
  width: 100%;
  height: 100%;
  z-index: 300;
  background-color: rgba(0, 0, 71, 0.6);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
  @keyframes popupOpen {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }
    100% {
      opacity: 1;
      display: block !important;
      transform: scale(1);
    }
  }

  /* Closing Animation */
  @keyframes popupClose {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      display: none;
      transform: scale(0.8);
    }
  }
  &.popup-open {
    opacity: 1;
    display: block !important;
  }
  &.popup-close {
    opacity: 0;
    display: none;
    animation: popupClose 0.3s ease forwards;
  }
  .content_wrapper {
    position: absolute;
    width: 80%;
    border-radius: 13px;
    background-color: rgba(255, 255, 255, 1);
    height: 90vh !important;
    margin-right: 5%;
    left: 18.5%;
    top: 5%;
    padding: clamp(10px, 1.2vw, 38px);
    @media screen and (max-width: 1050px) {
      width: 90% !important;
      left: 5% !important;
      margin-right: 0 !important;
    }

    &.popup-open {
      opacity: 1;
      display: block !important;
      animation: popupOpen 0.3s ease forwards;
    }
    &.popup-close {
      opacity: 0;
      display: none;

      animation: popupClose 0.3s ease forwards;
    }

    .contact_popup_header {
      padding: max(2px, 0.4%) 0 max(8px, 1%) 0;
      width: 100%;
      height: 21%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      // border: 1px solid black;
      // max-height: 190px;
      // @media screen and (max-width: 2800px) {
      //   max-height: 300px !important;
      // }
      // @media screen and (max-width: 2500px) {
      //   max-height: 260px !important;
      // }
      // @media screen and (max-width: 2200px) {
      //   max-height: 240px !important;
      // }
      // @media screen and (max-width: 1900px) {
      //   max-height: 200px !important;
      // }
      // @media screen and (max-width: 1400px) {
      //   max-height: 160px !important;
      // }

      .header_content-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        // justify-content: space-between;
        gap: max(1%, 8px);
        align-items: center;
        // border: 1px solid black;

        .contact_popup_head_img {
          width: 14.5%;
          height: 100%;
          // border: 1px solid black;
          // max-width: 180px;
          min-width: 135px;
          display: flex;
          align-items: center;
          @media screen and (max-width: 1050) {
            width: 11%;
            min-width: 100px;
          }
          img {
            // border-radius: clamp(10px, 0.8vw, 27px);
            border-radius: 13px;
            object-fit: cover;
            object-position: right;
            // width: 165px;
            width: 100%;
            height: 87.5%;
            object-fit: cover;
            @media screen and (max-width: 1400px) {
              height: 85% !important;
            }
            @media screen and (max-width: 1300px) {
              height: 82.5% !important;
            }
            @media screen and (max-width: 1200px) {
              height: 77.5% !important;
            }
            @media screen and (max-width: 1100px) {
              height: 75% !important;
            }
            // @media screen and (max-width: 1000px) {
            //   height: 70% !important;
            // }
            // @media screen and (max-width: 900px) {
            //   height: 65% !important;
            // }
          }
        }
        .contact_title_wrapper {
          height: 100%;
          // width: 85% !important;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100% !important;
          align-items: flex-end;
          // width: 100%;
          // flex: 1;
          // border: 1px solid black;
          overflow: hidden;
          .contact_pop_head_text {
            height: 35%;
            width: 100%;
            font-weight: 400;
            // border: 1px solid black;
            display: flex;
            align-items: center;
            padding-left: 1.5%;
            h1 {
              display: flex;
              align-items: center;
              font-size: clamp(18px, 1.6vw, 45px);
              line-height: 40px;
              // border: 1px solid black;
            }
          }
          .contact_buttons_cover {
            height: 50%;
            // border: 1px solid black;
            width: 100%;
            // background-color: red;
            display: flex;
            align-items: center;
            gap: max(1%, 8px);
            width: 100%;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            transition: 0.2s;

            &::-webkit-scrollbar {
              display: none;
            }

            &:hover {
              // height: calc(50% - 2px);
              // padding-top: 2px;
              &::-webkit-scrollbar {
                display: none;
              }
            }

            &::-webkit-scrollbar-track {
              display: none;
            }

            &::-webkit-scrollbar-thumb {
              display: none;
            }

            &::-webkit-scrollbar-thumb:hover {
              display: none;
            }

            > button {
              width: fit-content;
              padding: max(5px, 0.6vw) max(12px, 0.9vw);
              // border-radius: max(6px, 0.5vw);
              border-radius: 8px;
              //styleName: small button;
              font-family: "Poppins", sans-serif;
              font-size: clamp(10px, 1vw, 28px);
              font-weight: 400;
              line-height: 17.4px;
              text-align: left;
              color: #000;
              margin: 10px 5px;
              background-color: #fff;
              border: 1px solid rgba(225, 233, 238, 1);
              text-wrap: nowrap;

              &.active {
                background-color: rgba(129, 126, 255, 1);
                color: #fff;
              }

              &.inActive:hover {
                background-color: rgb(238, 238, 255, 0.8);
                color: black;
                transition: all 0.4s ease-in-out;
              }
            }
          }
        }

        .close_button_wrapper {
          text-align: end;
          width: 70px;
          height: 70px;

          @media screen and (max-width: 2100px) {
            height: 50px !important;
            width: 50px !important;
          }
          @media screen and (max-width: 1700px) {
            height: 40px !important;
            width: 40px !important;
          }
          @media screen and (max-width: 1450px) {
            height: 35px !important;
            width: 35px !important;
          }
          @media screen and (max-width: 1200px) {
            height: 28px !important;
            width: 28px !important;
          }
          .close {
            cursor: pointer;
            width: 70px;
            height: 70px;

            @media screen and (max-width: 2100px) {
              height: 50px !important;
              width: 50px !important;
            }
            @media screen and (max-width: 1700px) {
              height: 40px !important;
              width: 40px !important;
            }
            @media screen and (max-width: 1450px) {
              height: 35px !important;
              width: 35px !important;
            }
            @media screen and (max-width: 1200px) {
              height: 28px !important;
              width: 28px !important;
            }
          }
        }
      }

      .contact_title_wrapper {
        // padding: 20px 108px;
        // padding: 20px 0px 0px 90px;
        // padding: 20px 0px 0px 31px;
        // padding: max(0.5%, 8px) 0;
        > h1 {
          font-family: "Poppins", sans-serif;
          font-size: 24px;
          font-weight: 300;
          line-height: 28.8px;
          text-align: left;
          margin-bottom: 20px;
        }
      }
    }
    .com_table_props {
      display: flex;
      width: 40%;
      justify-content: right;
      gap: 10px;
      min-width: 450px;
    }
    .contact_body_content_data {
      height: 78.5%;
      // border: 1px solid black;
      // overflow-y: scroll;
      .doc_con_cover {
        height: 49.5%;
      }
    }
    .body_content_wrapperLandlord {
      height: 82% !important;
      @media screen and (min-width: 400px) and (max-width: 1000px) {
        height: 78% !important;
      }
    }

    .body_content_wrapper {
      background-color: var(--Primary-Light, rgba(247, 249, 251, 1));
      // padding: 27px 40px 40px 27px;
      padding: max(12px, 1.4vw);
      height: 100%;
      // border: 1px solid black;
      display: flex;

      .con_doc_left {
        // border: 1px solid black;
        height: 100%;
        width: 16%;
        padding-top: 1%;
        > h2 {
          font-family: "Poppins", sans-serif;
          font-size: clamp(12px, 1.1vw, 33px);
          font-weight: 500;
          line-height: 19.2px;
          text-align: left;
        }
      }

      .con_doc_right {
        width: 84%;
        height: 100%;
        .doc_card_cover_main {
          height: 100%;
          width: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          display: flex;
          flex-wrap: wrap;
          row-gap: 4%;
          column-gap: 1.5%;
          align-content: start;
          .doc_card_cover {
            width: 31.5%;
            height: 47%;
            @media screen and (max-width: 960px) {
              width: 48% !important;
            }

            @media screen and (max-width: 3200px) {
              max-height: 240px !important;
            }
            @media screen and (max-width: 3100px) {
              max-height: 237px !important;
            }
            @media screen and (max-width: 3000px) {
              max-height: 234px !important;
            }
            @media screen and (max-width: 2900px) {
              max-height: 231px !important;
            }
            @media screen and (max-width: 2800px) {
              max-height: 229px !important;
            }
            @media screen and (max-width: 2700px) {
              max-height: 217px !important;
            }
            @media screen and (max-width: 2600px) {
              max-height: 198px !important;
            }
            @media screen and (max-width: 2400px) {
              max-height: 182px !important;
            }
            @media screen and (max-width: 2200px) {
              max-height: 166px !important;
            }
            @media screen and (max-width: 2000px) {
              max-height: 153px !important;
            }
            @media screen and (max-width: 1800px) {
              max-height: 143px !important;
            }
            @media screen and (max-width: 1600px) {
              max-height: 130px !important;
            }
            @media screen and (max-width: 1400px) {
              max-height: 106px !important;
            }
            @media screen and (max-width: 1200px) {
              max-height: 97px !important;
            }
            @media screen and (max-width: 1000px) {
              max-height: 90px !important;
            }
          }
        }

        .con_download_card {
          padding: 3% 4%;
          background-color: #fff;
          height: 100%;
          width: 100%;
          // border-radius: max(12px, 1.1vw);
          border-radius: 13px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;

          .bottomSection {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 5px;
            .con_bottom_date_exp {
              span {
                font-size: clamp(7px, 0.7vw, 19px);
                color: #817eff;
                &:hover {
                  text-decoration: "underline";
                  color: #000;
                }
              }
              span:nth-child(2) {
                color: #000;
              }
            }
            span {
              font-size: clamp(7px, 0.7vw, 19px);
              color: #817eff;
              &:hover {
                text-decoration: "underline";
                color: #000;
              }
            }
          }

          .con_text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 60%;
            gap: 3%;
            .con_icon_cover {
              background: #f7f9fb;
              border-radius: 50%;
              padding: max(6px, 0.6vw);
              img {
                width: max(14px, 1.3vw);
                height: max(14px, 1.3vw);
              }
            }
            > p {
              font-family: "Poppins", sans-serif;
              font-size: clamp(10px, 0.9vw, 28px);
              font-weight: 400;
              // line-height: 22.4px;
              text-align: left;
              color: rgba(45, 45, 45, 1);
              margin-bottom: 0;
              width: 75%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: pointer;
            }
          }
        }
      }
    }

    .divider {
      width: 100%;
      height: max(1px, 0.2vw);
      background-color: rgba(225, 233, 238, 1);
      margin: auto;
    }

    .body_content_wrapper:nth-child(4) {
      border-radius: 0px 0px 20px 20px;
    }

    .con_popuptable {
      // border: 1px solid black;
      .con_incom_cards {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: max(9px, 1vw);
        // border: 1px solid black;
        height: 17%;
      }

      .con_landlord_popup_cards {
        @media screen and (max-width: 1000px) {
          flex-direction: column !important;
        }
      }

      .transactionColumn {
        // height: 85%;
        //  @media screen and (max-width: 768px) {
        //   height: 40%;
        // }
        @media screen and (max-width: 868px) {
          height: 40%;
        }
        @media screen and (min-width: 868px) and (max-width: 1024px) {
          height: 50%;
        }
        @media screen and (min-width: 1024px) and (max-width: 1366px) {
          height: 60%;
        }
        @media screen and (min-width: 1366px) and (max-width: 1920px) {
          height: 90%;
        }
        @media screen and (min-width: 1920px) {
          height: 100%;
        }

        .main_table {
          // height: 584px;
          padding: 0px 10px;
          // height: 100%;
          height: 67%;
          overflow: auto;

          @media screen and (min-width: 768px) and (max-width: 1024px),
            screen and (min-width: 1024px) and (max-width: 1366px) {
            height: calc(100% - 0px);
          }

          // border: 1px solid black;
          overflow-x: auto;

          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            tbody tr td {
              font-size: 20px;
              padding: 35px 0px;
            }

            thead th div {
              font-size: 23px !important;
              padding: 25px 0px !important;
            }
          }
          thead {
            border-bottom: 1px solid #d9d9d9;
          }
          .pagination {
            // border: 1px solid black;
            padding: 20px 40px;
          }
        }
      }

      .columns {
        height: 100%;

        .main_table {
          // height: 584px;
          padding: 0px 10px;
          // height: 100%;
          height: calc(100% - 63px);
          // max-height: calc(100% - 120px);

          @media screen and (min-width: 768px) and (max-width: 1024px),
            screen and (min-width: 1024px) and (max-width: 1366px) {
            height: calc(100% - 0px);
          }

          // border: 1px solid black;
          overflow-x: auto;

          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            height: calc(100% - 40px);

            tbody tr td div {
              font-size: 20px;
              padding: 35px 0px;
            }

            thead th div {
              font-size: 23px !important;
              padding: 25px 0px !important;
            }
          }

          thead {
            border-bottom: 1px solid #d9d9d9;
          }

          .pagination {
            // border: 1px solid black;
            padding: 20px 40px;
          }
        }
      }

      .incomeexp {
        height: 89%;

        .main_table {
          // height: 584px;
          padding: 0px 10px;
          // height: 100%;
          height: calc(100% - 60px);
          overflow: auto;

          @media screen and (min-width: 768px) and (max-width: 1024px),
            screen and (min-width: 1024px) and (max-width: 1366px) {
            height: calc(100% - 0px);
          }

          // border: 1px solid black;
          overflow-x: auto;

          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            height: calc(100% - 40px);

            tbody tr td {
              font-size: 20px;
              padding: 35px 0px;
            }

            thead th div {
              font-size: 23px !important;
              padding: 25px 0px !important;
            }
          }

          thead {
            border-bottom: 1px solid #d9d9d9;
          }

          .pagination {
            // border: 1px solid black;
            padding: 20px 40px;
          }
        }
      }

      //tenant

      .table7 {
        // height: 67%;
        height: 88%;

        @media screen and (min-width: 768px) and (max-width: 1366px) {
          height: 78%;
        }

        // @media screen and (min-width: 1920px) and (max-height: 1080px) {
        //   height: 84%;
        // }
        @media screen and (min-width: 1366px) and (max-width: 1920px) {
          height: 84%;
        }

        .main_table {
          // height: calc(100% - 0px);
          height: 98%;
          overflow: auto;

          @media screen and (min-width: 768px) and (max-width: 1024px),
            screen and (min-width: 1024px) and (max-width: 1366px) {
            height: calc(100% - 0px);
          }

          overflow-x: auto;

          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            tbody tr td {
              font-size: 20px;
              padding: 35px 0px;
            }

            thead th div {
              font-size: 23px !important;
              padding: 25px 0px !important;
            }
          }

          thead {
            border-bottom: 1px solid #d9d9d9;
          }

          .pagination {
            padding: 20px 40px;
          }
        }
      }

      .table3 {
        height: 78%;

        @media screen and (min-width: 768px) and (max-width: 1024px),
          screen and (min-width: 1024px) and (max-width: 1366px) {
          height: 84%;
        }

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          height: 83%;
        }

        .main_table {
          height: calc(100% - 0px);
          overflow: auto;

          @media screen and (min-width: 768px) and (max-width: 1024px),
            screen and (min-width: 1024px) and (max-width: 1366px) {
            height: calc(100% - 0px);
          }

          overflow-x: auto;

          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            tbody tr td {
              font-size: 20px;
              padding: 35px 0px;
            }

            thead th div {
              font-size: 23px !important;
              padding: 25px 0px !important;
            }
          }

          thead {
            border-bottom: 1px solid #d9d9d9;
          }

          .pagination {
            padding: 20px 40px;
          }
        }
      }

      .table4 {
        height: 85%;

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          height: 90%;
        }

        @media screen and (min-width: 768px) and (max-width: 1024px),
          screen and (min-width: 1024px) and (max-width: 1366px) {
          height: 90%;
        }

        .main_table {
          height: calc(100% - 0px);
          overflow: auto;

          @media screen and (min-width: 768px) and (max-width: 1024px),
            screen and (min-width: 1024px) and (max-width: 1366px) {
            height: calc(100% - 0px);
          }

          overflow-x: auto;

          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            tbody tr td {
              font-size: 20px;
              padding: 35px 0px;
            }

            thead th div {
              font-size: 23px !important;
              padding: 25px 0px !important;
            }
          }

          thead {
            border-bottom: 1px solid #d9d9d9;
          }

          .pagination {
            padding: 20px 40px;
          }
        }
      }

      .table5 {
        height: 75%;

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          height: 79%;
        }

        @media screen and (min-width: 768px) and (max-width: 1024px),
          screen and (min-width: 1024px) and (max-width: 1366px) {
          height: 85%;
        }

        .main_table {
          height: calc(100% - 0px);
          overflow: auto;

          @media screen and (min-width: 768px) and (max-width: 1024px),
            screen and (min-width: 1024px) and (max-width: 1366px) {
            height: calc(100% - 0px);
          }

          overflow-x: auto;

          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            tbody tr td {
              font-size: 20px;
              padding: 35px 0px;
            }

            thead th div {
              font-size: 23px !important;
              padding: 25px 0px !important;
            }
          }

          thead {
            border-bottom: 1px solid #d9d9d9;
          }

          .pagination {
            padding: 15px 25px;
          }
        }
      }
    }
    .propertyContactsWrapper {
      background-color: rgb(247, 249, 251);
      // padding: 45px 46px 0px 46px;
      // padding: max(14px, 1.5vw);
      padding: 14px 15px 0px 15px;
      // border-radius: max(10px, 1vw);
      border-radius: 13px;
      // height: 100%;
      // border: 1px solid red;
      .button_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        button {
          border: none;
          /* background-color: white; */
          font-size: clamp(13px, 1.2vw, 35px);
          color: #8c8c8c;
          padding: clamp(6px, 0.8vw, 12px) clamp(15px, 2.6vw, 20px);
          /* border-radius: 5px; */
          border-top-right-radius: 13px;
          border-top-left-radius: 13px;
        }

        .active {
          background: #817eff;
          color: white;
        }
      }
      .contentDiv {
        background-color: white;
        // border-top-left-radius: 13px;
        border-top-right-radius: max(10px, 1.2vw);
        border-bottom-right-radius: max(10px, 1.2vw);
        border-bottom-left-radius: max(10px, 1.2vw);
        height: 90%;
        .landlordDetails {
          height: 90%;
          overflow-y: auto;
          // border: 1px solid black;
          padding: 0 max(10px, 1.3vw);
          // border-top-left-radius: 13px;
          // border-top-right-radius: 13px;
          .title {
            // padding: 22px 0;
            font-size: clamp(14px, 1.2vw, 47px);
            border-bottom: 1px solid #d9d9d9;
            // margin: 0 40px;
            // margin-bottom: 33px;
            // border: 1px solid black;
            height: 16%;
            width: 100%;
            display: flex;
            align-items: center;
            // border-top-left-radius: 13px;
            // border-top-right-radius: 13px;
          }
          .detailsSectionWrapper {
            margin: 0;
            display: flex;
            flex-direction: column;
            // border: 1px solid black;
            padding-top: 1%;
            height: 84%;
            // align-items: flex-start;
            gap: 9%;
            overflow-y: auto;
            .singleRecord {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              // gap: 20px;
              // border: 1px solid black;
              height: 10%;

              .leftSection {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                // gap: 15px;
                // border: 1px solid black;
                width: 25%;
                height: 100%;
                gap: 10%;
                span {
                  font-size: clamp(12px, 1.15vw, 33px);
                  font-weight: 500;
                }
              }
              p {
                font-size: clamp(12px, 1.15vw, 33px);
              }
            }
          }
        }
      }
    }
  }
  .accountBalanceCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    padding: max(7px, 0.8vw) 3%;
    border-radius: 13px;
    width: 33.5%;
    min-width: 302px;
    // border: 1px solid black;
    .wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
    }
  }
}
.dividerLine {
  height: 1px;
  width: 100%;
  background-color: #e1e9ee;
}

.landlordPopupCard {
  cursor: pointer;
  // width: 246px;
  width: 22%;
  height: 90%;
  min-width: 150px;
  // height: 86px;
  padding: clamp(12px, 1vw, 28px);
  // border-radius: clamp(10px, 0.7vw, 20px);
  border-radius: 13px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #eeeeff;
  color: #2d2d2d;

  // @media screen and (max-width: 850px) {
  //   height: 70px !important;
  // }

  &.active {
    background-color: #817eff;
    color: #fff;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-size: clamp(11px, 0.9vw, 30px);
    font-weight: 500;
    line-height: 17.4px;
    text-align: left;
    text-wrap: nowrap;
    // border: 1px solid black;
    // color: var(--Primary-Light, #F7F9FB);
  }
  h1 {
    // border: 1px solid black;
    font-family: "Poppins", sans-serif;
    // font-size: clamp(14px, 1.3vw, 35px);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    // color: var(--Primary-Light, #F7F9FB);
    // margin: 10px 0px 0px 0px;
  }
}

.landlordDetailsPopup {
  .contact_body_content_data {
    height: 82% !important;
    @media screen and (max-width: 1370px) {
      height: 77% !important;
    }
  }
  .transactionColumn {
    height: 81% !important;
    .main_table {
      // height: 584px;
      padding: 0px 10px;
      // height: 100%;
      height: 100% !important;
      overflow: auto;

      @media screen and (min-width: 768px) and (max-width: 1024px),
        screen and (min-width: 1024px) and (max-width: 1366px) {
        height: calc(100% - 0px);
      }

      // border: 1px solid black;
      overflow-x: auto;

      @media screen and (min-width: 1920px) and (min-height: 1080px) {
        tbody tr td {
          font-size: 20px;
          padding: 35px 0px;
        }

        thead th div {
          font-size: 23px !important;
          padding: 25px 0px !important;
        }
      }
      thead {
        border-bottom: 1px solid #d9d9d9;
      }
      .pagination {
        // border: 1px solid black;
        padding: 20px 40px;
      }
    }
  }
}
