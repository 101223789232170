.currentoccupancy_wrapper_main {
  // background-color: #fff;
  // border-radius: clamp(15px, 1.3vw, 50px);
  // padding: 25px;
  // height: 100%;
  // width: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-evenly;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px 20px 22px 20px;

  @media screen and (max-width: 1075px) {
    height: 300px;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (max-width: 1075px) {
    padding: 25px 20px 20px 20px;
  }

  .header {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: clamp(17px, 1.4vw, 60px);
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    margin-bottom: 10px;

    @media screen and (max-width: 1075px) {
      line-height: 20px;
    }
  }

  .currentoccupancy_inner_wrapper {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 14px;

    @media screen and (min-width: 1920px) and (min-height: 1080px) {
      gap: 70px;
    }

    @media screen and (max-height: 901px) {
      justify-content: none;
    }

    .animated:hover {
      box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
      border-radius: 5px;
      transform: scale(1.03);
    }

    .animated {
      cursor: pointer !important;
    }

    .notAnimated {
      cursor: default !important;
    }

    .animated,
    .notAnimated {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dotted rgb(210, 214, 224);
      padding: 7px 9px;
      transition: background-position 0.2s ease, transform 0.2s ease-in-out;
      cursor: pointer;

      @media screen and (max-width: 1075px) {
        padding: 12px 0px !important;
      }

      .main_text {
        width: 45%;
        display: flex;
        justify-content: space-between;
        white-space: normal;

        .text {
          font-family: "Poppins", sans-serif;
          font-size: clamp(10px, 1vw, 24px);
          font-style: normal;
          font-weight: 400;
          text-align: left;

          @media screen and (max-width: 1075px) {
            font-size: 12px;
            margin-bottom: 10px;

          }
        }

        .text:nth-child(5) {
          font-size: 5px !important;
        }

        >span:nth-child(2) {
          text-align: right;
          font-family: "Poppins", sans-serif;
          font-size: clamp(10px, 1vw, 24px);
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .progress_bar {
        width: 55%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .progress {
          height: 12px;
          border-radius: 0px 5px 5px 0px;
          background-color: transparent !important;

          .progress-bar {
            border-radius: 0px 5px 5px 0px !important;
          }
        }
      }
    }
  }
}