.nav-client-popup {
  width: 280px;
  height: 350px;
  background: #fff;
  position: absolute;
  // bottom: 13%;
  bottom: 15%;
  left: 100%;
  // left: 105%;
  // bottom: 23%;
  // right: -31%;
  box-shadow: 3px 3px 10px 3px rgb(129, 126, 255, 0.4);
  border-radius: 10px;
  z-index: -1;
  overflow-y: auto;
  opacity: 0;
  // transform: translateX(-20px);
  transform: translateX(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.open {
    opacity: 1;
    transform: translateX(0);
  }

  &.close {
    opacity: 0;
    // transform: translateX(-20px);
    transform: translateX(-20px);
  }

  .nav-client-popup-header {
    position: sticky;
    top: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(129, 126, 255, 0.3);

    h2 {
      padding: 0px 10px;
      font-size: 18px;
      font-weight: 500;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      .close-btn {
        font-size: 32px;
        background: none;
        border: none;
        cursor: pointer;
        // padding: 0 9px;
        background-color: rgb(129, 126, 255);
        color: #fff;
        // border-radius: 50%;
        padding: 1px 16px;

        transition: background-color 0.2s ease;
        &:hover {
          background-color: rgb(129, 126, 255, 0.8);
        }
      }
    }
  }

  .nav-client-popup-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    .nav-client-popup-body-container {
      border-radius: 5px;
      background: rgb(243, 241, 253);
      cursor: pointer;
      transition: background 0.2s ease;

      &:hover {
        background: rgb(129, 126, 255, 0.2);
      }
      &.selected {
        background: rgb(129, 126, 255, 0.3);
        cursor: default;
        pointer-events: none;
        &:hover {
          background: none;
        }
        button {
          color: rgb(129, 126, 255);
        }
      }
      button {
        padding: 10px 10px;
        border: none;
        background: transparent;
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #000;
      }
    }
  }
}
