/* PropertyDetails.scss */
.property-details {
  background: #f9fafb;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  strong {
    font-size: 16px;
    font-weight: 500;
    color: #000;
  }

  .header {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
    .crumbSubSection {
      opacity: 0.6;
      text-decoration: none;
      cursor: pointer;
      font-weight: 400;
      padding-right: 10px;
    }
  }

  .highlight {
    color: #007bff;
  }

  .details-card {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }

  .property-image {
    width: 100px;
    height: 100px;
    // border-radius: 8px;
  }

  .info {
    flex-grow: 1;
    .infoMainHeading {
      font-size: 27px;
      font-weight: 400;
      line-height: 46px;
    }
  }

  .badge {
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 12px;
    margin-left: 8px;
  }

  .occupied {
    background: #e0f5ea;
    color: #2d8659;
  }

  .panelContentSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .tabs {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    overflow-x: auto;
    padding: 10px 0;
  }

  .tabs button {
    padding: 10px 15px;
    border: none;
    // background: #e9ecef;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    min-width: max-content;
    &:hover {
      box-shadow: 0 1px 10px #817eff4d;
    }
  }

  .tabs .active {
    background: rgb(129, 126, 255);
    color: white;
  }

  .actions {
    margin-top: 15px;
    text-align: right;
  }

  .actions .dropdown {
    padding: 10px 15px;
    border: none;
    background: #6c757d;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }

  .sectionWrapper {
    // background: #f9fafb;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(43, 18, 18, 0.1);

    min-height: 500px;
    margin-top: 10px;
    padding-bottom: 0;
  }

  .propertyDetailsTransactionsWrapper {
    overflow: hidden;
    height: 100%;

    .headingWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .transactionsHeading {
        font-size: 21px;
        font-weight: 400;
        color: #817eff;
      }
    }

    .main_table {
      padding: 0 !important;
      height: 80% !important;
      overflow-y: auto !important;
    }
  }

  .account-balance-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .account-balance-card {
    background: #eeeeff;
    margin-right: 35px;
    padding: 6px 10px;
    border-radius: 8px;
    display: flex;
    // flex-direction: column;
    gap: 15px;
  }

  .landlord,
  .tenant,
  .address {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  .account-balance-wrapper {
    display: flex;
    align-items: center;
  }

  .balance-icon {
    width: clamp(14px, 2.2vw, 24px);
    height: clamp(16px, 2.3vw, 24px);
    margin-right: 10px;
  }

  .balance-title {
    font-size: clamp(13px, 1.1vw, 30px);
    font-weight: 500;
    width: max-content;
  }

  .balance-amount {
    font-size: clamp(15px, 1.6vw, 24px);
    font-weight: 300;
  }

  .balance-amount.positive {
    color: green;
  }

  .balance-amount.negative {
    color: red;
  }

  .balance-amount.neutral {
    color: black;
  }

  .statement-button-container {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  .generate-statement-button {
    background-color: rgb(129, 126, 255);
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-weight: 500;
    font-size: 13px;
    width: max-content;
    transition: background-color 0.3s ease-in-out;
  }

  .generate-statement-button:hover {
    background-color: rgba(129, 126, 255, 0.7);
  }

  .contact_body_content_data {
    height: 100%;
    // border: 1px solid black;
    // overflow-y: scroll;
    .doc_con_cover {
      height: 100%;
    }
  }
  .copy-btn {
    padding: 3px 11px;
    background-color: #d4d4f3;
    border-radius: 7px;
    color: #817eff;
    font-size: 12px;
    font-weight: 600;
  }

  .body_content_wrapper {
    background-color: var(--Primary-Light, rgba(247, 249, 251, 1));
    // padding: 27px 40px 40px 27px;
    // padding: max(12px, 1.4vw);
    height: 100%;
    // border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 13px;

    .con_doc_left {
      // border: 1px solid black;
      padding-top: 1%;
      > h2 {
        font-family: "Poppins", sans-serif;
        font-size: clamp(12px, 1.1vw, 33px);
        font-weight: 500;
        line-height: 19.2px;
        text-align: left;
        margin-bottom: 10px;
      }
    }

    .con_doc_right {
      height: 80%;
      .doc_card_cover_main {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-wrap: wrap;
        row-gap: 4%;
        column-gap: 1.5%;
        align-content: start;
        .doc_card_cover {
          width: 31.5%;
          height: 47%;
          @media screen and (max-width: 960px) {
            width: 48% !important;
          }

          @media screen and (max-width: 3200px) {
            max-height: 240px !important;
          }
          @media screen and (max-width: 3100px) {
            max-height: 237px !important;
          }
          @media screen and (max-width: 3000px) {
            max-height: 234px !important;
          }
          @media screen and (max-width: 2900px) {
            max-height: 231px !important;
          }
          @media screen and (max-width: 2800px) {
            max-height: 229px !important;
          }
          @media screen and (max-width: 2700px) {
            max-height: 217px !important;
          }
          @media screen and (max-width: 2600px) {
            max-height: 198px !important;
          }
          @media screen and (max-width: 2400px) {
            max-height: 182px !important;
          }
          @media screen and (max-width: 2200px) {
            max-height: 166px !important;
          }
          @media screen and (max-width: 2000px) {
            max-height: 153px !important;
          }
          @media screen and (max-width: 1800px) {
            max-height: 143px !important;
          }
          @media screen and (max-width: 1600px) {
            max-height: 130px !important;
          }
          @media screen and (max-width: 1400px) {
            max-height: 106px !important;
          }
          @media screen and (max-width: 1200px) {
            max-height: 97px !important;
          }
          @media screen and (max-width: 1000px) {
            max-height: 90px !important;
          }
        }
      }

      .con_download_card {
        padding: 3% 4%;
        background-color: #fff;
        height: 100%;
        width: 100%;
        // border-radius: max(12px, 1.1vw);
        border-radius: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        .bottomSection {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 5px;
          .con_bottom_date_exp {
            span {
              font-size: clamp(7px, 0.7vw, 19px);
              color: #817eff;
              &:hover {
                text-decoration: "underline";
                color: #000;
              }
            }
            span:nth-child(2) {
              color: #000;
            }
          }
          span {
            font-size: clamp(7px, 0.7vw, 19px);
            color: #817eff;
            &:hover {
              text-decoration: "underline";
              color: #000;
            }
          }
        }

        .con_text {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          height: 60%;
          gap: 3%;
          .con_icon_cover {
            background: #f7f9fb;
            border-radius: 50%;
            padding: max(6px, 0.6vw);
            img {
              width: max(14px, 1.3vw);
              height: max(14px, 1.3vw);
            }
          }
          > p {
            font-family: "Poppins", sans-serif;
            font-size: clamp(10px, 0.9vw, 28px);
            font-weight: 400;
            // line-height: 22.4px;
            text-align: left;
            color: rgba(45, 45, 45, 1);
            margin-bottom: 0;
            width: 75%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }
      }
    }
  }

  .propertyContactsWrapper {
    background-color: rgb(247, 249, 251);
    border-radius: 13px;
    height: 88%;
    .button_wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      button {
        border: none;
        /* background-color: white; */
        // font-size: clamp(13px, 1.2vw, 35px);
        font-size: 16px;
        color: #8c8c8c;
        // padding: clamp(6px, 0.8vw, 12px) clamp(15px, 2.6vw, 20px);
        padding: 6px 19px;
        /* border-radius: 5px; */
        border-top-right-radius: 13px;
        border-top-left-radius: 13px;
      }

      .active {
        background: #817eff;
        color: white;
      }
    }
    .contentDiv {
      background-color: white;
      // border-top-left-radius: 13px;
      border-top-right-radius: max(10px, 1.2vw);
      border-bottom-right-radius: max(10px, 1.2vw);
      border-bottom-left-radius: max(10px, 1.2vw);
      height: 100%;
      .landlordDetails {
        height: 100%;
        overflow-y: auto;
        // border: 1px solid black;
        padding: 0 max(10px, 1.3vw);
        // border-top-left-radius: 13px;
        // border-top-right-radius: 13px;
        .title {
          // padding: 22px 0;
          font-size: clamp(14px, 1.2vw, 47px);
          border-bottom: 1px solid #d9d9d9;
          // margin: 0 40px;
          // margin-bottom: 33px;
          // border: 1px solid black;
          height: 16%;
          width: 100%;
          display: flex;
          align-items: center;
          // border-top-left-radius: 13px;
          // border-top-right-radius: 13px;
        }
        .detailsSectionWrapper {
          margin: 0;
          display: flex;
          flex-direction: column;
          // border: 1px solid black;
          padding-top: 1%;
          height: 84%;
          // align-items: flex-start;
          gap: 9%;
          overflow-y: auto;
          .singleRecord {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .leftSection {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              // gap: 15px;
              // border: 1px solid black;
              width: 19%;
              height: 100%;
              gap: 10%;
              span {
                // font-size: clamp(12px, 1.15vw, 33px);
                font-size: 14px;
                font-weight: 500;
              }
            }
            p {
              // font-size: clamp(12px, 1.15vw, 33px);
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
