.inital-card {
  background-color: rgb(255, 255, 255);
  z-index: 2;
  box-shadow: 0px 1px 10px 0px #00000040;
  width: 80vw;
  height: 88vh;
  margin-top: 70px;
  border-radius: 25px;
}
.card-contents {
  margin: 40px 35px;
  .init-card-top {
    display: flex;
    margin-top: 18px;
    justify-content: space-between;
    .top-button {
      display: flex;
      gap: 14px;
    }
    .card-search {
      width: 511px;
    }
  }
}
.info-question-mark {
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    transform: scale(1.25);
  }
}
.card-contents-onboarding {
  // margin: 40px 35px;
  height: 100%;
  padding: 20px 15px 40px 35px;
  margin-right: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  .init-card-top {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;
    @media screen and (max-width: 1800px) {
      margin-top: 30px;
    }
    @media screen and (max-width: 1600px) {
      margin-top: 20px;
    }
    .top-button {
      display: flex;
      gap: 14px;
    }
    .card-search {
      width: 511px;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }
}
.card-body-content {
  margin-top: 30px;
}
.card-body-content-onboarding {
  flex: 1;
  margin-top: 30px;
  overflow: hidden;
}
.init-headings {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  //   margin-top: 60px;
}
.file-body {
  transition: all 0.2s ease;
}
.file-list {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 20px;

  border-bottom: 1px solid #d9d9d9;
  &:hover {
    // transform: scale(1.008);
    box-shadow: 0px 0px 5px rgba(185, 185, 185, 0.2);
    transition: all 0.3s ease;
    background-color: rgba(212, 212, 243, 0.3);
    // opacity: 0.5;
    color: black;
  }

  .file-text {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  .file-left {
    display: flex;
    align-items: center;
    gap: 18px;
  }
  .file-end {
    display: flex;
    align-items: center;
    gap: 30px;
  }
}

.progress-bar {
  width: 100%;
  height: 16px;
  background-color: #f3f3f3;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar progress {
  width: 100%;
  height: 100%;
  appearance: none;
}

progress::-webkit-progress-bar {
  // background-color: #f3f3f3;
  background: transparent;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
}

progress::-webkit-progress-value {
  background-color: #817eff; /* Green progress bar */
  border-radius: 10px;
}

progress::-moz-progress-bar {
  background-color: #817eff; /* Green progress bar */
  border-radius: 10px;
}

.progress-percentage {
  text-align: right;
  font-size: 18px;
  padding: 0 10px;
  color: #333;
}
.share-icon {
  &:hover {
    transform: scale(1.25);
    transition: all 0.3s ease;
  }
}
.delete-icon {
  &:hover {
    transform: scale(1.25);
    transition: all 0.3s ease;
  }
}

.logOutButton {
  background: red;
  padding: 10px 45px;
  border-radius: 9px;
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-weight: 600;
  font-size: 18px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
