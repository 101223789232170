.allproperties_main_wrapper {
  width: 100%;
  // position: absolute;
  height: 100%;
  // margin-left: 300px;
  top: 0;
  overflow: auto;
  // padding: 30px 12px 0px 35px;
  padding: 10px 20px 20px 20px;
  background-color: #f7f9fb;
  overflow-y: hidden;

  .header {
    display: flex;
    justify-content: space-around;
    gap: 2%;
    // padding: 0 20px;
    height: 18%;
    padding: 0 20px;
    max-height: 144px;
    flex-direction: column;
    gap: 15px;
    // border: 1px solid black;
    @media screen and (max-width: 1200px) {
      // flex-direction: column-reverse;
      // height: 14%;
      padding-left: 10px;
      // margin-top: 8px;
    }
    @media screen and (max-width: 930px) {
      height: 15%;
      // margin-bottom: 5%;
      // padding-top: 15px;
      // margin-top: 15px;
    }
    .totalArrears {
      height: 100%;
      // width: 25%;
      height: 3.4rem;
      padding: 13px 10px;
      // min-width: 370px;
      width: fit-content;
      @media screen and (max-width: 1900px) {
        height: 2.8rem;
      }
    }

    .button_inside {
      // border: 1px solid black;
      display: flex;
      gap: max(8px, 1.5vw);
    }
    .button_wrapper {
      //width: 360px;
      align-items: center;
      justify-content: space-between;
      // border: 1px solid black;
      .activated {
        background-color: #817eff !important;
        color: #fff;
      }

      .allpropButtons {
        width: fit-content;
        // border: 1px solid black;
        @media screen and (max-width: 1200px) {
          // width: 23%;
        }
        .all {
          //width: 56px;
          // height: 29px;
          padding: 8px 18px;
          height: fit-content;
          border-radius: 5px;
          border: 1px solid #e1e9ee;
          background-color: #f7f9fb;
          @media screen and (max-width: 1400px) {
            padding: 3px 10px !important;
          }
          @media screen and (max-width: 1200px) {
            padding: 1px 8px !important;
          }
          // @media screen and (max-width: 1200px) {
          //   padding: 3px 10px;
          //   width: 100%;
          // }

          &:active {
            background-color: #817eff;
            color: #fff;
          }

          > span {
            //styleName: small button;
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 29.4px;
            text-align: left;
            @media screen and (max-width: 1900px) {
              font-size: 16px;
            }
            // border: 1px solid black;
            @media screen and (max-width: 1400px) {
              font-size: 13px;
            }
            @media screen and (max-width: 1200px) {
              font-size: 12px !important;
            }
            @media screen and (max-width: 890px) {
              font-size: 9px !important;
            }
          }
        }

        .approved {
          //width: 101px;
          // height: 29px;
          border-radius: 5px;
          border: 1px solid #e1e9ee;
          background-color: #f7f9fb;
          padding: 8px 18px;
          height: fit-content;
          @media screen and (max-width: 1400px) {
            padding: 3px 10px !important;
          }
          @media screen and (max-width: 1200px) {
            padding: 1px 8px !important;
          }
          // @media screen and (max-width: 1200px) {
          //   padding: 5px 18px;
          //   width: 100%;
          // }

          &:active {
            background-color: #817eff;
            color: #fff;
          }

          > span {
            //styleName: small button;
            font-family: "Poppins", sans-serif;
            font-size: 24px;

            font-weight: 400;
            line-height: 29.4px;
            text-align: left;
            @media screen and (max-width: 1900px) {
              font-size: 16px;
            }
            @media screen and (max-width: 1400px) {
              font-size: 13px;
            }
            @media screen and (max-width: 1200px) {
              font-size: 12px !important;
            }
            @media screen and (max-width: 890px) {
              font-size: 9px !important;
            }
          }
        }

        .notapproved {
          //width: 125px;
          // height: 29px;
          border-radius: 5px;
          padding: 8px 18px;
          border: 1px solid #e1e9ee;
          background-color: #f7f9fb;
          height: fit-content;
          @media screen and (max-width: 1400px) {
            padding: 3px 10px !important;
          }
          @media screen and (max-width: 1200px) {
            padding: 1px 8px !important;
          }
          // @media screen and (max-width: 1200px) {
          //   padding: 5px 18px;
          //   width: 100%;
          // }

          &:active {
            background-color: #817eff;
            color: #fff;
          }

          > span {
            //styleName: small button;
            font-family: "Poppins", sans-serif;
            font-size: 24px;

            font-weight: 400;
            line-height: 29.4px;
            text-align: left;
            text-wrap: nowrap;
            @media screen and (max-width: 1900px) {
              font-size: 16px;
            }
            @media screen and (max-width: 1400px) {
              font-size: 13px;
            }
            @media screen and (max-width: 1200px) {
              font-size: 12px !important;
            }
            @media screen and (max-width: 890px) {
              font-size: 9px !important;
            }
          }
        }
      }
    }

    .search_wrapper {
      display: flex;
      // height: 50px;
      width: 50%;
      min-width: 250px;
      // border: 1px solid black;
      @media screen and (max-width: 1200px) {
        // justify-content: right;
        // width: 100%;
      }
      .search_sub_wrap {
        width: 100%;
        gap: 15px;
        @media screen and (max-width: 915px) {
          gap: 8px !important;
        }
      }
      .searchAndUpload {
        width: calc(100% - 5.5vw);
        width: 100%;
        // border: 1px solid black;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 15px;
        @media screen and (max-width: 915px) {
          gap: 8px !important;
        }
      }

      .views_button_wrapper {
        display: flex;
        flex-wrap: nowrap;
        > button:nth-child(1) {
          // width: 5.3vh;
          // height: 5vh;
          max-width: 60px;
          max-height: 60px;
          // min-width: 6vh;
          // min-height: 6vh;
          border-radius: 1vh 0px 0px 1vh;
          border: none;
          font-size: 20px;
          background-color: #fff;
          color: #817eff;
          @media screen and (max-width: 1500px) {
            height: 35px;
            width: 35px;
            font-size: 16px;
          }
          @media screen and (max-width: 1500px) {
            height: 30px;
            width: 30px;
            font-size: 13px;
          }

          &:focus {
            outline: none;
          }

          &.active {
            background-color: #817eff;
            color: #fff;
          }
        }

        > button:nth-child(2) {
          // width: 5.3vh;
          // height: 5vh;
          max-width: 60px;
          max-height: 60px;
          border-radius: 0px 5px 5px 0px;
          background-color: #fff;
          color: #817eff;
          border: none;
          font-size: 20px;
          @media screen and (max-width: 1500px) {
            height: 35px;
            width: 35px;
            font-size: 16px;
          }
          @media screen and (max-width: 1500px) {
            height: 30px;
            width: 30px;
            font-size: 13px;
          }

          &:focus {
            outline: none;
          }

          &.active {
            background-color: #817eff;
            color: #fff;
          }
        }
      }
    }
  }
}

.allproperties_wrapper {
  // margin-top: 25px;
  height: calc(100% - 125px);
  overflow-y: auto;
  overflow-x: hidden;
  // @media screen and (max-width: 1400px) {
  //   height: 77%;
  //   margin-top: 2%;
  // }
  // @media screen and (max-width: 850px) {
  //   height: 82%;
  // }
  .main_table {
    height: calc(100% - 80px);
    // border: 1px solid black;
    // height: 100%;
    overflow-y: scroll !important;
    // min-width: 750px;
    @media screen and (min-width: 1920px) and (min-height: 1080px) {
      tbody tr td {
        font-size: 25px;
        padding: 35px 0px;
      }
      thead th div {
        font-size: 25px !important;
        padding: 25px 0px !important;
      }
    }
    &::-webkit-scrollbar {
      width: 1.5px !important;
      height: 1.5px !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #817eff; /* Scrollbar thumb color */
      border-radius: 10px;
    }
  }

  .row {
    padding: 10px 22px;
    border-radius: 13px;

    .prop_card:hover {
      transform: scale(1.03);
      box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
    }

    .prop_card {
      height: 100%;
      min-height: 400px;
      // width: 406px;
      border-radius: 27px;
      border: none;
      position: relative;
      cursor: pointer;
      border-radius: max(10px, 0.8vw);
      transition: background-position 0.2s ease, transform 0.2s ease-in-out;
      // background-color: transparent;
      .imageDiv {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 220px;
        width: 93%;
        border-radius: 24px;
        margin: 0 auto;
        margin-top: 15px;
      }

      > span {
        position: absolute;
        height: 33px;
        top: 30px;
        left: 19px;
        padding: 4px 15px 4px 15px;
        gap: 10px;
        border-radius: 5px;
        background-color: #ceffe1;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 17.4px;
        text-align: center;
        color: #000047;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .prop_card-img-top {
        height: 230px;
        border-radius: 26.7px;
        background-position: 100% 100%;
      }

      .prop_card-body {
        padding: 25px 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;

        .prop_card-title {
          font-family: "Poppins", sans-serif;
          font-size: 25px;
          font-weight: 400;
          line-height: 28.8px;
          text-align: left;
          margin-bottom: 27.33px;
        }

        .prop_card-text {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 32px;
          text-align: left;
          color: #6a6a79;
        }
      }
    }
  }

  .Approved {
    width: fit-content;
    height: 21px;
    padding: 2px 8px 2px 8px;
    gap: 10px;
    border-radius: 5px;
    background-color: #ffd3cf;
    //styleName: small button;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.4px;
    text-align: left;
    color: #2d2d2d;
  }

  .NotApproved {
    width: fit-content;
    height: 21px;
    padding: 2px 8px 2px 8px;
    gap: 10px;
    border-radius: 5px;
    background-color: #ceffe1;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 17.4px;
    text-align: left;
    color: #2d2d2d;
  }
}

@media (max-width: "1750px") {
  .totalArrears {
    padding: 0 10px !important;
    gap: 15px !important;
  }
  .search_box {
    width: 300px !important;
  }
  .row {
    padding: 5px !important;
  }
  .prop_card {
    min-height: 385px !important;
    .imageDiv {
      min-height: 205px !important;
    }
    .prop_card-body {
      .prop_card-text {
        line-height: 28px !important;
      }
      .prop_card-title {
        font-size: 24px !important;
        margin-bottom: 18.33px !important;
      }
    }
  }
}
@media (max-width: "1550px") {
  .total-Arrears {
    width: 220px !important;
  }
  .totalArrears {
    padding: 0 10px !important;
    gap: 15px !important;
    .propscount {
      font-size: 14px !important;
    }
  }
  .search_box {
    width: 300px !important;
  }
  .prop_card {
    min-height: 345px !important;
    .imageDiv {
      min-height: 180px !important;
    }
    .prop_card-body {
      .prop_card-text {
        line-height: 20px !important;
        font-size: 15px !important;
      }
      .prop_card-title {
        font-size: 20px !important;
        margin-bottom: 10.33px !important;
      }
    }
  }
  // .propscount {
  //   font-size: 14px !important;
  // }
}
@media (max-width: "1000px") {
  #tablestatus {
    font-size: 10px !important;
    padding: 5px 8px !important;
  }
}

@media (max-width: "1300px") {
  .total-Arrears {
    width: 180px !important;
    margin: 2px 0 0 20px !important;
    .propscount {
      font-size: 14px !important;
    }
  }
  #tablestatus {
    font-size: 12px !important;
  }
  .allproperties_main_wrapper {
    padding: 15px 10px 0px 4px;
  }
  // .propscount {
  //   font-size: 12px !important;
  // }
  .search_box {
    width: 250px !important;
  }
  .button_wrapper {
    span {
      font-size: 14px !important;
    }
  }
  .prop_card {
    min-height: 272px !important;
    .imageDiv {
      min-height: 140px !important;
    }
    .prop_card-body {
      padding: 18px 20px !important;
      .prop_card-text {
        line-height: 16px !important;
        font-size: 12px !important;
      }
      .prop_card-title {
        font-size: 16px !important;
        margin-bottom: 10.33px !important;
      }
    }
    span {
      height: 22px !important;
      top: 28px !important;
      left: 19px !important;
      padding: 4px 7px 4px 6px !important;
      font-size: 12px !important;
    }
  }
}
@media (max-width: "1150px") {
  .prop_card {
    min-height: 226px !important;
    .imageDiv {
      min-height: 111px !important;
      border-radius: 20px !important;
    }
    .prop_card-body {
      padding: 12px 14px !important;
      .prop_card-text {
        line-height: 12px !important;
        font-size: 10px !important;
      }
      .prop_card-title {
        font-size: 14px !important;
        line-height: 20px !important;

        margin-bottom: 5.33px !important;
      }
    }
    span {
      height: 16px !important;
      top: 26px !important;
      left: 15px !important;
      padding: 4px 7px 4px 6px !important;
      font-size: 10px !important;
    }
  }
  .search_box {
    width: 150px !important;
  }
  // .propscount {
  //   height: 20px !important;
  // }
  // .total-Arrears {
  //   padding: 7px 0 5px 14px !important;
  // }
  .button_wrapper {
    gap: 10px !important;
  }
  .button_wrapper {
    span {
      font-size: 14px !important;
    }
  }
}
@media (max-width: "930px") {
  .header {
    height: 12%;
  }
  .button_wrapper {
    gap: 5px !important;
    span {
      font-size: 12px !important;
    }
  }
  .allproperties_main_wrapper {
    padding: 11px 10px 0px 4px;
  }
  // .propscount {
  //   font-size: 8px !important;
  // }
  #property-cards {
    width: 33% !important;
  }
}
