.ConformationPopup_main_wrapper {
  z-index: 400;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 71, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup_main {
    // width: 640px;
    // height: 332px;
    border-radius: 13px;
    background-color: #fff;
    padding: 25px 30px;

    .header {
      display: flex;
      justify-content: end;

      // padding: 20px 36px;
      >button {
        background-color: transparent;
        border: none;
        width: fit-content;
        height: fit-content;
      }
    }

    .content {
      >h1 {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        // margin-top: 31px;

      }

      .button_wrapper {
        display: flex;
        margin-top: 16px;
        justify-content: center;
        gap: 25px;

        button {
          width: 80px;
          height: 25px;
          border-radius: 7px;
          color: #fff;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          text-align: center;
          border: none;
        }

        button:nth-child(1) {
          background-color: rgba(129, 126, 255, 1);
          transition: background-color 0.3s ease;
          &:hover {
            background-color: rgba(129, 126, 255, 0.8);
          }
        }
       

        button:nth-child(2) {
          border: 1px solid rgba(129, 126, 255, 1);
          background-color: #fff;
          color: rgba(129, 126, 255, 1);
        }
      }

    }
  }
}