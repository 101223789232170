.ClientMoney {
  width: 100%;
  // position: absolute;
  // margin-left: 333px;
  top: 0;
  overflow: auto;
  // padding: 14px 34px 34px 0px;
  padding: 20px;
  padding-top: 0;
  background-color: #f7f9fb;
  height: 100%;
  overflow: hidden;
  .css-dev-only-do-not-override-j9bb5n {
    height: 100%;
  }

  .ClientMoney_main {
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    // border: 1px solid black;
    .tab-container .tab {
      padding: max(15px, 1.6dvw) 20px;
    }
    .toggle_header {
      width: 100%;
      height: 45px;
      border-radius: 15px 15px 0px 0px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-bottom: 1px solid #e7e7e7;
      p {
        font-family: "Poppins", sans-serif;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 19.2px;
        text-align: center;
        width: 100%;
        padding: 23px 0px;
        cursor: pointer;
        color: #2d2d2d;
        &.active {
          color: #817eff;
          border-bottom: 2px solid #817eff;
        }
      }
    }
    .overview_main {
      // height: calc(100% - 80px);
      overflow: hidden;
      flex: 1;
      .table_filter {
      }
    }
  }
}
