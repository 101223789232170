.passwordInput {
  height: 55px;

  @media screen and (max-width: 1000px) {
    height: 35px;
  }
  @media screen and (min-width: 2000px) {
    // height: 80px;
    height: 90px;
  }
  input {
    font-size: 20px;
    &:focus {
      outline: none;
    }
    @media screen and (max-width: 1000px) {
      font-size: 22px;
    }
  }
}
