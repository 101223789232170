.maintenace_overview {
  width: 100%;
  // position: absolute;
  // margin-left: 324px;
  top: 0;
  overflow: auto;
  // padding: 35px 34px 28px 35px;
  padding: 20px;
  background-color: #f7f9fb;
  height: 100%;
  // border: 1px solid black;
  max-height: 1200px;
  @media screen and (max-width: 3440px) {
    max-height: 1430px;
  }
  @media screen and (max-width: 1400px) {
    padding: 15px;
  }
  @media screen and (max-width: 1100px) {
    padding: 10px;
  }

  .maintenance_content_wrapper {
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    padding: 20px;

    // height: calc(100% - 25px);
    height: 100%;
    @media screen and (max-width: 1100px) {
      height: auto !important;
    }

    .header {
      display: flex;
      gap: 10px;
      align-items: center;
      height: 8% !important;
      // border: 1px solid black;

      > h1 {
        //styleName: H1;
        font-family: "Poppins", sans-serif;
        font-size: max(20px, 1.4vw);
        font-weight: 400;
        // line-height: 28.8px;
        text-align: left;
        color: #000000;
        margin-bottom: 0px;
      }
    }

    .main_content_wrapper {
      height: 92%;
      // border: 1px solid black;
      // overflow: hidden !important;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1100px) {
        flex-direction: column;
        // overflow-y: scroll !important;
      }
      .maintanance_overview_graph {
        // border: 1px solid black;
        width: 24%;
        @media screen and (max-width: 1100px) {
          width: 100%;
          // height: 40vh;
          max-height: 350px;
        }
        .main_graph_Overview {
          display: flex;
          flex-direction: column;
          height: 100%;
          .main_g_head {
            //styleName: H2;
            font-family: "Poppins", sans-serif;
            font-size: max(14px, 1.2vw) !important;
            font-weight: 500;
            line-height: 19.2px;
            text-align: left;
            height: 6%;
            // padding: 21px 0;
            // border: 1px solid black;
            display: flex;
            align-items: center;
            padding-left: 7%;
            // justify-content: center;
          }
          .main_graph_data {
            height: 94%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            // border: 1px solid black;
            @media screen and (max-width: 1100px) {
              flex-direction: row;
            }
            .main_graph_dia {
              // border: 1px solid black;
              height: 50%;
              width: 100%;
              padding: 10%;
              @media screen and (max-width: 1100px) {
                height: 100%;
                width: 40%;
                padding: 2%;
              }
            }
            .maintanence_list {
              height: 50%;
              // border: 1px solid black;
              // margin-top: -1rem;
              width: 100%;
              @media screen and (max-width: 1100px) {
                height: 100%;
                width: 60%;
                // padding: 2%;
              }
              > ul {
                padding: 0;
                margin: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                // > li:hover {
                //   box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
                //   border-radius: 8px;
                //   transform: scale(1.03);
                // }
                .animated:hover {
                  box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
                  border-radius: 8px;
                  transform: scale(1.03);
                }
                .animated {
                  cursor: pointer;
                }
                .notAnimated {
                  cursor: default;
                }

                .animated,
                .notAnimated {
                  // display: flex;
                  // justify-content: space-between;
                  // gap: 20px;
                  // // margin-bottom: 12px;
                  // padding: 5px;
                  // margin-bottom: 33px;
                  display: flex;
                  justify-content: space-between;
                  gap: 14px;
                  width: 100%;
                  padding: max(10px, 0.6vw) 5px;
                  transition: background-position 0.2s ease,
                    transform 0.2s ease-in-out;
                  margin-top: -4px;
                  // border: 1px solid black;

                  span {
                    color: #2d2d2d;
                    font-family: "Poppins", sans-serif;
                    font-size: max(14px, 1.2vw);
                    font-style: normal;
                    font-weight: 400;
                    // line-height: 18px;
                    display: flex;
                    align-items: center;
                    @media screen and (max-width: 1100px) {
                      font-size: 14px !important;
                    }

                    .badge1 {
                      width: 8px;
                      height: 8px;
                      background: #75e291;
                      border-radius: 50%;
                      margin-right: 7px;
                    }

                    .badge2 {
                      width: 8px;
                      height: 8px;
                      background-color: #ffc650;
                      border-radius: 50%;
                      margin-right: 7px;
                    }

                    .badge3 {
                      width: 8px;
                      height: 8px;
                      background-color: #ff5b5c;
                      border-radius: 50%;
                      margin-right: 7px;
                    }

                    .badge4 {
                      width: 8px;
                      height: 8px;
                      background-color: #52c2ef;
                      border-radius: 50%;
                      margin-right: 7px;
                    }

                    .badge5 {
                      width: 8px;
                      height: 8px;
                      background-color: #6062f0;
                      border-radius: 50%;
                      margin-right: 7px;
                    }
                    .badge6 {
                      width: 8px;
                      height: 8px;
                      background-color: #ff9e6f;
                      border-radius: 50%;
                      margin-right: 7px;
                    }
                  }

                  > p {
                    color: #2d2d2d;
                    font-family: "Poppins", sans-serif;
                    font-size: max(18px, 1.5vw);
                    font-weight: 300;
                    line-height: 18px;
                    text-align: left;
                    width: 50px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    @media screen and (max-width: 1100px) {
                      font-size: 16px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .maintanance_overview_content {
        // border: 1px solid black;
        width: 75%;
        height: 100%;
        max-height: 1100px;
        @media screen and (max-width: 1100px) {
          width: 100%;
          height: 100vh;
        }

        .Key_analytics {
          // border: 1px solid black;
          width: 100%;
          height: 55%;
          .key_analytics_content {
            width: 100%;
            height: 100%;
            .innerKey_wrapper {
              // border: 1px solid black;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 20px;
              width: 100%;
              height: 20%;
              .heading {
                //styleName: H2;
                font-size: max(14px, 1.2vw);
                font-weight: 500;
                font-weight: 500;
                @media screen and (max-width: 1300px) {
                  font-size: 14px;
                }
              }

              .date {
                // margin-top: -40px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                span {
                  width: 4.3rem;
                  height: 30px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  // gap: 10px;
                  border-radius: 5px;
                  border: 1px solid rgba(225, 233, 238, 1);
                  // opacity: 0px;
                  font-size: 12px;
                  cursor: pointer;
                  font-family: "Poppins", sans-serif;
                  @media screen and (max-width: 1100px) {
                    font-size: 10px !important;
                    width: 3.5rem;
                    height: 25px;
                  }
                }
                .active {
                  background-color: rgba(129, 126, 255, 1);
                  color: white;
                  border: none;
                }
              }
            }
            .main_card_wrapper {
              height: 80%;
              // border: 1px solid black;
              // height: calc(100% - 90px);
              display: flex;
              justify-content: space-around;
              align-items: center;
              cursor: pointer;
              padding-right: 44px;

              .main_main_card_cover {
                width: 31%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                .main_main_card {
                  padding: 8%;
                  border: none;
                  background-color: #f7f9fb;
                  border-radius: 10px;
                  height: 80%;
                  width: 100%;
                  max-height: 300px;
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                  align-items: start;
                  transition: background-position 0.2s ease,
                    transform 0.2s ease-in-out;

                  &:hover {
                    transform: scale(1.05);
                  }

                  .img_wrapper {
                    border-radius: 50%;
                    background-color: #ffffff;
                    width: fit-content;
                    padding: max(15px, 1.5vw) max(15px, 1.5vw);

                    > img {
                      width: max(20px, 1.9vw);
                    }
                  }

                  .text_erapper {
                    > h1 {
                      font-family: "Poppins", sans-serif;
                      font-size: max(18px, 1.2vw);
                      font-weight: 300;
                      line-height: 21px;
                      text-align: left;
                      margin-bottom: 20px;
                    }

                    > p {
                      //styleName: small button;
                      font-family: "Poppins", sans-serif;
                      font-size: max(14px, 0.8vw);
                      font-weight: 400;
                      line-height: 17.4px;
                      text-align: left;
                      color: #6a6a79;
                    }
                  }
                }
              }
            }
          }
        }

        .upcoming_maintenance_jobs {
          // margin-top: -1rem;
          // border: 1px solid black;
          height: 45%;
          padding: 0 2%;
          .upcoming_main_head {
            height: 10%;
            width: 100%;
            display: flex;
            align-items: center;
            font-size: max(14px, 1.2vw);
            font-weight: 500;
          }
          .upcoming_card_wrapper {
            height: 90%;
            width: 100%;
            // padding-right: 30px;
            // border: 1px solid black;
            display: flex;
            align-items: center;
            gap: 2%;
            overflow-x: auto;
            overflow-y: hidden;
            .upcoming_card_cover {
              height: 90%;
              width: 31%;
              .upcoming_card {
                // padding: 25px 25px;
                border: none;
                background-color: #f7f9fb;
                border-radius: 10px;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: start;
                display: flex;
                flex-direction: column;
                padding: 10%;
                justify-content: space-between;
                @media screen and (max-width: 1400) {
                  padding: 4%;
                }
                @media screen and (max-width: 1100) {
                  padding: 6%;
                }
                img {
                  width: max(20px, 1.5vw);
                  height: max(20px, 1.5vw);
                }

                .text_erapper {
                  .upcoming_card_text {
                    margin-bottom: max(8px, 1vw);
                    font-size: max(13px, 0.9vw);
                  }
                  > p {
                    font-family: "Poppins", sans-serif;
                    font-size: max(16px, 0.9vw);
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    margin-bottom: 8px;
                  }

                  > h6 {
                    //styleName: small button;
                    font-family: "Poppins", sans-serif;
                    font-size: mac(12px, 0.6vw);
                    font-weight: 400;
                    // text-wrap: wrap;
                    line-height: 12.4px;
                    text-align: left;
                    color: #6a6a79;
                    p {
                      font-size: max(13px, 0.9vw);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
