.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 60%;
  height: 60%;
  // width: 1007px;
  /* min-width: 1145px; */
  padding: max(16px, 1.25vw);
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  min-width: 460px;
  // border: 1px solid black;
}

.popup-content {
  .customInput:focus-visible {
    outline: 1px solid #817eff !important;
  }

  .customInput {
    height: 52px !important;
    border-radius: 9px !important;
  }

  .popup_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.6);
    padding: 10px 0;

    h2 {
      font-size: clamp(15px, 1.7vw, 45px);
      font-weight: 400;
      line-height: 30px;
      text-align: center;
    }

    button {
      border: none;
      width: max(30px, 2vw);
      height: max(30px, 2vw);
      background: #817eff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: max(16px, 1.4vw);
      color: white;
      border-radius: 8px;
      padding: 0;
      margin: 0;
      margin-top: -12px;
      cursor: pointer;
      background-color: rgba(129, 126, 255, 0.8);
    }

    span:hover {
      background-color: rgba(129, 126, 255, 1);
    }
  }

  .popup_center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 20px 0 0 0;

    .popup_form {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 100%;

      // gap: 238px;
      .formContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 97%;
        // flex-direction: column;
        gap: 10px;

        .innerWrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          //   width: 90%;
          gap: 20px;
          flex-wrap: wrap;
        }

        // .css-1qrxvr1-singleValue {
        //   text-align: start;
        // }
        label {
          font-weight: 500;
          font-size: 15px;
        }

        .customInput {
          height: 40px;
          width: 100%;
          border: 1px solid lightgray;
          border-radius: 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 3px 10px;
        }

        input {
        }

        .innerFormContainer {
          width: 47%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          gap: 10px;
        }

        .error {
          width: 44%;

          p {
            width: 100%;
            color: red;
          }
        }
      }
    }
  }

  .statusDIv {
    display: flex;
    justify-content: flex-end !important;
    align-items: center;
    width: 100%;

    span {
      width: 100%;
    }
  }

  .popup_bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    width: 98%;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
}

.innerFormContainer {
  display: flex;
  justify-content: space-between;
}

.inputGroup {
  width: calc(50% - 10px);
  /* Adjust width as needed */
}

.upload_main {
  // border: 1px solid black;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  justify-content: space-between;

  .upload_doc_top {
    width: 100%;
    height: 12%;
    // border: 1px solid black;
    display: flex;
    justify-content: flex-end;

    .up_accountBalanceCard {
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      padding: 0 3%;
      border-radius: 13px;

      // width: 33.5%;
      // min-width: 302px;
      // border: 1px solid black;
      .up_doc_wrapper {
        height: 100%;
        display: flex;
        align-items: center;

        .up_doc_top_file {
          font-weight: 400;
          font-size: clamp(12px, 1vw, 28px);
          color: black;
          background: transparent;
          text-wrap: nowrap;
        }
      }

      span {
        height: 75%;
        font-size: clamp(12px, 1vw, 28px);
        background-color: #817eff;
        font-weight: 500;
        // height: 100%;
        padding: 0 clamp(12px, 1vw, 28px);
        display: flex;
        align-items: center;
        color: #fff;
        border-radius: max(10px, 0.9vw);
      }
    }
  }

  .Upload_pic {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: space-between;
  }

  .upload_doc_bottom {
    width: 100%;
    // height: 12%;
    margin-bottom: 20px;

    // border: 1px solid black;
    .Header {
      font-size: clamp(12px, 1vw, 28px);
    }

    .Next_Button {
      color: #ffff;
      background: rgb(129, 126, 255);
      border-radius: 5px;
      padding: max(10px, 0.8vw) max(13px, 1.8vw);
      // width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      font-size: clamp(12px, 1vw, 28px);
      &:disabled {
        background-color: #817eff;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

.Upload_pic {
  width: 100%;
  text-align: left;
  padding: 25px 50px;
  overflow-y: auto;
  border-radius: 10px;
  height: 57%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  // border: 1px solid black;
  // background-color: #e9edf7;
  padding: 12px 0;
  height: 235px;

  &::-webkit-scrollbar {
    display: none !important;
  }

  .Main_div {
    // background-color: #ffff;
    margin-bottom: 10px;
    gap: 14px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    .mainHeader {
      font-size: clamp(18px, 1.4vw, 58px);
      font-weight: 600;
    }

    .mainSubHeader {
      font-size: clamp(16px, 1.3vw, 45px);
      line-height: 28px;
    }

    .attachDiv {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      flex-wrap: wrap;
      flex-direction: column;
    }

    .internalAttachDiv {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    svg {
      color: green;
      width: 70px;
      height: 70px;
    }

    .Back_Button {
      width: 120px;
      height: 42px;
      border: 1px solid #ffffff;
      border-radius: 5px;
      color: #fff;
      background-color: #2862d8;

      &:hover {
        background-color: #fff;
        color: #2862d8;
        border: 1px solid #2862d8;
      }
    }

    a {
      color: #4aa3df;
      text-decoration: underline;
    }

    textarea {
      padding: 10px;
      width: 100%;
      border: 0px;
      outline: none;
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      resize: none;
    }

    .divider {
      border-bottom: 1px dotted;
      margin-top: 30px;
      margin-bottom: 10px;
    }

    .Sub_header {
      color: gray;
    }
  }

  .Header {
    // font-size: clamp(11px, 0.95vw, 23px);
    font-size: 16px;
    width: 100%;
    text-align: center;
    // font-weight: 700;
  }

  .Sub_Header {
    font-size: 16px;
    color: gray;
  }

  .Image_Viewer {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* width: 96%; */
    top: 20px;
    flex-wrap: wrap;
    left: 20px;

    .ImageView {
      width: 60px;
      height: 60px;
      margin-left: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
      border-radius: 15px;

      img {
      }
    }
  }

  //   .imagedropper::-webkit-scrollbar {
  //     display: none !important;
  //   }

  .imagedropper {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    // height: 400px;
    // border: 1px solid black;
    padding: 15px;
    display: flex;
    flex-direction: row-reverse;
    overflow: auto;
    background: rgb(247, 249, 251);
    position: relative;

    &.actives {
      padding: 20px;
    }

    .Imagedropper_inner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border: 2px dashed rgba(129, 126, 255, 0.5);
      padding: 25px 0;
      border-radius: 10px;

      p {
        height: 100%;
        display: flex;
        align-items: center;
        color: #6a6a79;
        font-size: clamp(14px, 1.2vw, 38px);
      }

      p:hover {
        text-decoration: underline;
      }

      .upload {
        padding: 10px;
        border-radius: 5px;
        color: #2862d8;
        font-weight: 500;
        border: 0px;
        margin-bottom: 22px;
        border: 1px solid #2862d8;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
          background: #2862d8;
          color: white;
        }
      }
    }
  }
}

.cancle_img {
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  z-index: 5;
  cursor: pointer;
  top: 6px;
  right: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: wheat;
  font-size: 10px;
}

.status {
  > img {
    position: absolute;
    background-color: white;
    color: #000;
    top: 0px;
    margin-left: 14px;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 15px;
    height: 61px;
    width: 61px;
  }
}

.totalFileCount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 50px;
  padding: 0 20px;
  background-color: #f7f9fb;
  font-size: clamp(18px, 1.4vw, 58px);
  border-radius: 13px;
  width: 245px;

  span:nth-of-type(2) {
    color: #817eff;
    font-weight: 500;
  }
}
