.loadingClass {
  width: 82%;
  height: 100%;
  //   background: rgba(247, 249, 251, 1);
  background: rgba(129, 126, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.6;
  transition: opacity 0.5s linear;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &.visible {
    opacity: 0.6;
  }
}

.loadingImage {
  width: 70px;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
