// .custom-table {
//   border-collapse: collapse;
//   width: 100%;
//   height: 88%;
//   // width: 1080px;
//   margin: 0 auto;
//   // border: 3px solid green;
//   overflow-y: auto;
// }

// // .main_table {
// //     border: 1px solid lightgray;
// //     overflow-x: scroll;
// //     width: 100%;
// //     max-width: 1060px;
// //     position: relative;

// // }
// .custom-table tbody {
//   // height: 80%;
//   overflow-y: scroll;
// }

// .custom-table td {
//   text-align: left;
// }

// .custom-table tbody tr:nth-child(odd) {
//   background-color: rgba(247, 249, 251, 1);
// }

// .custom-table tbody tr:nth-child(even) {
//   background-color: white;
// }

// .custom-table thead {
//   position: sticky;
//   top: 0;
//   z-index: 1;
//   background-color: white;
//   //   background-color: #f7f9fb;
// }

// .custom-table thead th {
//   font-family: "Poppins", sans-serif;
//   font-size: clamp(10px, 0.9vw, 28px) !important;
//   font-weight: 500 !important;
//   line-height: 17.4px;
//   text-align: left;
//   text-transform: capitalize;
//   padding: 15px 0;
//   height: clamp(40px, 2.5vw, 160px);
//   div {
//     font-size: clamp(10px, 0.9vw, 28px) !important;
//     font-weight: 500 !important;
//   }
// }

// .custom-table thead tr th:nth-child(1) {
//   > div {
//     margin-left: 5px;
//   }
// }
// .custom-table-body tr td {
//   font-family: "Poppins", sans-serif;
//   font-size: clamp(10px, 0.9vw, 28px);
//   // font-weight: 500;
//   line-height: 17.4px;
//   text-align: left;
//   cursor: pointer;
// }

// .custom-table tr:nth-child(1) td {
//   text-align: left;
// }

// .custom-table td {
//   font-size: clamp(10px, 0.9vw, 25px);
//   font-weight: 400;
//   padding: 25px 10px;
//   @media screen and (max-width: 1300px) {
//     padding: 15px 10px;
//   }
//   @media screen and (max-width: 900px) {
//     padding: 8px 10px;
//   }
// }
// .custom-table td:nth-child(1) {
//   // > div {
//   //   margin-left: 10px;
//   // }
//   padding: 20px 10px;
// }

// .custom-table-body .even {
//   background-color: #fff;
// }

// .custom-table-body .odd {
//   background: rgba(217, 217, 217, 0.2);
// }

// .custom-table-body tr:hover {
//   background: rgb(129, 126, 255, 0.3);
//   // border: 1px solid var(--Secondary-Purple, rgba(198, 199, 248, 1))
// }

// .filterDiv {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 20px;
//   flex-wrap: wrap;
//   // padding-left: 10px;
//   .mainSpan {
//     // outline: 0.5px solid rgb(0, 0, 0);
//     outline: 1px solid rgba(0, 0, 0, 0.25);
//     padding: 4px 22px;
//     border-radius: 10px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-weight: 500;
//     cursor: pointer;
//     &:hover {
//       outline: 1px solid rgba(0, 0, 0, 0.45);
//     }
//     .subSpan {
//       padding: 0 6px;
//       border-radius: 6px;
//       margin-left: 8px;
//       font-size: 12px;
//       color: black !important;
//     }
//   }
//   .active {
//     background-color: #817eff;
//     outline: none !important;
//     color: white;
//   }
// }

// .pagination {
//   height: 12%;
//   justify-content: space-between;
//   display: flex;
//   align-items: center;
// }

// .custom-table tbody tr:hover {
//   background: rgba(217, 217, 217, 0.2);
// }
// @media (max-width: "1290px") {
//   .custom-table thead th {
//     font-size: 14px !important;
//     div {
//       font-size: 12px !important;
//       font-weight: 500 !important;
//     }
//   }
//   .custom-table-body tr td {
//     font-family: "Poppins", sans-serif;
//     font-size: 12px !important;
//     font-weight: 400;
//     line-height: 17.4px !important;
//   }
// }
// @media (max-width: "1200px") {
//   .custom-table thead th {
//     font-size: 12px !important;
//     div {
//       font-size: 12px !important;
//       font-weight: 500 !important;
//     }
//   }
//   .custom-table-body tr td {
//     font-family: "Poppins", sans-serif;
//     font-size: 12px !important;
//     font-weight: 300 !important;
//     overflow: auto !important;
//   }
// }

// @media (max-width: "900px") {
//   .button_container button {
//     margin-right: 8px !important;
//   }
// }
// @media (max-width: "820px") {
//   .custom-table thead th {
//     div {
//       font-size: 12px !important;
//     }
//   }
// }

// @media (max-width: "1320px") {
//   .pagination_text {
//     font-size: 12px !important;
//   }
//   .pagination_select {
//     height: 28px !important;
//     width: 60px !important;
//     font-size: 12px !important;
//   }
//   .pagination_font {
//     font-size: 12px !important;
//   }

//   .pagination_option {
//     font-size: 12px !important;
//   }
// }

// .custom-table {
//   border-collapse: collapse;
//   width: 100%;
//   margin: 0 auto;
//   min-width: 650px;
// }

// .main_table {
//   // overflow: hidden;
//   // overflow: auto;
//   &:hover {
//     margin-right: 0px !important;
//     // overflow-y: auto;
//   }
// }

// .custom-table td {
//   text-align: left;
// }
// tbody {
//   // min-width: 650px;
//   // overflow: scroll;

//   &:hover {
//     // overflow-y: scroll;
//   }
// }

// .custom-table tbody tr:nth-child(odd) {
//   background-color: rgba(247, 249, 251, 1);
// }

// .custom-table tbody tr:nth-child(even) {
//   background-color: white;
// }

// .custom-table thead {
//   position: sticky;
//   top: 0;
//   z-index: 1;
//   background-color: white;
//   //   background-color: #f7f9fb;
// }

// .custom-table thead th {
//   font-family: "Poppins", sans-serif;
//   font-size: 20px !important;
//   font-weight: 500 !important;
//   line-height: 17.4px;
//   text-align: left;
//   text-transform: capitalize;
//   padding: 10px 0;
//   div {
//     font-size: max(16px, 1.4vw) !important;
//     font-weight: 500 !important;
//   }
// }

// .custom-table thead tr th:nth-child(1) {
//   > div {
//     margin-left: 20px;
//   }
// }
// .custom-table-body tr td {
//   font-family: "Poppins", sans-serif;
//   font-size: max(14px, 1.2vw);
//   font-weight: 500;
//   line-height: 17.4px;
//   text-align: left;
// }

// .custom-table tr:nth-child(1) td {
//   text-align: left;
// }

// .custom-table td {
//   font-size: max(14px, 1.2vw);
//   font-weight: 400;
//   padding: 20px 10;
// }
// .custom-table td:nth-child(1) {
//   padding: 20px;
// }

// .custom-table-body .even {
//   background-color: #fff;
// }

// .custom-table-body .odd {
//   background: rgba(217, 217, 217, 0.2);
// }

// .custom-table-body tr:hover {
//   background: rgb(129, 126, 255, 0.3);
// }

// .filterDiv {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 20px;
//   flex-wrap: wrap;
//   .mainSpan {
//     outline: 1px solid rgba(0, 0, 0, 0.25);
//     padding: 4px 22px;
//     border-radius: 10px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-weight: 500;
//     cursor: pointer;
//     &:hover {
//       outline: 1px solid rgba(0, 0, 0, 0.45);
//     }
//     .subSpan {
//       padding: 0 6px;
//       border-radius: 6px;
//       margin-left: 8px;
//       font-size: 12px;
//       color: black !important;
//     }
//   }
//   .active {
//     background-color: #817eff;
//     outline: none !important;
//     color: white;
//   }
// }

// .pagination {
//   justify-content: space-between;
//   padding: 10px 20px 0 20px;
//   align-items: center;
// }

// .custom-table tbody tr:hover {
//   background: rgba(217, 217, 217, 0.2);
// }

.custom-table {
  border-collapse: collapse;
  width: 100%;
  // width: 1080px;
  margin: 0 auto;
}

// .main_table {
//   border: 1px solid lightgray;
//   overflow-x: scroll;
//   width: 100%;
//   // max-width: 1060px;
//   position: relative;
//   max-height: 100%;
//   // height: 100%;
//   table {
//     width: 100% !important;
//     tbody {
//       overflow-y: scroll !important;
//     }
//   }
// }

.custom-table td {
  text-align: left;
}

.custom-table tbody tr:nth-child(odd) {
  background-color: rgba(247, 249, 251, 1);
}

.custom-table tbody tr:nth-child(even) {
  background-color: white;
}

.custom-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  //   background-color: #f7f9fb;
}

.custom-table thead th {
  font-family: "Poppins", sans-serif;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 17.4px;
  text-align: left;
  text-transform: capitalize;
  padding: 15px 0;
  border-bottom: 1px solid #808080;

  div {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

.custom-table thead tr th:nth-child(1) {
  > div {
    margin-left: 5px;
  }
}

.custom-table-body tr td {
  font-family: "Poppins", sans-serif;
  font-size: clamp(12px, 0.5vw, 24px);
  // font-weight: 500;
  // line-height: 17.4px;
  text-align: left;
}

.custom-table tr:nth-child(1) td {
  text-align: left;
}

.custom-table td {
  font-size: 16px;
  font-weight: 400;
  padding: 12px 0px;

  @media screen and (max-width: 1500px) {
    padding: 10px 10px;
  }

  @media screen and (max-width: 1100px) {
    // padding: 7px 10px;
    padding: 0;
  }
}

.custom-table td:nth-child(1) {
  // > div {
  //   margin-left: 10px;
  // }
  padding: 12px 10px;
  @media screen and (max-width: 1500px) {
    padding: 10px;
  }
}

.custom-table-body .even {
  background-color: #fff;
}

.custom-table-body .odd {
  background: rgba(217, 217, 217, 0.2);
}

.custom-table-body tr:hover {
  background: rgb(129, 126, 255, 0.3);
  // border: 1px solid var(--Secondary-Purple, rgba(198, 199, 248, 1))
}

.filterDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  // padding-left: 10px;
  .mainSpan {
    // outline: 0.5px solid rgb(0, 0, 0);
    outline: 1px solid rgba(0, 0, 0, 0.25);
    padding: 4px 22px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    // cursor: pointer;

    &:hover {
      outline: 1px solid rgba(0, 0, 0, 0.45);
    }

    .subSpan {
      padding: 0 6px;
      border-radius: 6px;
      margin-left: 8px;
      font-size: 12px;
      color: black !important;
    }
  }

  .active {
    background-color: #817eff;
    outline: none !important;
    color: white;
  }
}

.pagination {
  justify-content: space-between;
  padding: 10px 15px 0 15px;
  align-items: center;
}

.custom-table tbody tr:hover {
  background: rgba(217, 217, 217, 0.2);
  // background: rgb(129, 126, 255, 0.1);
}

@media (max-width: "1290px") {
  .custom-table thead th {
    font-size: 14px !important;

    div {
      font-size: 12px !important;
      font-weight: 500 !important;
    }
  }

  .custom-table-body tr td {
    font-family: "Poppins", sans-serif;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 17.4px !important;
  }
}

@media (max-width: "1200px") {
  .custom-table thead th {
    font-size: 12px !important;

    div {
      font-size: 12px !important;
      font-weight: 500 !important;
    }
  }

  .custom-table-body tr td {
    font-family: "Poppins", sans-serif;
    font-size: 12px !important;
    font-weight: 300 !important;
    overflow: auto !important;
  }
}

@media (max-width: "900px") {
  .button_container button {
    margin-right: 8px !important;
  }
}

@media (max-width: "820px") {
  .custom-table thead th {
    div {
      font-size: 12px !important;
    }
  }
}

@media (max-width: "1320px") {
  .pagination_text {
    font-size: 12px !important;
  }

  .pagination_select {
    height: 28px !important;
    width: 60px !important;
    font-size: 12px !important;
  }

  .pagination_font {
    font-size: 12px !important;
  }

  .pagination_option {
    font-size: 12px !important;
  }
}

.ant-pagination-item {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  min-width: 40px !important;
}

.ant-pagination-item a {
  font-size: 14px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  width: 40px !important;
  // height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-pagination-item-active {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}
