.Forget_password {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .loginLeft {
    height: 100%;
    width: 50%;
    padding: 0;
    // border: 1px solid black !important;
    .backgroundImage {
      height: 100%;
      width: 50vw;
      background-image: url(../../../Assets/Login/Hero.webp);
      //   background-repeat: no-repeat;
      //   background-attachment: fixed;
      background-size: cover;
      background-position: center center;
      display: flex;
      justify-content: center;
      align-items: center;
      // flex-direction: column;
      //   aspect-ratio: 16 / 9;
      .innerContent {
        width: 70%;
        height: 70%;
        display: flex;
        gap: max(15px, 1.3vw);
        // border: 1px solid black;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // input {
        //   height: 80px !important;
        // }

        .innerTop {
          // width: 50%;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // flex-direction: column;
          // gap: 50px;
          // margin-left: 50%;
          width: 50%;
          height: 65%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          flex-direction: column;
          gap: max(18px, 1.6vw);
          margin-left: 50%;
          // border: 1px solid white;
          .loginPageLogo {
            width: 55%;
            height: max(40px, 9vw);
            min-height: 100px;
            max-height: 100%;
            // border: 1px solid white;
            display: flex;
            justify-content: right;
            align-items: flex-end;
            margin-right: 20px;
            img {
              height: 80%;
              // width: 60%;
              // min-height: 100px !important;
              // max-height: 100px;
              // border: 1px solid white;
            }
          }
          .loginPageSolace {
            width: 100%;
            // height: 50px;
            // border: 1px solid white;
            display: flex;
            justify-content: right;
            img {
              width: 60%;
              // height: 100%;
              min-width: 120px;
              // max-width: 120px;
              // border: 1px solid white;
            }
          }
        }

        // .innerBottom{
        p {
          // width: 100%;
          // color: white;
          // text-align: center;
          // font-size: 28px;
          // font-family: "Poppins", sans-serif;
          width: 110%;
          // border: 1px solid white;
          padding-top: 10px;
          color: white;
          text-align: right;
          min-width: 200px;
          font-size: 35px;

          // font-size: min(20px, 1.7vw) !important;
        }

        .borderDiv {
          height: 2px;
          width: 110%;
          min-width: 140px;
          background-color: white;
        }

        // }
      }
    }
  }

  .loginRight {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    // cursor: pointer;
    .goBack {
      // margin-bottom: max(10px, 1.5vw);
      color: #000047;
      display: flex;
      align-items: center;
      gap: max(10px, 0.8vw);
      font-size: clamp(12px, 1.1vw, 35px);
      cursor: pointer;
      img {
        transform: scaleX(-1);
      }
      &:hover {
        text-decoration: underline;
        color: #000047;
      }
      margin-bottom: 20px;
      @media screen and (min-width: 2000px) {
        font-size: 24px;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 1000px) {
        margin-bottom: 10px;
      }
    }

    .innerContent {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
      width: 80%;
      // border: 1px solid black;
      // gap: 40px;

      .inputStyle {
        background: rgba(217, 217, 217, 0.4);
        // border-radius: max(7px, 0.6vw);
        height: 55px;
        @media screen and (min-width: 2000px) {
          height: 90px;
          padding: 7px 20px;
        }
        border-radius: 10px;
        width: 80%;
        min-width: 220px;
        padding: 4px 20px;
        border: none;
        margin-top: max(12px, 1.1vw);
        font-size: clamp(12px, 1.1vw, 35px);

        &:focus {
          border: none;
          outline: none;
        }
      }
    }

    .forgetPasswordInnerWrapper {
      width: 100%;
      h2 {
        font-size: 26px;
        font-weight: 500;
        line-height: 48.28px;
        text-align: left;
        color: #000047;
        margin-bottom: 15px;
        @media (max-width: 1000px) {
          margin-bottom: 10px;
        }
        @media screen and (min-width: 2000px) {
          font-size: 36px;
          margin-bottom: 30px;
        }
      }
      .forgetPasswordInnerWrapperText {
        font-size: 18px;
        margin-bottom: 20px;
        @media (max-width: 1000px) {
          margin-bottom: 15px;
        }
        @media screen and (min-width: 2000px) {
          font-size: 36px;
          margin-bottom: 30px;
        }
      }

      p:nth-child(3) {
        font-size: 18px;
        margin-bottom: 20px;
        @media (max-width: 1000px) {
          margin-bottom: 15px;
        }
        @media screen and (min-width: 2000px) {
          font-size: 36px;
          margin-bottom: 30px;
        }
      }

      p:nth-child(6) {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        @media (max-width: 1000px) {
          margin-bottom: 15px;
        }

        color: rgba(0, 0, 71, 1);
        cursor: pointer;
      }

      p:nth-child(6):hover {
        text-decoration: underline;
      }

      button {
        font-size: clamp(12px, 1.1vw, 35px);
        font-weight: 500;
        color: white;
        background-color: rgb(129, 126, 255, 0.8);
        // padding: 10px;
        padding: 15px 20px;

        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        letter-spacing: 3px;
        img {
          width: max(20px, 2.8vw);
        }
        @media screen and (min-width: 2000px) {
          margin-top: 30px;
        }
      }
      button:hover {
        background-color: rgb(129, 126, 255, 1);
      }
    }
  }
}

.loginMainImg1 {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.2;
}

.loginRightMainImg1 {
  position: absolute;
  bottom: 95%;
  right: 33%;
  opacity: 0.2;
  transform: rotate(180deg);
}
