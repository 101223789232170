.accounts_main {
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    background-color: #f7f9fb;
    padding: 13px 0 13px 10px;
    text-align: start;
    // border: 1px solid black;
    // margin-left: 15px;
    @media screen and (max-width: 835px) {
      // margin-bottom: 20px;
    }
    button {
      color: #8c8c8c;
      font-family: "Poppins", sans-serif;
      // font-size: max(14px, 1vw);
      font-size: 14px;
      font-weight: 400;
      // line-height: 19.2px;
      text-align: center;
      border: none;
      background-color: #f7f9fb;
      margin-right: 20px;
      // width: 174px;
      border-radius: 8px;
      padding: 10px 18px;
      text-wrap: nowrap;
      @media screen and (max-width: 835px) {
        padding: 9px 8px !important;
        width: 140px;
      }
      @media screen and (min-width: 2000px) {
        font-size: 18px;
      }
      // border: 1px solid black;
      &.active {
        background-color: #817eff;
        color: #fff;
      }
      &.inactive:hover {
        background-color: rgb(129, 126, 255, 0.1);
        color: black;
      }
    }
  }
  .content_wrapper {
    height: 100%;
    padding: min(30px, 1.4vh) 15px;
    // border: 1px solid black;
  }
}
