.periodic_insepction {
  background-color: #fff;
  border-radius: clamp(15px, 1.3vw, 50px);
  padding: 25px 20px 44px 20px;
  width: 100%;

  @media screen and (max-width: 1075px) {
    height: 300px;
  }

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1075px) {
    padding: 25px 20px 20px 20px;
  }

  .header {
    color: #000;
    font-family: "Poppins", sans-serif;
    font-size: clamp(17px, 1.4vw, 60px);
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    margin-bottom: 10px;

    @media screen and (max-width: 1075px) {
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .periodic_insepction_inner_wrapper {
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 18px;

    @media screen and (min-width: 1920px) and (min-height: 1080px) {
      gap: 65px;
    }

    .progress_main {
      gap: 10px;

      @media screen and (min-height: 1075px) {
        gap: 0px;
      }
    }

    .animated:hover {
      box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
      border-radius: 5px;
      transform: scale(1.03);
    }

    .animated {
      cursor: pointer !important;
    }

    .notAnimated {
      cursor: default !important;
    }

    .animated,
    .notAnimated {
      transition: background-position 0.2s ease, transform 0.2s ease-in-out;
      cursor: pointer;
      padding: 3.5px 10px;

      .main_text {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;

        .text {
          font-family: "Poppins", sans-serif;
          font-size: clamp(10px, 1vw, 24px);
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }

        >span:nth-child(2) {
          text-align: right;
          font-family: "Poppins", sans-serif;
          font-size: clamp(10px, 1vw, 24px);
          font-style: normal;
          font-weight: 500;
        }
      }
    }
  }
}