.MaintanenceDash_wrapper_main {
  background-color: #fff;
  border-radius: clamp(15px, 1.3vw, 50px);
  padding: max(7px, 2vh) 6% 3px 6%;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 1075px) {
    height: 300px !important;
  }

  // min-height: 373px;

  .arrear_header {
    display: flex;
    height: 17%;
    display: flex;
    align-items: center;

    >h1 {
      font-size: clamp(17px, 1.4vw, 60px);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
      font-weight: 400;
      position: relative;

      @media screen and (max-width: 1075px) {
        line-height: 45px;
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 0;
        height: 3px;
        background-color: #7c4dff;
        transition: width 0.4s ease-out;
      }
    }

    h1:nth-of-type(2) {
      border-top-right-radius: 13px;
    }

    .active {
      color: #7c4dff;

      &::after {
        width: 100%;
      }
    }
  }

  .MaintanenceDash_graph_wrapper {
    height: 73%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px;

    @media screen and (min-width: 3440px) and (max-width: 3440px) and (min-height: 1440px) {

      padding: 20px 0px;
    }

    .graph {
      // width: 40%;
      // height: 500px;
      // margin-top: -25px;
      // margin-left: -10px;
      //       @media screen and (min-width: 3440px) and (max-width: 3440px) and (min-height: 1440px) {
      // top: 80px !important;
      // left: 75px !important;
      //       }

      //       @media screen and (max-width: 1075px) {
      //         line-height: 45px;
      //         top: 20% !important;
      //         left: 38px !important;
      //       }
    }

    >ul {
      padding: 0;
      margin: 0;
      width: 55%;
      display: flex;
      flex-direction: column;
      height: 100%;

      justify-content: space-evenly;

      @media screen and (min-width: 3440px) and (max-width: 3440px) and (min-height: 1440px) {
        gap: 10px;
      }

      ;

      @media screen and (max-width: 1075px) {
        width: 45%;

      }

      li {
        height: 30px;

        @media only screen and (min-width: 1020px) and (max-width: 1250px) {
          height: 35px;
        }

        @media screen and (max-width: 1075px) {
          height: 35px;

        }
      }

      .animated:hover {
        transform: scale(1.03);
        box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
        transition: all 0.3s ease;
      }

      .animated {
        cursor: pointer;
      }

      .notAnimated {
        cursor: default !important;
      }

      .animated,
      .notAnimated {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 3px 10px;
        cursor: pointer;
        transition: background-position 0.2s ease, transform 0.2s ease-in-out;

        span {
          color: #2d2d2d;
          font-family: "Poppins", sans-serif;
          font-size: clamp(8px, 0.83vw, 24px);
          font-style: normal;
          font-weight: 400;
          display: flex;
          align-items: center;

          @media screen and (max-width: 1075px) {
            font-size: 13px;
          }

          .badge1 {
            width: max(8px, 0.7vw);
            height: max(8px, 0.7vw);
            background: #75e291;
            border-radius: 50%;
            margin-right: 7px;
          }

          .badge2 {
            width: max(8px, 0.7vw);
            height: max(8px, 0.7vw);
            background-color: #ffc650;
            border-radius: 50%;
            margin-right: 7px;
          }

          .badge3 {
            width: max(8px, 0.7vw);
            height: max(8px, 0.7vw);
            background-color: #ff5b5c;
            border-radius: 50%;
            margin-right: 7px;
          }

          .badge4 {
            width: max(8px, 0.7vw);
            height: max(8px, 0.7vw);
            background-color: #52c2ef;
            border-radius: 50%;
            margin-right: 7px;
          }

          .badge5 {
            width: max(8px, 0.7vw);
            height: max(8px, 0.7vw);
            background-color: #6062f0;
            border-radius: 50%;
            margin-right: 7px;
          }

          .badge6 {
            width: max(8px, 0.7vw);
            height: max(8px, 0.7vw);
            background-color: #ff9e6f;
            border-radius: 50%;
            margin-right: 7px;
          }
        }

        >p {
          color: #2d2d2d;
          font-family: "Poppins", sans-serif;
          font-size: clamp(8px, 0.85vw, 24px);
          font-style: normal;
          font-weight: 400;
          margin-bottom: 0;

          @media screen and (max-width: 1075px) {
            font-size: 13px;
          }
        }
      }
    }
  }
}