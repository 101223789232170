.InfoQuesPopup {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.23456);
  .popupContainer {
    width: 550px;
    height: 310px;
    padding: 15px;
    z-index: 1000;
    background: white;
    border-radius: 20px;
    @media screen and (max-width: 1400px) {
      width: 500px;
      height: 280px;
    }
    .heading {
      font-size: 30px;
      font-weight: 500;
    }

    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid black;
      padding: 0 0 10px 0;
    }
    .infoConfig {
      h2 {
        font-weight: 500;
        @media screen and (max-width: 1400px) {
          font-size: 30px;
        }
      }
      p {
        @media screen and (max-width: 1400px) {
          font-size: 12px;
        }
      }
    }
  }
}
