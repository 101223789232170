.reports {
  height: 100%;
  background-color: #f7f9fb;
  .custom-date-range-picker {
    border-radius: 4px;
    width: 300px;
  }

  .tableComp {
    height: calc(100% - 100px);

    @media screen and (min-width: 1920px) and (min-height: 1080px) {
      height: calc(100% - 140px);
    }
    .main_table {
      padding: 28px;
      height: calc(100% - 70px);
      @media screen and (min-width: 1920px) and (min-height: 1080px) {
        tbody tr td {
          font-size: 25px;
          padding: 35px 0px;
        }
        thead th div {
          font-size: 25px !important;
          padding: 25px 0px !important;
        }
      }
      thead {
        border-bottom: 1px solid #d9d9d9;
        // border-top: 1px solid rgba(0, 0, 0, 1);
      }
    }
  }
  .header {
    // padding: 40px 20px;
    .table_filters {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // padding: 20px 27px !important;
      @media screen and (max-width: 860px) {
        padding-left: 10px;
        margin-left: 10px;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
      }
      @media screen and (max-width: 1056px) {
        flex-direction: row !important;
        // padding: 20px 27px !important;
      }
      .button_container {
        margin: 15px;
        text-align: left;
        width: fit-content;
        button {
          padding: 10px 13px;
          border-radius: 7px;
          border: 1px solid rgb(225, 233, 238);
          background-color: #fff;
          text-wrap: nowrap;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          margin-right: 15px;
          font-weight: 400;
          line-height: 17.4px;
          text-align: left;
          @media screen and (max-width: 1000px) {
            padding: 3px 13px !important;
            height: 35px;
          }
          &.active {
            background: rgba(129, 126, 255, 1);
            color: #fff;
          }
        }
      }
    }
  }
}
