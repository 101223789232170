.loginMain {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  .loginLeft {
    height: 100%;
    width: 50%;
    padding: 0;
    // border: 1px solid black !important;
    .backgroundImage {
      height: 100%;
      width: 100%;
      background-image: url(../../../Assets/Login/Hero.webp);
      //   background-repeat: no-repeat;
      //   background-attachment: fixed;
      background-size: cover;
      background-position: center center;
      display: flex;
      justify-content: center;
      align-items: center;
      // flex-direction: column;
      //   aspect-ratio: 16 / 9;
      .innerContent {
        width: 70%;
        height: 70%;
        display: flex;
        // border: 1px solid black;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // input {
        //   height: 80px !important;
        // }

        .innerTop {
          width: 50%;
          height: 65%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          flex-direction: column;
          gap: max(14px, 1.5vw);
          margin-left: 50%;
          // border: 1px solid white;
          .loginPageLogo {
            width: 55%;
            height: max(40px, 9vw);
            min-height: 100px;
            max-height: 100%;
            display: flex;
            justify-content: right;
            align-items: flex-end;
            margin-right: 20px;
            // border: 1px solid white;
            img {
              height: 80%;
              // height: max(80px, 8vw);
              // max-height: 100px;
            }
          }
          .loginPageSolace {
            width: 100%;
            // height: 50px;
            // border: 1px solid white;
            display: flex;
            justify-content: right;
            img {
              width: 60%;
              // height: 100%;
              min-width: 120px;
              // max-width: 120px;
              // border: 1px solid white;
            }
          }
        }

        // .innerBottom{
        p {
          // width: 100%;
          // color: white;
          // text-align: center;
          // font-size: 28px;
          // font-family: "Poppins", sans-serif;
          width: 110%;
          // border: 1px solid white;
          padding-top: 10px;
          color: white;
          text-align: right;
          // font-size: max(16px, 1.4vw) !important;
          font-size: 35px;
          @media (max-width: 1000px) {
            font-size: 30px;
          }
          min-width: 330px;
          // font-size: min(20px, 1.7vw) !important;
        }

        .borderDiv {
          height: 2px;
          width: 120%;
          min-width: 140px;
          background-color: white;
        }

        // }
      }
    }
  }

  .loginRight {
    width: 50%;
    min-width: 350px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    .innerContent {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      text-align: left;
      width: 80%;
      // border: 1px solid black;
      // gap: 25px;
      form {
        width: 100%;
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        // gap: max(12px, 1.1vw);
        gap: 10px;
        @media screen and (max-width: 1000px) {
          gap: 5px;
        }
        // border: 2px solid green;
        // border: 1px solid black;
      }
      .inputStyle {
        display: flex;
        justify-content: flex-start;
        gap: 30px;
        flex-direction: column;
        @media screen and (max-width: 1000px) {
          gap: 20px;
        }
        @media screen and (min-width: 2000px) {
          gap: 50px;
        }
      }

      input {
        background: #f7f9fb;
        // width: 95%;
        padding: 4px 20px;
        // margin-bottom: 15px;
        font-size: max(12px, 1.1vw) !important;
        width: 90% !important;
        border: none;
        height: 55px;
        border-radius: 10px;
        @media screen and (max-width: 1000px) {
          height: 38px;
        }
        @media screen and (min-width: 2000px) {
          height: 90px;
          padding: 7px 20px;
        }

        // &::placeholder {
        //   font-family: "Poppins", sans-serif;
        //   font-size: 16px;
        //   font-weight: 300;
        //   line-height: 48.28px;
        //   text-align: left;
        //   color: #000000;
        // }
        &:focus {
          border: none;
          outline: none;
        }
      }
      ::placeholder {
        color: #383939;
        opacity: 1; /* Firefox */
        font-size: max(1.1vw, 12px);
      }

      ::-ms-input-placeholder {
        /* Edge 12 -18 */
        color: #383939;
      }
    }

    h2 {
      font-family: "Poppins", sans-serif;
      font-size: max(20px, 2.7vw);
      font-weight: 500;
      line-height: 48.28px;
      text-align: left;
      color: #000047;
      margin-bottom: max(10px, 1.3vw);
      // border: 1px solid black;
    }

    p:nth-child(2) {
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      // margin-bottom: 47px;
      // margin-bottom: max(10px, 2.5vw);
      margin-bottom: 30px;
      color: black;
      @media screen and (min-width: 2000px) {
        font-size: 28px;
      }
      // font-weight: 300;
    }
    p:nth-child(5),
    .forgetPassword {
      font-size: max(12px, 1.1vw) !important;
      font-weight: 500;
      color: #000047;
      cursor: pointer;
      margin-top: max(10px, 1vw);
    }
    p:nth-child(5):hover,
    .forgetPassword:hover {
      text-decoration: underline;
    }

    // a:nth-child(5) {
    //   font-family: "Poppins", sans-serif;

    //   font-size: 21px;
    //   font-weight: 500;
    //   color: rgba(0, 0, 71, 1);
    //   cursor: pointer;
    //   margin-bottom: 20px;
    // }

    // a:nth-child(5):hover {
    //   text-decoration: underline;
    // }
    .button_container {
      // margin-top: 32px;
      // button {
      //   font-size: 21px;
      //   font-weight: 500;
      //   color: white;
      //   background-color: #817eff;
      //   padding: 10px 25px;
      //   border: none;
      //   border-radius: 8px;
      //   letter-spacing: 4px;
      //   font-family: "Poppins", sans-serif;
      // }

      // button:hover {
      //   background-color: rgb(129, 126, 255, 1);
      // }
      button {
        font-size: clamp(12px, 1.1vw, 35px);
        font-weight: 500;
        color: white;
        background-color: rgb(129, 126, 255, 0.8);
        // padding: max(8px, 0.7vw) max(12px, 1.15vw);
        padding: 15px 20px;
        // border-radius: max(10px, 0.9vw);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        // gap: max(10px, 1.5vw);
        gap: 10px;
        // margin-top: max(10px, 1vw);
        margin-top: 10px;
        letter-spacing: 3px;
        img {
          width: max(20px, 2.8vw);
        }
        @media screen and (min-width: 2000px) {
          font-size: 30px;
        }
      }
      button:hover {
        background-color: rgb(129, 126, 255, 1);
      }
    }
  }
  .customPasswordButton {
    div {
      // background-color: #f7f9fb !important;
      background-color: red !important;
      height: 40px !important;
      // border: 1px solid red;
      width: 91% !important;
      border-radius: 10px !important;
      // margin-bottom: 10px;
    }
    input {
      margin: 0 !important;
      height: 28px !important;
    }
  }
}

.loginMainImg1 {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.2;
}

.loginRightMainImg1 {
  position: absolute;
  bottom: 95%;
  right: 33%;
  opacity: 0.2;
  transform: rotate(180deg);
}
