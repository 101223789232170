.home_wrapper {
  width: 100%;
  padding: clamp(16px, 1.2vw, 38px);
  background-color: #f7f9fb;
  // background-color: black;
  // overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  // height: calc(100% - -328px);
  @media (min-width: 3840px) {
    height: 100%;
  }
  @media screen and (max-width: 1075px) {
    // height: calc(100% - -770px);
    gap: 0px;
  }

  // min-height: 100vh;
  // @media screen and (max-width: 1020px) {
  //   gap: 0px;
  //   // overflow: auto;
  // }

  .home_inner_wrapper {
    display: flex;
    // height: 65.5%;
    // min-height: 630px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    flex-direction: row;
    justify-content: space-between;
    @media (min-width: 3840px) {
      height: 65.5%;
    }
    &.column-layout {
      flex-direction: column;
      height: auto;
      row-gap: 1%;
    }
    @media screen and (max-width: 1075px) {
      flex-direction: column;
      height: auto !important;
      row-gap: 1%;
    }

    .complains-tab {
      width: 62.5%;
      height: 100%;
      @media screen and (max-width: 1075px) {
        width: 100%;
        // height: 80vh;
      }
    }

    .arrearRecept {
      width: 36%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // margin-top: 5px;
      @media screen and (max-width: 1075px) {
        width: 100%;
        flex-direction: row;
        // margin-top: 15px;
        // margin-top: 10px;
      }
      .arrearHome,
      .payHome {
        height: 49%;
        width: 100%;
        @media screen and (max-width: 1075px) {
          height: 220px;

          // width: 100%;
          width: 49%;
        }
      }
    }
  }
  .home-below {
    // height: 31.5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin-top: 20px;
    @media (min-width: 3840px) {
      height: 31.5%;
    }
    .periodHome,
    .currentHome {
      width: 30.5%;
      height: 100%;
      height: max-content;
      @media screen and (max-width: 1075px) {
        margin: 20px 0 15px 0;
        // height: 220px;
        height: max-content;
      }
      @media screen and (max-height: 900px) {
        // height: 37vh;
      }
      @media screen and (max-width: 800px) {
        width: 50%;
      }
    }
    @media screen and (max-width: 1075px) {
      .periodHome {
        width: 49%;
      }
      .currentHome {
        width: 49%;
      }
    }
    .mainDashDown {
      width: 36%;
      // height: 100%;
      @media screen and (max-width: 1075px) {
        width: 100%;
        height: 315px;
      }
    }
  }
}
