.compliancepage_wrapper {
  background-color: #fff;
  border-radius: clamp(15px, 1.3vw, 50px);
  height: 100% !important;
  .complaince-details {
    padding-bottom: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: 1075px) {
      margin-bottom: 10px;
    }
    .complaince_list_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4%;
      @media screen and (max-width: 1200px) {
        padding: 20px 4%;
      }
      @media screen and (max-width: 1000px) {
        padding: 16px 4%;
      }
      // height: 18%;

      > h1 {
        font-family: "Poppins", sans-serif;
        font-size: clamp(17px, 1.4vw, 60px);
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        width: 57%;
        min-width: 200px;
      }

      .due-overdue {
        width: 42%;
        min-width: 240px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60%;
        .nodue {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          gap: 10px;

          > span {
            font-family: "Poppins", sans-serif;
            font-size: clamp(12px, 1.1vw, 32px);
            font-weight: 400;
            line-height: 14.4px;
            text-align: center;
            color: #000000;
          }

          .badge1 {
            width: clamp(7px, 0.6vw, 20px);
            height: clamp(7px, 0.6vw, 20px);
            background-color: #4dde87;
            border-radius: 50%;
          }
        }

        .due {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          gap: 10px;

          > span {
            font-family: "Poppins", sans-serif;
            font-size: clamp(12px, 1.1vw, 32px);
            font-weight: 400;
            line-height: 14.4px;
            text-align: center;
            color: #000000;
          }

          .badge2 {
            width: clamp(7px, 0.6vw, 20px);
            height: clamp(7px, 0.6vw, 20px);
            background-color: #ffb200;
            border-radius: 50%;
          }
        }

        .overdue {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          gap: 10px;

          > span {
            font-family: "Poppins", sans-serif;
            font-size: clamp(12px, 1.1vw, 32px);
            font-weight: 400;
            line-height: 14.4px;
            text-align: center;
            color: #000000;
          }

          .badge3 {
            width: clamp(7px, 0.6vw, 20px);
            height: clamp(7px, 0.6vw, 20px);
            background-color: #ff3a29;
            border-radius: 50%;
          }
        }
      }
    }

    .due-details-list {
      // height: 82%;
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 4px !important;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent !important;
      }
      &::-webkit-scrollbar-thumb {
        background: #e5eef4 !important;
        cursor: pointer;
      }
      .list-view {
        // border-top: 0.5px dotted #d2d6e0;
        padding: 0 3%;

        .lists:hover {
          background: rgb(79, 195, 244, 0.06);
        }

        > li {
          padding: clamp(8px, 1.2vh, 15px) 8px;
          border-bottom: 0.5px dotted #d2d6e0;

          > span {
            color: #2d2d2d;
            font-family: "Poppins", sans-serif;

            font-size: clamp(10px, 1vw, 22px);
            font-style: normal;
            font-weight: 400;
            line-height: 120%;

            > img {
              margin-right: 15px;
              min-width: 43px;
              min-height: 43px;
              height: clamp(43px, 4.5vw, 65px);
              width: clamp(43px, 4.5vw, 65px);
            }
          }

          > .listdue {
            width: 43%;
            align-items: center;
            > div {
              text-align: center;
              font-family: "Poppins", sans-serif;
              font-size: clamp(13px, 1.15vw, 25px);
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
            }
          }
        }
      }
    }
  }
}
