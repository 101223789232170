.compliance_main {
  width: 100%;
  // position: absolute;
  // margin-left: 333px;
  top: 0;
  // overflow: auto;
  // padding: 36px 28px 0px 0px;
  padding: 20px;
  background-color: #f7f9fb;
  height: calc(100% - 0px);
  overflow: hidden;

  .compliance_content_wrapper {
    justify-content: space-between;
    height: 100%;
    // height: calc(100% - 1px);
    // height: calc(100% - 17px);
    display: flex;
    // border: 1px solid black;
    gap: 1%;
    .comcomlist {
      width: 35%;
      height: 100%;
      margin-left: -20px;
      display: flex;
      // border: 1px solid black;
      // align-items: center;
    }
    .comcomtable {
      width: 64.7%;
      height: 100%;
      // border: 1px solid black;
    }
  }
}
