// .reserve {
//   // height: 100%;
//   height: calc(100% - -5px);
//   .css-dev-only-do-not-override-j9bb5n {
//     height: 100%;
//   }
//   .card_parent {
//     .card {
//       width: 246px;
//       // height: 86px;
//       padding: 16px 20px 20px 20px;
//       border-radius: 10px;
//       background-color: #817eff;
//       color: #fff;
//       border: none;
//       justify-content: space-between;
//       p {
//         font-family: "Poppins", sans-serif;
//         font-size: 13px;
//         font-weight: 500;
//         line-height: 17.4px;
//         text-align: left;
//         color: var(--Primary-Light, #f7f9fb);
//       }
//       h1 {
//         font-family: "Poppins", sans-serif;
//         font-size: 24px;
//         font-weight: 300;
//         line-height: 18px;
//         text-align: left;
//         color: var(--Primary-Light, #f7f9fb);
//         margin: 25px 0px 0px 0px;
//       }
//     }
//   }
//   h1 {
//     font-family: "Poppins", sans-serif;

//     font-size: 26px;
//     font-weight: 500;
//     line-height: 28.8px;
//     text-align: left;
//     margin-top: 25px;
//   }
//   .table_headers {
//     display: flex;
//     justify-content: space-between;
//     margin-top: 30px;
//     > div {
//       display: flex;
//       gap: 24px;
//       justify-content: space-between;
//     }
//   }

//   .main_content {
//     // margin-top: 12px;
//     // .main_table {
//     //   height: 605px;
//     //   overflow: auto;
//     //   padding: 0 !important;
//     //   thead {
//     //     border-bottom: 1px solid rgba(0, 0, 0, 1);
//     //   }
//     // }
//     height: calc(100% - 243px);
//     border-radius: 13px;
//     overflow: auto;
//     margin-top: 12px;
//     .main_table {
//       height: calc(100% - 66px);
//       overflow: auto;
//       thead {
//         border-bottom: 1px solid rgba(0, 0, 0, 1);
//       }
//     }
//   }
// }

.reserve {
  height: 100%;

  // border: 1px solid black;
  .revHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h1 {
      text-wrap: nowrap;
      font-size: max(20px, 1.4vw);
    }

    .searchReserve {
      width: 200px;
    }
  }

  .css-dev-only-do-not-override-j9bb5n {
    height: 100%;
  }

  h1 {
    font-family: "Poppins", sans-serif;

    // font-size: 26px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
  }

  .table_headers {
    display: flex;
    justify-content: space-between;
    margin-top: 9px;
    width: 100%;

    .card {
      padding: 7px 8px;
      border-radius: 10px;
      background-color: #817eff;
      color: #fff;
      border: none;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;

      img {
        width: max(25px, 2vw);
        height: max(25px, 2vw);
      }

      // border: 1px solid black;
      p {
        font-family: "Poppins", sans-serif;
        font-size: max(14px, 1vw);
        font-weight: 500;
        line-height: 17.4px;
        text-align: left;
        text-wrap: nowrap;
        color: var(--Primary-Light, #f7f9fb);
        // width: 160px;
      }

      h1 {
        font-family: "Poppins", sans-serif;
        font-size: max(18px, 1.2vw);
        font-weight: 300;
        line-height: 18px;
        text-align: left;
        color: var(--Primary-Light, #f7f9fb);
      }
    }

    // border: 1px solid black;
    > div {
      display: flex;
      gap: 12px;
      justify-content: space-between;
      width: fit-content;
      // border: 1px solid black;
    }
  }

  .main_content {
    margin-top: 12px;
    height: calc(100% - 100px);

    .main_table {
      height: calc(100% - 45px);
      width: 100%;
      padding: 0 !important;
      overflow: auto;

      @media screen and (min-width: 1920px) and (min-height: 1080px) {
        height: calc(100% - 175px);

        tbody tr td {
          font-size: 25px;
          padding: 35px 0px;
        }

        thead th div {
          font-size: 25px !important;
          padding: 25px 0px !important;
        }
      }

      thead {
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
}
