.compliance_table {
  height: 100%;
  background-color: #ffffff;
  border-radius: 20px;

  .header {
    // padding: 42px 47px 30px 48px;
    padding: 20px;
    height: 23%;

    .text_container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 40%;
      // border: 1px solid black;
      height: 35%;

      .complaintextheading {
        height: 100%;
        display: flex;
        gap: 2%;
        align-items: center;
        // border: 1px solid black;

        > img {
          width: max(22px, 2vw);
          height: max(22px, 2vw);
        }

        > h1 {
          //styleName: H1;
          font-family: "Poppins", sans-serif;
          font-size: max(16px, 1.5vw);
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          margin-bottom: 0;
          text-wrap: nowrap;

          // text-wrap: nowrap;
          @media screen and (max-width: 985px) {
            font-size: 12px;
          }
        }
      }
    }

    .progress_wrapper {
      // margin-top: 10px;
      // border: 1px solid black;
      height: 60%;

      .progress_main {
        margin-top: 15px;
        // width: 256px;
        margin-bottom: 21px;

        .main_text {
          display: flex;
          justify-content: space-between;

          .text {
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 10px;
          }

          > span:nth-child(2) {
            text-align: right;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #f2f2f2;
    margin: auto;
  }

  .main_tablecontent {
    height: 77%;
    // border: 1px solid black;

    .com_table_filter {
      padding: 9px 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media (max-width: "1055px") {
        flex-direction: column;
        gap: 10px;
        // justify-content:flex-start;
        align-items: center;
        justify-content: center;
      }

      .com_button_container {
        // margin: 15px;
        text-align: left;
        width: fit-content;

        // button:nth-child(1) {
        //   background: rgba(129, 126, 255, 1);
        //   color: #fff;
        // }
        .active {
          background: rgba(129, 126, 255, 1);
          color: #fff;
        }

        button {
          padding: max(8px, 0.6vw) max(10px, 0.7vw);
          border-radius: 7px;
          border: 1px solid rgb(225, 233, 238);
          background-color: #fff;
          text-wrap: nowrap;
          font-family: "Poppins", sans-serif;
          font-size: max(14px, 0.8vw);
          margin-right: 15px;
          font-weight: 400;
          line-height: 17.4px;
          text-align: left;
          display: flex;
          justify-content: center;
          align-items: center;
          width: fit-content;

          @media screen and (max-width: 1800px) {
            padding: 6px 14px;
            font-size: 10px;
            margin-right: 5px;
          }
          @media screen and (max-width: 1600px) {
            padding: 4px 12px;
            font-size: 10px;
            margin-right: 5px;
          }
          @media screen and (max-width: 1300px) {
            padding: 3px 10px;
            font-size: 10px;
            margin-right: 5px;
          }

          // @media screen and (max-width: 1560px) {
          //   font-size: ;
          // }

          span {
            border-radius: 3px;
            font-family: "Poppins", sans-serif;
            // font-size: 16px;
            font-size: clamp(14px, 0.9vw, 20px) !important;
            font-weight: 500;
            line-height: 13px;
            text-align: center;
            margin-left: 10px;
            color: #000 !important;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0;

            @media screen and (max-width: 1500px) {
              padding: 4.5px;
              font-size: 10px !important;
              width: fit-content !important;
            }
            @media screen and (max-width: 1300px) {
              padding: 4px;
              font-size: 10px !important;
              width: fit-content !important;
            }
          }
        }

        button:nth-child(1) {
          span {
            background: rgba(230, 231, 255, 1);
          }
        }

        button:nth-child(2) {
          span {
            background: rgba(206, 255, 225, 1);
          }
        }

        button:nth-child(3) {
          span {
            background: rgba(255, 233, 181, 1);
          }
        }

        button:nth-child(4) {
          span {
            background: rgba(255, 211, 207, 1);
          }
        }

        button:nth-child(5) {
          span {
            background: rgba(223, 245, 255, 1);
          }
        }

        button:nth-child(6) {
          span {
            background: rgba(255, 206, 243, 1);
          }
        }

        button:nth-child(7) {
          span {
            background: rgba(168, 197, 218, 1);
          }
        }
      }
    }

    .table_compliance {
      height: 85%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // border: 1px solid black;
      .main_table {
        height: calc(100% - 70px);
        overflow: auto;

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          tbody tr td {
            font-size: 25px;
            padding: 35px 0px;
          }

          thead th div {
            font-size: 25px !important;
            padding: 25px 0px !important;
          }
        }

        thead {
          border-bottom: 1px solid #d9d9d9;
        }

        td:nth-of-type(1) {
          width: 20% !important;
        }

        td:nth-of-type(2) {
          width: 15% !important;
        }

        td:nth-of-type(3) {
          width: 45% !important;
        }

        td:nth-of-type(4) {
          width: 10% !important;
        }

        .status_button {
          width: fit-content;
          height: 21px;
          padding: 2px 8px 2px 8px;
          border-radius: 5px;
          font-family: "Poppins", sans-serif;
          font-size: 13px;
          font-weight: 400;
          line-height: 17.4px;
          text-align: center;
          color: #2d2d2d;
        }
      }
    }
  }
}
