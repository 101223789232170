.popup_main_wrapper {
  opacity: 0;

  @keyframes popupOpen {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }

    100% {
      opacity: 1;
      display: block !important;
      transform: scale(1);
    }
  }

  /* Closing Animation */
  @keyframes popupClose {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      display: none;
      transform: scale(0.8);
    }
  }

  &.popup-open {
    opacity: 1;
    display: block !important;
  }

  &.popup-close {
    opacity: 0;
    display: none;
    animation: popupClose 0.3s ease forwards;
  }

  .main_popup {
    width: 80%;
    // width: fit-content;
    // height: 653px;
    border-radius: clamp(13px, 1.15vw, 45px);
    background-color: rgba(255, 255, 255, 1);
    // padding: 40px;
    height: 90vh;

    // height: calc(100% - 70px);
    // max-height: calc(100% - 70px);
    @media screen and (min-width: 3440px) and (min-height: 1040px) {
      height: 90vh !important;
    }

    // max-height: 800px;
    // overflow: auto;
    margin-right: 60px;
    // inset: 0;
    left: 18.5%;
    top: 5%;
    padding: clamp(12px, 1.3vw, 43px);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1000px) {
      max-height: 800px;
    }

    &.popup-open {
      opacity: 1;
      // display: block !important;
      animation: popupOpen 0.3s ease forwards;
    }

    &.popup-close {
      opacity: 0;
      display: none;

      animation: popupClose 0.3s ease forwards;
    }

    .contact_popup_header {
      // height: 16%;
      // padding: max(14px, 1.3vw) max(20px, 1.5vw) 0 max(20px, 1.5vw);
      // padding: max(8px, 1%) max(10px, 2%);
      width: 100%;
      // height: 16%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;

      // border: 1px solid black;
      .pop_head {
        width: 100%;
        // border: 1px solid black;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
          // height: 70%;
          // border: 1px solid black;
          line-height: 37px;
          font-weight: 500;
          font-size: clamp(16px, 1.4vw, 45px) !important;
        }
      }

      .main_key_content {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1%;

        .cli_over_headtext {
          font-family: "Poppins", sans-serif;
          font-size: max(14px, 1.3vw, 38px) !important;
          font-weight: 400;
          line-height: 28.8px;
          text-align: left;
          color: var(--Primary-Brand, #1c1c1c);

          > span {
            font-family: "Poppins", sans-serif;
            font-size: max(20px, 1.5vw);
            font-weight: 400;
            line-height: 28.8px;
            text-align: left;
            color: rgba(149, 164, 252, 1);
            margin-left: 10px;
          }
        }

        .close_button_wrapper {
          text-align: end;

          @media screen and (max-width: 2100px) {
            height: 50px !important;
            width: 50px !important;
          }

          @media screen and (max-width: 1700px) {
            height: 35px !important;
            width: 35px !important;
          }

          @media screen and (max-width: 1450px) {
            height: 35px !important;
            width: 35px !important;
          }

          @media screen and (max-width: 1200px) {
            height: 28px !important;
            width: 28px !important;
          }
        }
      }
    }

    .main_content {
      height: calc(100% - 60px);

      .main_table {
        height: calc(100% - 60px);
        padding: 0 10px !important;
        // padding: 0 !important;
        overflow: auto;

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          tbody tr td {
            font-size: 25px;
            padding: 35px 0px;
          }

          thead th div {
            font-size: 25px !important;
            padding: 25px 0px !important;
          }
        }

        tbody tr td {
          font-size: 14px;
        }

        thead th div {
          font-size: 14px !important;
        }

        thead {
          border-bottom: 1px solid #d9d9d9;
        }

        td:nth-of-type(1) {
          width: 20% !important;
        }

        td:nth-of-type(2) {
          width: 35% !important;
        }

        td:nth-of-type(3) {
          width: 28% !important;
        }

        td:nth-of-type(4) {
          width: 10% !important;
        }

        td:nth-of-type(5) {
          width: 12% !important;
        }
      }
    }

    .main_contentMaintenance {
      // border: 1px solid black;
      height: calc(100% - 62px);

      .main_table {
        // height: 660px;
        height: calc(100% - 70px);
        padding: 0px 10px !important;
        // border: 1px solid black;
        overflow-y: auto;

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          tbody tr td {
            font-size: 25px;
            padding: 35px 0px;
          }

          thead th div {
            font-size: 25px !important;
            padding: 25px 0px !important;
          }
        }

        thead {
          border-bottom: 1px solid #d9d9d9;
        }

        td:nth-of-type(1) {
          width: 10% !important;
        }

        td:nth-of-type(2) {
          width: 25% !important;
        }

        td:nth-of-type(3) {
          width: 23% !important;
        }

        td:nth-of-type(4) {
          width: 25% !important;
        }

        td:nth-of-type(5) {
          width: 12% !important;
        }
      }
    }
  }
}
