.maintenace_log {
  width: 100%;
  // position: absolute;
  // margin-left: 324px;
  top: 0;
  // overflow: auto;
  // padding: 35px 34px 30px 35px;
  padding: 20px;
  background-color: #f7f9fb;
  height: calc(100% - 0px);
  overflow: hidden !important;

  .maintenance_content_wrapper {
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    // padding: 20px;

    height: calc(100% - 0px);
    padding: 20px;
    // height: 100%;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 30px;

        @media screen and (max-width: 930px) {
          margin-bottom: 5px;
          height: 35px;
        }

        > h1 {
          //styleName: H1;
          font-family: "Poppins", sans-serif;
          // font-size: 32px;
          font-size: max(20px, 1.4vw);
          font-weight: 400;
          line-height: 28.8px;
          text-align: left;
          color: #000000;
          margin-bottom: 0px;
        }
      }
    }

    .maintenace_log_wrapper {
      height: calc(100% - 70px);
      overflow-x: hidden;

      .button_container {
        margin: 15px;
        text-align: left;

        button:nth-child(1) {
          background: rgba(129, 126, 255, 1);
          color: #fff;
        }

        button {
          padding: 10px 13px;
          border-radius: 7px;
          border: 1px solid rgb(225, 233, 238);
          background-color: #fff;
          text-wrap: nowrap;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          margin-right: 15px;
          font-weight: 400;
          line-height: 17.4px;
          text-align: left;

          span {
            padding: 0px 3px 0px 3px;
            border-radius: 3px;
            // background: rgba(230, 231, 255, 1);
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            font-weight: 500;
            line-height: 13px;
            text-align: center;
            margin-left: 10px;
            color: #000 !important;
          }
        }

        button:nth-child(1) {
          span {
            background: rgba(230, 231, 255, 1);
          }
        }

        button:nth-child(2) {
          span {
            background: rgba(206, 255, 225, 1);
          }
        }

        button:nth-child(3) {
          span {
            background: rgba(255, 233, 181, 1);
          }
        }

        button:nth-child(4) {
          span {
            background: rgba(255, 211, 207, 1);
          }
        }

        button:nth-child(5) {
          span {
            background: rgba(223, 245, 255, 1);
          }
        }

        button:nth-child(6) {
          span {
            background: rgba(255, 206, 243, 1);
          }
        }

        button:nth-child(7) {
          span {
            background: rgba(168, 197, 218, 1);
          }
        }
      }

      .status {
        width: fit-content;
        height: 21px;
        padding: 2px 8px 2px 8px;
        gap: 10px;
        border-radius: 5px;
        background-color: #ffd3cf;
        //styleName: small button;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 17.4px;
        text-align: left;
        color: #2d2d2d;
      }

      .main_content {
        height: calc(100% - 170px);

        .main_table {
          padding: 0 !important;
          height: calc(100% - 10px);
          overflow: auto;

          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            height: calc(100% - 60px);

            tbody tr td {
              font-size: 25px;
              padding: 35px 0px;
            }

            thead th div {
              font-size: 25px !important;
              padding: 25px 0px !important;
            }
          }

          thead {
            border-bottom: 1px solid #d9d9d9;
          }
        }
      }
    }
  }
}

.filterbuttonmaintain {
  // border: 1px solid black;
  // height: 50px;
  max-width: 100%;
  overflow: hidden !important;
}

.maintainfilterDiv {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  margin-bottom: 5px;
  padding-left: 3px;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    // height: calc(50% - 2px);
    // padding-top: 2px;
    &::-webkit-scrollbar {
      height: 2px !important;
    }
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(180, 180, 180, 0.667) !important;
    border-radius: 10px !important;
    //   border: 3px solid #f1f1f1;
    width: 1px;
  }

  &::-webkit-scrollbar-thumb:hover {
    //   background-color: #6062f0;
    width: 2px;
  }

  // background-color: red;
  // border: 1px solid black;
  // height: max(50px, 2.2vw);
  .search_bar_maintain {
    width: 50%;
  }

  .mainSpan {
    // outline: 0.5px solid rgb(0, 0, 0);
    // outline: 1px solid rgba(0, 0, 0, 0.25);
    // padding: 4px 22px;
    // border-radius: 15px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // cursor: pointer;

    padding: max(5px, 0.4vw) max(12px, 1vw);
    border-radius: 7px;
    margin: 3px 0;
    border: 1px solid rgb(225, 233, 238);
    background-color: #fff;
    text-wrap: nowrap;
    font-family: "Poppins", sans-serif;
    font-size: clamp(14px, 0.9vw, 20px);
    margin-right: 6px;
    font-weight: 400;
    line-height: 17.4px;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    @media screen and (max-width: 900px) {
      font-size: 10px;
    }

    &:hover {
      outline: 1px solid rgba(0, 0, 0, 0.45);
    }

    .subSpan {
      padding: 5px;
      border-radius: 6px;
      margin-left: 8px;
      font-size: clamp(14px, 0.9vw, 20px);
      font-weight: 500;
      color: black !important;
      padding: 5px 0;
      width: 40px;

      @media screen and (max-width: 900px) {
        font-size: 10px;
        padding: 2px;
        width: 30px;
      }
    }
  }

  .active {
    background-color: #817eff;
    outline: none !important;
    color: white;
  }
}

// .maintainfilterDiv::-webkit-scrollbar {
//   height: 0.1px !important;
// }
// .maintainfilterDiv::-webkit-scrollbar-track {
//   background-color: transparent !important;
// }
// .maintainfilterDiv::-webkit-scrollbar-thumb {
//   background-color: #5858589c;
// }

// .maintainfilterDiv::-webkit-scrollbar {
//   display: none;
// }
