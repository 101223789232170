.App {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f7f9fb;
}

.sidebar {
  width: 16.5%;
  height: 100vh;
  min-width: 210px;
  position: relative;
  z-index: 100;
  /* overflow-x: hidden; */
}

.outlet {
  width: 83.5% !important;
  /* width: 79.5%; */
  height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
}

@media screen and (min-aspect-ratio: 21/9) {
  .sidebar {
    width: 14.3% !important;
  }
  .outlet {
    width: calc(100% - 14.3%) !important;
  }
}
@media screen and (max-width: 980px) {
  .sidebar {
    width: 65px;
    /* height: 100dvh; */
    min-width: 40px !important;
  }

  .outlet {
    width: calc(100% - 65px) !important;
    /* width: 79.5%; */
    /* height: 100dvh; */
    /* max-height: 100dvh; */
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

a {
  text-decoration: none !important;
  color: white;
}

.password_field:focus {
  border: none;
  outline: none;
}
.password_field::placeholder {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 48.28px;
  text-align: left;
  color: #000000;
}

input::-moz-placeholder {
  font-family: "Poppins", sans-serif;
}

.popup_main_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  background-color: rgba(0, 0, 71, 0.6);
  display: flex;
  justify-content: end;
  align-items: center;
}

p {
  margin: 0;
}

::placeholder {
  font-family: "Poppins", sans-serif;
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  font-family: "Poppins", sans-serif;
}
