@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "rsuite/dist/rsuite.css";
* {
  font-family: "Poppins", sans-serif !important;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.upload_button {
  /* width: 147px;
  height: 49px; */
  border-radius: 7px;
  background-color: #817eff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;

  > span {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.4px;
    text-align: left;
  }
}

.customPasswordButton:focus-visible {
  outline: none;
}

* {
  font-family: "Poppins", sans-serif !important;
}

.rs-calendar-table-cell-selected-end {
  .rs-calendar-table-cell-content {
    background: #817eff !important;
  }
}
.rs-calendar-table-cell-selected-start {
  .rs-calendar-table-cell-content {
    background: #817eff !important;
  }
}
.rs-calendar-table-cell-in-range {
  .rs-calendar-table-cell-content {
    background: #eeeeff !important;
  }
}
.rs-calendar-header-title {
  button {
    color: #817eff !important;
  }
}
.rs-calendar-table-cell-content:hover {
  background: #eeeeff !important;
}
.rs-input-group {
  border: 2px solid;
  border-color: #e1f1fd !important;
  .rs-input-group-inside {
    .rs-input {
      font-weight: 300 !important;
    }
  }
}
.rs-input-group:hover {
  border: 2px solid;
  border-color: #817eff !important;
}
.rs-stack-item {
  .rs-picker-toolbar-right {
    button {
      color: white !important;
      background: #817eff !important;
    }
  }
  button {
    color: #817eff !important;
  }
}
.skelitonDiv {
  padding: 10px;
  background: white;
  border-radius: 13px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  /* width: 97% !important; */
  /* height: 100%; */
}
.skeltonCardDiv {
  width: 24%;
  @media screen and (max-width: 930px) {
    width: 32%;
  }
  .backgroundClass {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* min-height: 180px; */
    height: 180;
    width: 100%;
    border-radius: 15px;
    /* width: 100px; */
    margin: 0 auto;
    margin-top: 16px;
  }
  .ant-skeleton-element {
    width: 100%;
    height: 100%;
    .ant-skeleton-image {
      width: 100%;
      height: 200px;
    }
  }
  /* width: 24% !important; */
  /* height: 100%; */
}

@keyframes loadingDots {
  0% {
    content: "";
  }
  20% {
    content: ".";
    opacity: 0.6;
  }
  40% {
    content: "..";
    opacity: 0.7;
  }
  60% {
    content: "...";
    opacity: 0.8;
  }
  80% {
    content: "....";
    opacity: 1;
  }
  100% {
    content: "";
    opacity: 0.6;
  }
}

.loading-text::after {
  content: "";
  animation: loadingDots 1.5s infinite;
}
.loading-text {
  width: 122px;
}
@media screen and (max-width: 1350px) {
  .loading-text {
    width: 90px;
  }
}

.close_button_wrapper {
  text-align: end;

  .close {
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.3s ease;
    width: 40px;
    height: 40px;

    /* @media screen and (max-width: 2100px) {
      height: 50px !important;
      width: 50px !important;
    } */

    @media screen and (max-width: 1700px) {
      height: 35px !important;
      width: 35px !important;
    }
    /* @media screen and (max-width: 1450px) {
      height: 35px !important;
      width: 35px !important;
    } */

    @media screen and (max-width: 1200px) {
      height: 28px !important;
      width: 28px !important;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.contentHover {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: inherit;
  text-decoration: none; /* Remove default underline */
  text-transform: capitalize;
  font-size: clamp(12px, 1vw, 28px);

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 1px; /* Adjust height as needed */
    background: #817eff;
    bottom: -1px; /* Adjust position as needed */
    left: 50%;
    transition: width 0.5s ease, left 0.5s ease;
  }

  &:hover::after {
    width: 100%;
    left: 0;
  }
  &:hover {
    color: #817eff;
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  position: absolute !important;
}

/* Track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: rgba(
    168,
    197,
    218,
    0.3
  ) !important; /* A lighter shade of the pill color */
}

/* Handle (scrollbar itself) */
::-webkit-scrollbar-thumb {
  background-color: rgba(168, 197, 218, 0.6) !important; /* Pill color */
  border-radius: 10px !important;
}

/* Hover effect for scrollbar */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(
    168,
    197,
    218,
    1
  ); /* Darker shade of the pill color on hover */
}

.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
