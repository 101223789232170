.contactsWrapper {
  width: 100%;
  height: 100%;
  // position: absolute;
  // margin-left: 333px;
  top: 0;
  overflow: auto;
  // padding: 35px 34px 34px 0px;
  padding: clamp(10px, 1vw, 45px);
  background-color: #f7f9fb;
  // height: calc(100% - 0px);
  overflow: hidden;

  .css-dev-only-do-not-override-j9bb5n {
    height: 100%;
  }

  .contacts_main {
    // background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    // margin-left: 20px;
    // border: 1px solid black;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .tab-container {
      margin: 0;
    }

    .tab {
      padding: 0 20px 0 20px;
    }

    .search_wrapper {
      display: flex;
      justify-content: flex-end;
      padding: max(12px, 1vh);
      // border: 1px solid black;

      .totalArrears {
        // border: 1px solid black;
        font-size: clamp(15px, 1.25vw, 33px);
        padding: 5px 10px;
      }

      .searchGridList {
        // border: 1px solid black;
        // width: 40%;
        min-width: 330px;
        width: 38%;

        .searchBarContact {
          width: 100%;
        }
      }

      .views_button_wrapper {
        display: flex;
        flex-wrap: nowrap;

        > button:nth-child(1) {
          width: 39px;
          height: 39px;
          // max-width: 6vh;
          // max-height: 6vh;
          // min-width: 6vh;
          // min-height: 6vh;
          border-radius: 1vh 0px 0px 1vh;
          border: none;
          font-size: 20px;
          background-color: #fff;
          color: #817eff;

          @media screen and (max-width: 1500px) {
            height: 35px;
            width: 35px;
            font-size: 16px;
          }
          @media screen and (max-width: 1500px) {
            height: 30px;
            width: 30px;
            font-size: 13px;
          }

          &:focus {
            outline: none;
          }

          &.active {
            background-color: #817eff;
            color: #fff;
          }
        }

        > button:nth-child(2) {
          width: 39px;
          height: 39px;
          // max-width: 6vh;
          // max-height: 6vh;
          // min-width: 6vh;
          // min-height: 6vh;
          border-radius: 0px 5px 5px 0px;
          background-color: #fff;
          color: #817eff;
          border: none;
          font-size: 20px;
          @media screen and (max-width: 1500px) {
            height: 35px;
            width: 35px;
            font-size: 16px;
          }
          @media screen and (max-width: 1500px) {
            height: 30px;
            width: 30px;
            font-size: 13px;
          }

          &:focus {
            outline: none;
          }

          &.active {
            background-color: #817eff;
            color: #fff;
          }
        }
      }
    }

    .toggle_header {
      width: 100%;
      // height: 69px;
      border-radius: 15px 15px 0px 0px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-bottom: 1px solid #e7e7e7;

      div {
        p {
          font-family: "Poppins", sans-serif;
          font-size: max(14px, 1vw);
          font-weight: 500;
          line-height: 19.2px;
          text-align: center;
          width: 100%;
          padding: 2vh 0px;
          // border: 1px solid black;
          height: 100%;
          cursor: pointer;
          color: #2d2d2d;

          &.active {
            color: #817eff;
            border-bottom: 2px solid #817eff;
          }
        }
      }
    }

    .overview_main {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      padding: 0 0 0px 10px;
      justify-content: flex-start;
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1;

      .table_filter {
        margin-bottom: 27px;
      }

      .main_table {
        width: 100%;
        // padding: 0;
        height: calc(100% - 50px);
        overflow: auto;

        @media screen and (min-width: 768px) and (max-width: 1024px),
          screen and (min-width: 1024px) and (max-width: 1366px) {
          height: auto;
        }

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          height: calc(100% - 181px);

          tbody tr td {
            font-size: 25px;
            padding: 40px 0px;
          }

          thead th div {
            font-size: 25px !important;
            padding: 25x 0px !important;
          }
        }
      }
    }
  }

  .cardcontain {
    width: 100%;
    display: flex;
    // gap: 2%;
    row-gap: max(20px, 1.5vh);
    column-gap: 2%;
    // justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    // align-content: space-between;
  }

  .cardWrapper {
    width: 31.5%;
    // height: 145px;
    background-color: white;
    border-radius: clamp(15px, 1.2vw, 35px);
    padding: max(3vh, 15px) max(10px, 1.2vw);
    transition: background-position 0.2s ease, transform 0.2s ease-in-out;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    // gap: 20px;
    gap: max(1.5%, 7px);

    // border: 1px solid black;
    &:hover {
      transform: scale(1.05);
    }

    @media screen and (max-width: 950px) {
      width: 49% !important;
    }

    .cardHeader {
      display: flex;
      justify-content: center;
      align-items: center;

      // border: 1px solid black;
      .imgDiv {
        // max-width: 92px;
        // max-height: 92px;
        height: 4.5vw;
        width: 4.5vw;
        max-height: 250px;
        max-width: 250px;
        min-width: 60px;
        min-height: 60px;
        background-color: #817eff;
        color: white;
        font-size: max(22px, 1.5vw);
        font-weight: 400;
        border-radius: 50%;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .cardBody {
      width: calc(100% - 6vw);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      // border: 1px solid black;
      .section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        .add_contact_card {
          font-size: clamp(10px, 0.95vw, 26px);
        }

        span {
          font-size: clamp(12px, 1.1vw, 25px);
          text-transform: capitalize;

          @media screen and (max-width: 1100px) {
            font-size: 12px;
            margin-left: 1vw;
          }

          // border: 1px solid black;
        }
      }

      .scrollCustomize {
        &::-webkit-scrollbar {
          width: 0;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          border-radius: 10px;
          //   border: 3px solid #f1f1f1;
          width: 2px;
        }

        &::-webkit-scrollbar-thumb:hover {
          //   background-color: #6062f0;
          width: 2px;
        }

        body {
          scrollbar-width: thin;
          scrollbar-color: #817eff transparent;
          //   width: 2px;
        }
      }
    }
  }
}
