.popup_main_wrapper {
  opacity: 0;

  @keyframes popupOpen {
    0% {
      opacity: 0;
      transform: scale(0.8);
    }

    100% {
      opacity: 1;
      display: block !important;
      transform: scale(1);
    }
  }

  /* Closing Animation */
  @keyframes popupClose {
    0% {
      opacity: 1;
      transform: scale(1);
    }

    100% {
      opacity: 0;
      display: none;
      transform: scale(0.8);
    }
  }

  &.popup-open {
    opacity: 1;
    display: block !important;
  }

  &.popup-close {
    opacity: 0;
    display: none;
    animation: popupClose 0.3s ease forwards;
  }

  .maintenance_content_wrapper {
    width: 80%;
    border-radius: 20px;
    // background-color: rgb(255, 255, 255);
    margin-right: 45px;
    height: 90vh;
    left: 18.5%;
    top: 3%;
    background: #f7f9fb;
    padding: 20px;

    &.popup-open {
      opacity: 1;
      display: block !important;
      animation: popupOpen 0.3s ease forwards;
    }

    &.popup-close {
      opacity: 0;
      display: none;

      animation: popupClose 0.3s ease forwards;
    }

    .aheader {
      background-color: #fff;
      // padding: 20px 20px;
      padding: max(14px, 1.3vw) max(20px, 1.5vw) 0 max(20px, 1.5vw);
      border-radius: 20px 20px 0px 0px;

      .header_content-wrapper {
        img {
          border-radius: 20px;
          object-fit: cover;
          // object-position: right;
          // width: 200px;
        }

        .close_button_wrapper {
          text-align: end;

          .close {
            cursor: pointer;
            width: 40px;
            height: 40px;
          }
        }
      }

      .title_wrapper {
        padding: 20px 0px;

        > h1 {
          font-family: "Poppins", sans-serif;
          font-size: max(16px, 1.8vw) !important;
          font-weight: 400;
          line-height: 28.8px;
          text-align: left;
          margin-bottom: 10px;
        }

        > p {
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          margin-bottom: 10px !important;
        }

        .button_wrapper {
          text-align: left;
          width: fit-content;

          > button {
            width: fit-content;
            height: 29px;
            padding: 6px 21px 6px 21px;
            border-radius: 5px;
            //styleName: small button;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 17.4px;
            text-align: left;
            color: #000;
            margin: 10px 5px;

            border: 1px solid rgba(225, 233, 238, 1);

            &.active {
              background-color: rgba(129, 126, 255, 1);
              color: #fff;
            }
          }
        }

        .table_props {
          display: flex;

          gap: 10px;
          width: fit-content;
        }
      }
    }

    .divider {
      width: 90%;
      height: 1px;
      background-color: rgba(225, 233, 238, 1);
      margin: auto;
    }

    .body_content_wrapper:nth-child(4) {
      border-radius: 0px 0px 20px 20px;
    }

    .popuptable {
      margin-top: -9px;
      background: #f7f9fb;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // flex-direction: column;
      gap: 12px;
      max-height: calc(100% - 176px);
      overflow-y: auto;
      border-radius: 20px;
      flex-wrap: wrap;
      padding: 20px;

      > div {
        flex: 1 1 calc((100% - 24px) / 3);
        max-width: calc((100% - 24px) / 3);

        @media screen and (min-width: 768px) and (max-width: 1024px),
          screen and (min-width: 1024px) and (max-width: 1366px) {
          flex: 1 1 calc((100% - 24px) / 2);
          max-width: calc((100% - 24px) / 2);
        }

        @media screen and (min-width: 1920px) and (min-height: 1080px) {
          flex: 1 1 calc((100% - 24px) / 4);
          max-width: calc((100% - 24px) / 4);
        }
      }

      // .custom-table td {
      //   padding: 30px 0;
      // }
      // .view {
      //   width: 100px;
      //   border-radius: 5px;
      //   padding: 8px 21px 8px 21px;

      //   background-color: rgba(129, 126, 255, 1);
      //   //styleName: small button;
      //   font-family: "Poppins", sans-serif;
      //   font-size: 16px;
      //   font-weight: 400;
      //   line-height: 17.4px;
      //   text-align: center;
      //   border: none;
      //   color: #fff;
      //   margin-right: 40px;
      // }

      // .approve {
      //   width: 140px;
      //   // height: 29px;
      //   padding: 8px 21px 8px 21px;
      //   border-radius: 5px;
      //   font-family: "Poppins", sans-serif;
      //   font-size: 16px;
      //   font-weight: 400;
      //   line-height: 17.4px;
      //   text-align: center;
      //   border: none;
      //   color: #fff;
      //   background-color: rgba(129, 126, 255, 1);
      // }
      // .main_table {
      //   height: 584px;
      //   overflow: auto;
      //   padding: 0px 10px;
      //   thead {
      //     border-bottom: 1px solid rgba(0, 0, 0, 1);
      //   }

      //   table thead tr th {
      //     text-align: center;
      //     > div {
      //       justify-content: center;
      //     }
      //   }
      //   table tbody tr td {
      //     text-align: center;
      //   }
      // }
      .download_card {
        background-color: #fff;
        width: 100%;
        padding: 15px 21px 15px 21px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;

        button {
          top: 319px;
          left: 834px;
          gap: 0px;
          border-radius: 5px;
          opacity: 0px;
          background-color: #817eff;
          color: white;
          padding: 6px 16px;
        }

        .bottomSection {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 5px;

          span {
            font-size: 12px;
            color: #817eff;

            &:hover {
              text-decoration: "underline";
              color: #000;
            }
          }
        }

        .text {
          img {
            width: 22px;
            // height: 22px;
            height: 100%;
          }

          > p {
            //styleName: small button;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 22.4px;
            text-align: left;
            color: rgba(45, 45, 45, 1);
            margin-bottom: 0;
          }
        }
      }

      .cardWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 96%;
        // margin: 0 auto;
        background: white;
        height: 96px;
        padding: 20px;
        border-radius: 10px;

        .three {
          width: 200px;
        }

        &:hover {
          background: rgb(129, 126, 255, 0.03);
        }
      }
    }
  }
}
