.ClientMoneyOverview {
  .graph {
    .echarts-for-react {
      width: 100%;

      @media screen and (max-width: 1430px) {
        width: 315px !important;
      }
    }
  }

  // padding: 62px 65px;
  padding: 20px;
  padding-right: 0;
  padding-bottom: 0;
  height: 100%;

  // border: 1px solid black;
  .cmoMain {
    display: flex;
    width: 100%;
    // border: 1px solid black;
    height: 100%;

    @media screen and (max-width: 1430px) {
      flex-direction: column;
      overflow-y: scroll;
    }

    .arrcardMain {
      width: 28%;

      @media screen and (max-width: 1430px) {
        width: 100%;
      }

      .arrears_card {
        width: 100%;
        height: 97%;
        // padding: 62px 54px 62px 54px;
        padding: 20px;
        padding-right: 0;
        border-radius: 20px;
        background-color: var(--Primary-Light, #f7f9fb);
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        max-height: 1700px;

        > h1 {
          font-family: "Poppins", sans-serif;
          font-size: max(18px, 1.2vw) !important;
          font-weight: 400;
          // line-height: 28.8px;
          text-align: left;
          height: 10%;
          // margin-bottom: 42px;
          display: flex;
          align-items: center;
        }

        .overGraphWrap {
          // border: 1px solid black;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 90%;

          @media screen and (max-width: 1430px) {
            flex-direction: row;
            justify-content: space-around;
          }
        }

        .graph_wrapper {
          justify-content: center;
          display: flex;

          @media screen and (max-width: 1430px) {
            height: 90%;
          }

          // border: 1px solid black;
          // margin-bottom: 51px;
          .graph {
            display: flex;
            align-items: center;
            // height: 100%;
            // width: 100%;
            // position: relative;
            // border: 1px solid black;
          }
        }

        .content_wrapper {
          display: flex;
          justify-content: center;
          height: 50%;

          @media screen and (max-width: 1430px) {
            width: 40%;
            height: 90%;
            // border: 1px solid black;
            align-items: center;
          }

          > ul {
            padding: 0;
            margin: 0;
            width: 95%;
            // margin-left: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .animated:hover {
              transform: scale(1.03);
              box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
            }

            .notAnimated {
              cursor: default;
            }

            .animated {
              cursor: pointer;
            }

            .animated,
            .notAnimated {
              display: flex;
              justify-content: space-between;
              // gap: 20px;
              // margin-bottom: 18px;
              padding: 8px 20px;
              transition: background-position 0.2s ease,
                transform 0.2s ease-in-out;

              span {
                color: #2d2d2d;
                font-family: "Poppins", sans-serif;
                font-size: max(14px, 1.2vw);
                font-style: normal;
                font-weight: 400;
                // line-height: 18px;
                display: flex;
                align-items: center;
                width: 100%;
                text-wrap: nowrap;
                // justify-content: center;

                .badge07 {
                  width: 8px;
                  height: 8px;
                  background: rgba(0, 0, 71, 1);
                  border-radius: 50%;
                  margin-right: 7px;
                }

                .badge814 {
                  width: 6px;
                  height: 6px;
                  background-color: rgba(77, 222, 135, 1);
                  border-radius: 50%;
                  margin-right: 7px;
                }

                .badge1530 {
                  width: 6px;
                  height: 6px;
                  background-color: rgba(79, 195, 244, 1);
                  border-radius: 50%;
                  margin-right: 7px;
                }

                .badge31 {
                  width: 6px;
                  height: 6px;
                  background-color: rgba(129, 126, 255, 1);
                  border-radius: 50%;
                  margin-right: 7px;
                }
              }

              > p {
                color: #2d2d2d;
                font-family: "Poppins", sans-serif;
                font-size: 18px;

                @media screen and (max-width: 1281px) {
                  font-size: 14px;
                }

                font-style: normal;
                font-weight: 400;
                // line-height: 18px;
                text-align: left;
                margin-bottom: 0;
                width: 100%;
                text-align: right;
              }
            }
          }
        }
      }
    }

    .arrdataMain {
      width: 70%;

      @media screen and (max-width: 1430px) {
        width: 100%;
      }
    }
  }

  .overview_left {
    padding: 10px 34px 22px 54px;

    // border: 1px solid black;
    // width: 100%;
    @media screen and (max-width: 1430px) {
      padding-left: 5px;
    }

    @media screen and (max-width: 820px) {
      padding-right: 10px;
    }

    > h1 {
      //styleName: H1;
      font-family: "Poppins", sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 28.8px;
      text-align: left;
      padding-bottom: 8px;
    }

    .card_parent {
      // margin-top: 20px;
      width: 100%;
      display: flex;
      gap: 2%;

      @media screen and (max-width: 800px) {
        flex-direction: column;
        gap: 20px;
      }

      .cardWrap {
        // display: flex;
        // border: 1px solid black;
        width: 49%;
        height: 15vh;

        @media screen and (max-width: 800px) {
          width: 100%;
        }
      }

      .card {
        cursor: pointer;
        width: 100%;
        height: 100%;
        // max-height: 100px;
        background: #f7f9fb;
        border-radius: 10px;
        border: none;
        padding: 20px;
        justify-content: center;
        gap: 2vh;
        transition: background-position 0.2s ease, transform 0.2s ease-in-out,
          box-shadow 0.2s ease-in-out;

        &:hover {
          transform: scale(1.02);
          box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
        }

        p {
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
        }

        .amount {
          display: flex;
          justify-content: space-between;
          align-items: end;

          h1 {
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-weight: 300;
            line-height: 18px;
            text-align: left;
            margin: 0;
          }

          span {
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 13.13px;
            text-align: right;
          }
        }
      }
    }

    .overviewDays {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin-top: 20px;

      @media screen and (max-width: 800px) {
        flex-direction: column-reverse;
        // align-items: center;
      }
    }

    .date {
      // margin-top: -40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;

      @media screen and (max-width: 800px) {
        justify-content: center;
        margin-bottom: 15px;
        align-items: flex-start;
      }

      span {
        width: 4.3rem;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        // gap: 10px;
        border-radius: 5px;
        border: 1px solid rgba(225, 233, 238, 1);
        // opacity: 0px;
        font-size: 12px;
        cursor: pointer;
        font-family: "Poppins", sans-serif;

        @media screen and (max-width: 800px) {
          width: 6rem;
        }
      }

      .active {
        background-color: rgb(0, 0, 0);
        color: white;
        border: none;
      }
    }

    .toggle_button {
      margin-top: 20px;
      text-align: end;
      // border: 1px solid black;
      justify-content: end;
      display: flex;

      @media screen and (max-width: 800px) {
        justify-content: center;
        margin: 15px 0;
      }

      button:nth-child(1) {
        // width:82px;
        // height: 29px;
        // padding: 6px 21px ;
        width: 4.8rem;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #817eff;
        color: #817eff;
        background-color: #fff;
        font-family: "Poppins", sans-serif;

        &.active {
          background-color: #817eff;
          color: #fff;
        }
      }

      button:nth-child(2) {
        // width:82px;
        // height: 29px;
        // padding: 6px 21px;
        width: 10rem;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px 5px 5px 0px;
        border: 1px solid #817eff;
        color: #817eff;
        background-color: #fff;
        font-family: "Poppins", sans-serif;

        &.active {
          background-color: #817eff;
          color: #fff;
        }
      }
    }

    .chart {
      position: relative;
      width: 100%;
      height: 70%;

      @media screen and (min-width: 768px) and (max-width: 1100px) {
        height: 100%;
        max-height: 228px;
        min-height: 228px;
      }

      @media screen and (min-width: 1100px) and (max-width: 1366px) {
        height: 100%;
        max-height: 428px;
        min-height: 300px;
      }
    }
  }
}
