.payents_main_wrapper {
  background-color: #fff;
  padding: 0 4.5%;
  border-radius: clamp(15px, 1.3vw, 50px);
  height: 100%;

  .listdata {
    height: 100%;
    img {
      min-height: 20px;
      min-width: 20px;
      height: 1.5vw;
      width: 1.5vw;
    }
    > ul {
      padding: 0;
      margin: 0;
      height: 93%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .animated:hover {
        transform: scale(1.03);
        box-shadow: 0px 1px 10px 0px rgba(129, 126, 255, 0.3);
      }
      .notAnimated {
        cursor: default;
      }
      .animated {
        cursor: pointer;
      }

      .animated,
      .notAnimated {
        display: flex;
        justify-content: space-between;
        padding: max(16px, 0.3vw) max(6px, 0.45vw);
        border-bottom: 0.5px dotted #d2d6e0;
        cursor: pointer;
        transition: background-position 0.2s ease, transform 0.2s ease-in-out;

        > div {
          display: flex;
          width: 70%;
          align-items: center;
          gap: 10px;

          > h2 {
            color: #000;
            font-family: "Poppins", sans-serif;
            font-size: clamp(10px, 1vw, 27px);
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 0;
            @media screen and (max-width: 840px) {
              font-size: 11px;
            }
          }
        }

        > p {
          color: #2d2d2d;
          text-align: right;
          font-family: "Poppins", sans-serif;
          font-size: clamp(16px, 1.25vw, 40px);
          font-style: normal;
          font-weight: 300;
          margin-bottom: 0;
          text-align: left;
        }
      }
    }
  }
}
