.overview {
  //    width: calc(100% - 300px);
  //    position: absolute;
  //    // height: calc(100% - 0px);
  //    margin-left: 300px;
  //    top: 0;
  //    overflow: auto;
  //    padding: 25px 34px 25px 34px;
  //    background-color: #F7F9FB;
  // height: 100%;
  width: 100%;
  // position: absolute;
  height: 100%;
  // margin-left: 333px;
  // top: 0;
  // overflow: auto;
  // padding: 34px 64.33px 0px 38px;\
  padding: clamp(20px, 1.7vw, 50px);
  background-color: #f7f9fb;

  //overflow: hidden;
  .header {
    > h1 {
      //styleName: H1;
      font-family: "Poppins", sans-serif;
      font-size: clamp(22px, 1.6vw, 45px);
      font-weight: 400;
      line-height: 28.8px;
      text-align: left;
      color: #000000;
      margin-bottom: 2vh;
    }
  }

  .content_wrapper {
    // height: calc(100% - 63px);
    // height: calc(100% - -25px);
    display: flex;
    flex-direction: column;
    gap: 20px;

    // border: 1px solid black;
    .one {
      // background-color: #fff;
      border-radius: 1.4rem;
      height: 30vh;
      // max-height: 380px;
      display: flex;
      justify-content: space-between;

      // max-height: 300px;
      // border: 1px solid black;
      @media screen and (max-width: 900px) {
        height: auto;
        gap: 20px;
        // max-height: 50rem;
        flex-direction: column;
        column-gap: 2vh;
        // max-height: 500px;
        // border: 1px solid black;
      }

      .Occupancy_left {
        width: 35%;
        padding: 2% max(23px, 1.8vw);
        border-radius: 1.4rem 0px 0px 1.4rem;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        @media screen and (max-width: 900px) {
          max-height: 200px;
          height: 160px;
          width: 100%;
          border-radius: 1.4rem;
          // max-height: 60rem;
        }

        > h2 {
          //styleName: H2;
          font-family: "Poppins", sans-serif;
          font-size: clamp(13px, 1.3vw, 38px);
          font-weight: 500;
          line-height: 19.2px;
          text-align: left;
          height: 20%;
        }

        .total_count_wrapper {
          width: 100%;
          height: 80%;
          // height: 150px;
          // padding: 36px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          padding-left: max(2vw, 20px);
          background-color: #f7f9fb;
          border-radius: 10px;
          gap: 10%;
          // gap: 10px;

          > h2 {
            font-family: "Poppins", sans-serif;
            font-size: clamp(25px, 2.1vw, 80px);
            // font-size: 3vw;
            font-weight: 300;
            // line-height: 38px;
            text-align: left;
            // margin-bottom: 3%;
          }

          > p {
            font-family: "Poppins", sans-serif;
            font-size: clamp(12px, 1vw, 28px);
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            margin: 0;
          }
        }
      }

      .Occupancy_right {
        padding: 2% 1rem;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        width: 64%;
        background-color: #fff;
        border-radius: 0 1.4rem 1.4rem 0;

        // border: 1px solid black;
        @media screen and (max-width: 900px) {
          height: auto;
          max-height: 280px;
          width: 100%;
          border-radius: 1.4rem;
          // max-height: 60rem;
        }

        .overPie {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // border: 1px solid black;
        }

        .loader_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 25px;

          .CircularProgressbar {
            width: 50%;
            height: 50%;
            //   width: 36%;
          }

          > p {
            font-family: "Poppins", sans-serif;
            // font-size: 16px;
            font-weight: 400;
            // line-height: 20px;
            text-align: center;
            font-size: clamp(10px, 0.9vw, 24px);
          }
        }
      }
    }

    .two {
      // background-color: #fff;
      //   margin-top: 20px;
      border-radius: clamp(20px, 1.7vw, 50px);
      display: flex;
      width: 100%;
      height: 36vh;
      gap: 1%;

      // @media screen and (max-width: 1900px) {
      //   height: 40vh;
      // }

      // max-height: 350px;
      // max-height: 380px;
      @media screen and (max-width: 900px) {
        height: auto !important;
        flex-direction: column;
        // column-gap: min(2vh, 20px);
        // column-gap: 2rem;
        // max-height: 550px;
        // border: 1px solid black;
      }

      .Property_left {
        padding: clamp(20px, 1.7vw, 50px);
        border-radius: clamp(20px, 1.7vw, 50px) 0px 0px clamp(20px, 1.7vw, 50px);
        background-color: #fff;
        // border-right: 7px solid #f7f9fb;
        // min-height: 327px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        // width: 502px;
        width: 35%;

        @media screen and (max-width: 900px) {
          // height: 47vh;
          width: 100%;
          border-radius: 1.4rem;
          max-height: 240px;
        }

        > h2 {
          //styleName: H2;
          font-family: "Poppins", sans-serif;
          font-size: clamp(13px, 1.3vw, 38px);
          font-weight: 500;
          line-height: 19.2px;
          text-align: left;
          margin-bottom: 0;
        }

        .left_inner_wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 50px;
          width: 100%;
          gap: 2%;
          height: 80%;

          // border: 1px solid black;
          @media screen and (max-width: 1400px) {
            margin-top: 35px;
          }
          @media screen and (max-width: 900px) {
            margin-top: 7px;
            height: 70%;
            margin-top: 20px;
          }

          .graph_wrapper {
            width: 48%;
            margin-bottom: 6%;

            @media screen and (max-width: 1500px) {
              margin-bottom: 15% !important;
            }

            @media screen and (max-width: 900px) {
              margin-bottom: 1% !important;
            }
          }

          > ul {
            width: 50%;
            padding: 0;

            // margin: 25px 0px 0px 0px;
            @media screen and (max-width: 900px) {
              // margin-top: 7px;
            }

            > li {
              display: flex;
              justify-content: space-between;
              // gap: 48px;
              margin-bottom: clamp(20px, 2.2vw, 80px);

              span {
                color: #2d2d2d;
                font-family: "Poppins", sans-serif;
                font-size: clamp(14px, 1.1vw, 33px);
                font-style: normal;
                line-height: 18px;
                display: flex;
                align-items: center;

                @media screen and (max-width: 1000px) {
                  font-size: 12px !important;
                }

                @media screen and (max-width: 900px) {
                  font-size: 16px !important;
                }

                .badge1 {
                  width: 8px;
                  height: 8px;
                  background-color: #ff3a29;
                  border-radius: 50%;
                  margin-right: 7px;
                }

                .badge2 {
                  width: 8px;
                  height: 8px;
                  background-color: #ffb200;
                  border-radius: 50%;
                  margin-right: 7px;
                }

                .badge3 {
                  width: 8px;
                  height: 8px;
                  background: #4dde87;
                  border-radius: 50%;
                  margin-right: 7px;
                }
              }

              > p {
                color: #2d2d2d;
                font-family: "Poppins", sans-serif;
                font-size: clamp(16px, 1.2vw, 38px);
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                margin-bottom: 0;

                @media screen and (max-width: 1000px) {
                  font-size: 12px !important;
                }

                @media screen and (max-width: 900px) {
                  font-size: 16px !important;
                }
              }
            }
          }
        }
      }

      .Property_right {
        padding: clamp(20px, 1.7vw, 50px) 15px;
        width: 64%;
        background-color: #fff;
        border-radius: 0 1.4rem 1.4rem 0;
        overflow: hidden;

        @media screen and (max-width: 900px) {
          height: 45vh;
          width: 100%;
          border-radius: 1.4rem;
          margin-top: min(2svh, 20px);
          max-height: 300px;
        }

        > h2 {
          font-family: "Poppins", sans-serif;
          font-size: clamp(13px, 1.3vw, 38px);
          font-weight: 500;
          line-height: 19.2px;
          text-align: left;
          margin-bottom: 0;
        }

        .propRightTabs {
          // width: 100%;
          // border: 1px solid black;
          overflow-x: auto;
          overflow-y: hidden;
          width: 100%;
          height: 90%;

          // border: 1px solid black;
          .card_main {
            padding: 0 !important;
            display: flex;
            gap: 27px;
            // width: 200px;
            height: 100%;
            // border: 1px solid black;

            .card_inner {
              background-color: #f7f9fb;
              border-radius: 10px;
              // width: 162px;
              height: 100%;
              width: max(200px, 13vw);
              // width: 13vw;
              padding: 20px 22px 19px 20px;
              display: flex;
              flex-direction: column;
              justify-content: start;
              gap: 10px;

              .icon_wrapper {
                width: 56px;
                height: 67px;
                padding: 6px;
                border-radius: 30px;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;
                }
              }

              > div {
                > h4 {
                  font-family: "Poppins", sans-serif;
                  font-size: max(15px, 1.2vw);
                  font-weight: 400;
                  line-height: 28px;
                  text-align: left;
                }

                > p {
                  font-family: "Poppins", sans-serif;
                  font-size: max(12px, 0.8vw);
                  font-weight: 400;
                  line-height: 17.4px;
                  text-align: left;
                  margin-bottom: 10px;
                  color: #6a6a79;
                }
              }
            }
          }
        }
      }
    }

    .three {
      // background-color: #fff;
      // //   margin-top: 20px;
      // border-radius: 20px;
      // padding: 6px;
      // height: 290px;
      // height: calc(100% - 542px);
      // width: 60%;

      > div {
        background-color: #fff;
        //   margin-top: 20px;
        border-radius: clamp(20px, 1.7vw, 50px);
        cursor: none;
      }
    }
  }
}

.animated:hover {
  transform: scale(1.1);
}

.animated {
  cursor: pointer;
}

.notAnimated {
  cursor: default !important;
}

.firstProgress {
  transition: all 0.2s ease-in-out;

  .CircularProgressbar {
    .CircularProgressbar-trail {
      stroke: rgb(219, 248, 231) !important;
    }
  }
}

.secondProgress {
  transition: 0.2s ease-in-out;

  .CircularProgressbar {
    .CircularProgressbar-trail {
      stroke: rgb(255, 240, 204) !important;
    }
  }
}

.thirdProgress {
  transition: background-position 0.2s ease, transform 0.2s ease-in-out;

  .CircularProgressbar {
    .CircularProgressbar-trail {
      stroke: rgb(255, 216, 212) !important;
    }
  }
}

.margBottom {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 900px) {
  .one {
    height: 60vh;
    flex-direction: column;
    column-gap: 2vh;

    .Occupancy_left {
      height: 200px;
      width: 100%;
      border-radius: 1.4rem;
    }

    .Occupancy_right {
      height: auto;
      width: 100%;
      border-radius: 1.4rem;
    }
  }
}
