.Inspection {
  // width: calc(100% - 300px);

  // position: absolute;
  // margin-left: 300px;
  width: 100%;
  top: 0;
  overflow: auto;
  padding: 27px 28px;
  background-color: #f7f9fb;
  height: calc(100% - 0px);
  overflow: hidden;
  @media screen and (max-width: 1700px) {
    padding: 24px 25px;
  }
  @media screen and (max-width: 1500px) {
    padding: 20px 21px;
  }
  @media screen and (max-width: 1300px) {
    padding: 17px 18px;
  }
  @media screen and (max-width: 1200px) {
    padding: 14px 15px;
  }
  @media screen and (max-width: 1050px) {
    padding: 11px 12px;
  }
  @media screen and (max-width: 900px) {
    padding: 8px 9px;
  }
  .Inspection_main {
    background-color: #fff;
    border-radius: 20px;
    height: 100%;
    // border: 1px solid black;
    // margin-left: 20px;
    .Inspection-header {
      padding: 15px 20px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      // border: 1px solid black;
      @media screen and (max-width: 1055px) {
        padding: 7px 15px;
      }
      > h1 {
        font-family: "Poppins", sans-serif;
        font-size: max(22px, 1.7vw);
        font-weight: 400;
        line-height: 28.8px;
        text-align: left;
        width: 35%;
        margin-bottom: 0;
      }
      .progress_wrapper {
        margin-top: 10px;
        width: 65%;
        gap: 15px;

        .progress_main {
          margin-top: 15px;
          // width: 356px;
          margin-bottom: 21px;

          .main_text {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .text {
              font-family: "Poppins", sans-serif;
              font-size: 18.6px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            }

            > span:nth-child(2) {
              text-align: right;
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }
          }
        }
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: #f2f2f2;
      margin: auto;
    }
    .main_tablecontent {
      height: calc(100% - 180px);
      .table_filter {
        padding: 43px 32px 0px 14px;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        .button_container {
          margin: 3px 10px;
          text-align: left;
          width: fit-content;
          display: flex;
          .active {
            background: rgba(129, 126, 255, 1);
            color: #fff;
          }
          // @media screen and () {
          // }

          button {
            padding: 10px 13px;
            border-radius: 7px;
            border: 1px solid rgb(225, 233, 238);
            text-wrap: nowrap;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            margin-right: 15px;
            font-weight: 400;
            line-height: 17.4px;
            text-align: left;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              padding: 2px 5px;
              border-radius: 3px;
              // background: rgba(230, 231, 255, 1);
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 13px;
              text-align: center;
              margin-left: 5px;
              color: #000 !important;
              width: 40px;
              padding: 5px 0;
            }
          }

          button:nth-child(1) {
            span {
              // font-size: 16px !important;
              background: rgba(230, 231, 255, 1);
            }
          }
          button:nth-child(2) {
            span {
              // font-size: 16px !important;
              background: rgba(206, 255, 225, 1);
            }
          }
          button:nth-child(3) {
            span {
              // font-size: 16px !important;
              background: rgba(255, 233, 181, 1);
            }
          }
          button:nth-child(4) {
            span {
              // font-size: 16px !important;
              background: rgba(255, 211, 207, 1);
            }
          }
        }
      }

      .table_compliance {
        // overflow: auto;
        height: calc(100% - 20px);
        .main_table {
          height: calc(100% - 62px);
          @media screen and (min-height: 1040px) {
            height: auto !important;
          }

          padding: 0 0px !important;
          overflow: auto;
          @media screen and (min-width: 768px) and (max-width: 1024px),
            screen and (min-width: 1024px) and (max-width: 1366px) {
            height: calc(100% - 80px);
          }

          // border: 1px solid black;
          overflow-x: auto;
          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            tbody tr td {
              font-size: 25px;
              padding: 35px 0px;
            }
            thead th div {
              font-size: 25px !important;
              padding: 25px 0px !important;
            }
          }
          thead {
            border-bottom: 1px solid #d9d9d9;
          }

          .status_button {
            width: fit-content;
            height: 21px;
            padding: 2px 8px 2px 8px;
            border-radius: 5px;
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 17.4px;
            text-align: center;
            color: #2d2d2d;
          }
        }
      }
    }
  }
}

@media (max-width: "1650px") {
  .searchbox {
    width: 350px !important;
  }
  // .Inspection-header {
  //   h1 {
  //     font-size: 30px !important;
  //   }
  // }
}
@media (max-width: "1450px") {
  .searchbox {
    width: 250px !important;
  }
  .generate-btn {
    width: 100px !important;
  }
  .table_filter {
    padding: 10px 40px 0px 3px !important;
  }
  .Inspection-header {
    padding: 1px 28px !important ;
    h1 {
      font-size: 26px !important;
    }
  }
  .text {
    // font-size: 14px !important;
  }
  .main_text {
    span {
      // font-size: 14px !important;
    }
  }
  // .progress-sm {
  //   height: 7px !important;
  // }
  .button_container {
    button {
      height: 35px !important;
      font-size: 12px !important;
      span {
        font-size: 12px !important;
        width: 25px !important;
        height: 20px !important;
      }
    }
  }
}
@media (max-width: "1160px") {
  .searchbox {
    width: 150px !important;
  }
  .generate-btn {
    width: 70px !important;
  }
  .table_filter {
    padding: 10px 10px 0px 3px !important;
  }
  .Inspection-header {
    padding: 1px 28px !important ;
    h1 {
      font-size: 22px !important;
    }
  }
  .text {
    // font-size: 14px !important;
  }
  .main_text {
    span {
      // font-size: 14px !important;
    }
  }
  // .progress-sm {
  //   height: 7px !important;
  // }
  .button_container {
    button {
      height: 30px;
      font-size: 10px !important;
      span {
        font-size: 10px !important;
        width: 17px !important;
        height: 23px !important;
      }
    }
  }
}

@media (max-width: "1058px") {
  .searchbox {
    width: 120px !important;
  }
}
@media (max-width: "1055px") {
  .table_filter {
    flex-direction: column;
    gap: 20px;
    // justify-content:flex-start;
    align-items: flex-start;
  }
}
@media (max-width: "1000px") {
  .searchbox {
    width: 120px !important;
  }
  .Inspection-header {
    padding: 1px 28px !important ;
    h1 {
      font-size: 20px !important;
    }
  }
  .main_text span {
    font-size: 12px !important;
    margin-bottom: 5px !important;
  }
  // .progress-sm {
  //   height: 5px !important;
  // }
  .button_container button {
    height: 25px !important;
    // font-size: 10px !important;
    padding: 2px 6px;
  }
  .button_container {
    margin: 10px !important;
    button span {
      height: 16px !important;
      line-height: 7px !important;
    }
  }
  .generate_report {
    padding: 5px 7px !important;
  }
}
@media (max-width: "920px") {
  .Inspection .Inspection_main .main_tablecontent .table_filter {
  }
  .Inspection-header {
    padding: 1px 26px !important ;
    h1 {
      font-size: 18px !important;
    }
  }
  // .right-search-btn {
  //   flex-wrap: wrap !important;
  // }
}
