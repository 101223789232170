// .Deposit {
//   height: calc(100% - 5px);
//   .css-dev-only-do-not-override-j9bb5n {
//     height: 100%;
//   }
//   .card_parent {
//     display: flex;
//     gap: 33px;
//     flex-wrap: wrap;
//     .card {
//       cursor: pointer;
//       width: 246px;
//       // height: 86px;
//       padding: 16px 20px 20px 20px;
//       border-radius: 10px;
//       border: none;
//       justify-content: space-between;
//       background-color: #eeeeff;
//       color: #2d2d2d;
//       transition: background-position 0.2s ease, transform 0.2s ease-in-out;

//       &:hover {
//         transform: scale(1.05);
//       }

//       &.active {
//         background-color: #817eff;
//         color: #fff;
//       }
//       p {
//         font-family: "Poppins", sans-serif;
//         font-size: 13px;
//         font-weight: 500;
//         line-height: 17.4px;
//         text-align: left;
//         // color: var(--Primary-Light, #F7F9FB);
//       }
//       h1 {
//         font-family: "Poppins", sans-serif;
//         font-size: 24px;
//         font-weight: 300;
//         line-height: 18px;
//         text-align: left;
//         // color: var(--Primary-Light, #F7F9FB);
//         margin: 25px 0px 0px 0px;
//       }
//     }
//   }
//   h1 {
//     font-family: "Poppins", sans-serif;

//     font-size: 26px;
//     font-weight: 500;
//     line-height: 28.8px;
//     text-align: left;
//     margin-top: 25px;
//   }
//   .table_headers {
//     display: flex;
//     justify-content: space-between;
//     margin-top: 30px;
//     > div {
//       display: flex;
//       gap: 24px;
//       justify-content: space-between;
//     }
//   }
//   .main_content {
//     margin-top: 12px;
//     height: calc(100% - 100px);

//     .main_table {
//       height: calc(100% - 200px);

//       overflow: auto;
//       padding: 0 !important;
//       thead {
//         border-bottom: 1px solid rgba(0, 0, 0, 1);
//       }
//     }
//   }
// }

.Deposit {
  height: calc(100% - 5px);

  .depoHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    h1 {
      text-wrap: nowrap !important;
      font-size: max(20px, 1.4vw);
    }

    .searchDeposit {
      width: 200px;

      @media screen and (max-width: 900px) {
        // width: 65%;
      }
    }
  }

  .css-dev-only-do-not-override-j9bb5n {
    height: 100%;
  }

  h1 {
    font-family: "Poppins", sans-serif;

    font-size: 26px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
  }

  .table_headers {
    display: flex;
    justify-content: space-between;
    margin-top: 9px;

    @media screen and (max-width: 1250px) {
      flex-direction: column;
      // column-gap: 10px;
    }

    .card_parent {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;

      // border: .1px solid black;
      .card {
        // padding: 12px 20px;
        border-radius: 10px;
        background-color: rgba(129, 126, 255, 0.1);
        border: none;
        justify-content: space-between;
        flex-direction: row;
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 0 12px;
        gap: 10px;
        height: 7vh;
        max-height: 60px;
        cursor: pointer;

        // border: 1px solid black;
        @media screen and (max-width: 1350px) {
          // justify-content: left;
          padding: 0 8px;
          gap: 6px;
          // column-gap: 10px;
        }

        &.inactive:hover {
          transition: all 0.3s ease;
          transform: scale(1.05);
        }

        p {
          font-family: "Poppins", sans-serif;
          font-size: max(12px, 0.8vw);
          font-weight: 500;
          // line-height: 23.4px;
          text-wrap: nowrap;
          text-align: left;
          color: var(--Primary-Light, #f7f9fb);
          // width: 140px;
          color: black;
        }

        h1 {
          font-family: "Poppins", sans-serif;
          font-size: max(14px, 0.9vw);
          font-weight: 300;
          line-height: 23px;
          text-align: left;
          color: var(--Primary-Light, #f7f9fb);
          margin: 0px 0px 0px 0px;
          color: black;
        }

        &.active {
          background-color: #817eff;
          color: #fff;

          p {
            color: #fff;
          }

          h1 {
            color: #fff;
          }
        }
      }
    }

    > div {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      width: fit-content;

      @media screen and (max-width: 1250px) {
        margin-top: 15px;
      }
    }
  }

  .main_content {
    margin-top: 12px;
    height: calc(100% - 0px);

    .main_table {
      height: calc(100% - 145px);
      overflow: auto;
      padding: 0 !important;

      @media screen and (min-width: 1920px) and (min-height: 1080px) {
        height: calc(100% - 255px);

        tbody tr td {
          font-size: 25px;
          padding: 35px 0px;
        }

        thead th div {
          font-size: 25px !important;
          padding: 25px 0px !important;
        }
      }

      thead {
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
}
