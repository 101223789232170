// .fullPageDropzone {
//   position: relative;
//   min-height: 100vh;
//   width: 100%;
// }

// .contentContainer {
//   position: relative;
//   z-index: 1;
// }

// .dropOverlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background: rgba(129, 126, 255, 0.1);
//   backdrop-filter: blur(4px);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 100;
//   pointer-events: none;
// }

// .dropMessage {
//   background: white;
//   padding: 2rem;
//   border-radius: 1rem;
//   box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
//   text-align: center;

//   img {
//     width: 64px;
//     height: 64px;
//     margin-bottom: 1rem;
//   }

//   p {
//     font-size: 1.25rem;
//     color: #817eff;
//     margin: 0;
//   }
// }

// .dragging {
//   &::after {
//     content: "";
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     border: 3px dashed #817eff;
//     pointer-events: none;
//     z-index: 99;
//   }
// }

.fullPageDropzone {
  position: relative;
  height: 87vh;
  width: 100%;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.disabled {
    // cursor: not-allowed;

    .dropOverlay,
    .dropMessage,
    :global(.wfp--dropzone) {
      pointer-events: none;
    }
  }
}

.contentContainer {
  position: relative;
  z-index: 1;
  transition: transform 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .dragging & {
    transform: scale(0.98);
  }

  :global(.card-contents) {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  :global(.card-body-content) {
    flex: 1;
    min-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  :global(.property-content) {
    flex: 1;
    min-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  :global(.file-body) {
    flex: 1;
    min-height: 0;
    overflow-y: auto;
    padding: 10px;
    scrollbar-width: thin;
    scrollbar-color: #817eff rgba(129, 126, 255, 0.1);

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(129, 126, 255, 0.1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #817eff;
      border-radius: 4px;
    }
  }
}

.dropOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(129, 126, 255, 0.15);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transform: scale(1.1);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  .dragging & {
    opacity: 1;
    transform: scale(1);
  }
}

.dropMessage {
  background: rgba(255, 255, 255, 0.95);
  padding: 2.5rem;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px rgba(129, 126, 255, 0.1),
    0 1px 8px rgba(129, 126, 255, 0.2);
  text-align: center;
  transform: translateY(20px);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid rgba(129, 126, 255, 0.1);

  .dragging & {
    transform: translateY(0);
  }

  img {
    width: 80px;
    height: 80px;
    margin-bottom: 1.5rem;
    animation: float 3s ease-in-out infinite;
    filter: drop-shadow(0 4px 12px rgba(129, 126, 255, 0.2));
  }

  p {
    font-size: 1.5rem;
    color: #817eff;
    margin: 0;
    font-weight: 500;
    text-shadow: 0 1px 2px rgba(129, 126, 255, 0.1);
  }
}

.dragging {
  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 3px dashed #817eff;
    pointer-events: none;
    z-index: 99;
    animation: borderDash 30s linear infinite;
    background: radial-gradient(
      circle at center,
      rgba(129, 126, 255, 0.05) 0%,
      transparent 70%
    );
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes borderDash {
  from {
    background-position: 0 0;
    border-spacing: 20px;
  }
  to {
    background-position: 100% 100%;
    border-spacing: 0;
  }
}

// Modern glass effect for dropzone
.dragging .contentContainer {
  &::before {
    content: "";
    position: absolute;
    inset: -10px;
    background: linear-gradient(
      120deg,
      rgba(129, 126, 255, 0.05),
      rgba(255, 255, 255, 0.1)
    );
    border-radius: 1.5rem;
    z-index: -1;
    opacity: 0;
    animation: glowPulse 2s ease-in-out infinite;
  }
}

@keyframes glowPulse {
  0%,
  100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.02);
  }
}

.cardLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dragFile {
  margin-top: 70px;
  width: 30%;
  height: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
