.side-black-wrapper {
  height: 100vh;
  // width: 100%;
  // transition: 0s !important;
  // transition: 0.2s;
  overflow-y: hidden;
  @media screen and (max-width: 850px) {
  }
}
.sidebar_main_wrapper {
  width: 100%;
  background-color: #000047;
  transition: width 0.5s !important;
  // border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  @media screen and (max-width: 850px) {
  }

  &.active {
    height: 100%;
    background-color: #000047;
    transition: width 0.5s !important;
  }

  .sidebar-top {
    height: 88%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .sidebar_header {
    width: 100%;
    height: 9vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .title_logo {
      width: 100%;
      height: 100%;
      min-width: 124px;
      min-height: 32px;
      background-repeat: no-repeat;
      background-position: 100% 100%;
      background-size: 100% 100%;
      transition: none !important;
      // border: 1px solid white;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .pageLogo {
        // border: 1px solid white;
        width: 75%;
        height: 75%;
        display: flex;
        align-items: center;
        justify-content: left;
        // border: 1px solid black;
        // padding: 2.5% 0;
        @media screen and (max-width: 980px) {
          justify-content: center !important;
          width: 86%;
          height: 86%;
        }
        img {
          margin-top: 2%;
          width: 60%;
          height: 78%;
          object-fit: contain;
          // border: 1px solid white;
          // max-width: 100px;
          // aspect-ratio: 9/3;
          @media screen and (max-width: 980px) {
            width: 70%;
            height: 70%;
            margin-top: 5px;
          }
        }
      }
    }

    .list_img {
      background-color: transparent;
      padding: 3px 5px;
      position: absolute;
      z-index: 10;
      top: 30%;
      // border: 1px solid white;
      border-radius: 5px;
      cursor: pointer;

      .open {
        transform: rotate(-180deg);
        transition: all 0.5s !important;
        // border: 1px solid white;
      }

      .close {
        transform: rotate(0deg);
        transition: all 0.5s !important;
      }
    }
  }

  .sidebarIcons {
    height: clamp(20px, 1.6vw, 40px);
    width: clamp(20px, 1.6vw, 40px);
    // min-width: 22px;
    // min-height: 22px;
  }

  .side_navdata_wrapper {
    // margin-top: 70px;
    // margin-top: 25px;
    // border: 1px solid white;
    transition: 0.2s;
    overflow-y: scroll;
    // height: 300px;
    height: calc(100% - 30px);
    &::-webkit-scrollbar {
      // background-color: red;
      width: 2px !important;
    }
    &::-webkit-scrollbar-track {
      background-color: #000047 !important;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #000047 !important;
    }

    .navLink {
      font-family: "Poppins", sans-serif;
      display: block;
      width: 100%;
      // height: max(7vh, 80px);
      // border: 1px solid white;
      //   max-height: 60px;
      min-height: 45px;
      padding-top: 1.3vh;
      padding-right: clamp(12px, 0.9vw, 25px);
      padding-bottom: 1.3vh;
      margin-top: clamp(10px, 2vh, 24px);

      // position: relative;
      // border: 1px solid white;
      //   @media screen and (max-height: ) {

      //   }

      .innerLeft {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .menu {
          > span {
            font-family: "Poppins", sans-serif;
            font-size: clamp(12px, 1.05vw, 25px);
            font-weight: 400;
            line-height: 16.8px;
            text-align: left;
            transition: 0.1s;
            text-wrap: nowrap;
            // border: 1px solid white;
            &:hover {
              color: white;
            }
            // @media screen and (max-width: 1010px) {
            //   font-size: 12px;
            // }
          }
        }
      }

      &:hover {
        background-color: #050558;
        border-left: 2px solid #817eff;
        padding-left: 6%;
        color: white;
      }

      &.activelink {
        background-color: #050558;
        border-left: 2px solid #817eff;
        padding-left: 6.6%;
        color: white;
      }
    }

    // .navLink:hover {
    //     padding-right: 16px;
    //     border-left: 1px solid #817EFF;
    // }

    .dropdown_content {
      ul {
        padding-left: 4rem;

        li {
          text-align: left;
          color: white;
          cursor: pointer;

          > span {
            font-family: "Poppins", sans-serif;
            //   font-size: max(16px, 1.2vw);
            font-size: clamp(11px, 0.7vw, 20px);
            font-weight: 500;
            line-height: max(12px, 4.5vh);
            text-align: left;
            color: #9292be;
            @media screen and (max-width: 1010px) {
              font-size: 12px;
            }

            &:hover {
              color: #fff;
            }
          }

          &.subactivelink {
            > span {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .sidebar_footer {
    // position: absolute;
    bottom: 0;
    width: 100%;
    height: 9%;
    // max-height: 80px;
    // overflow-x: hidden;
    transition: 0.1s;
    // padding-bottom: 2%;
    // border: 1px solid white;
    @media screen and (min-aspect-ratio: 21/9) {
      height: 10%;
    }

    .contactus {
      // margin-bottom: 50px;

      > button {
        width: fit-content;
        height: 37px;
        // top: 745px;
        // left: 28px;
        // padding: /10px 55px 10px 55px;
        border-radius: 5px;
        border: 1px solid #817eff;
        background-color: rgba(0, 0, 0, 0);
        font-family: "Poppins", sans-serif;
        font-size: max(11px, 1.3vw);
        font-weight: 400;
        line-height: 17.4px;
        text-align: left;
        color: #ffffff;
        transition: all 0.5s;

        > i {
          color: #817eff;
          font-size: 20px;
        }
      }
    }

    .inner_wrapper {
      // padding: 20px 18px;
      background-color: #18185c;
      height: 100%;
      transition: all 0.5s;
      padding: 0 max(8px, 5%);
      // border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      .user_text_area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        transition: all 0.5s;
        overflow: hidden;
        height: 100%;

        .sidebar_profile {
          background-color: #817eff;
          border-radius: 50%;
          font-family: "Poppins", sans-serif;
          width: 5.5vh;
          height: 5.5vh;
          min-width: 33px;
          min-height: 33px;
          // padding: 9px 8px 9px 9px;
          padding: max(8px, 1.2vw);
          font-size: clamp(13px, 1.1vw, 25px);
          font-weight: 300;
          line-height: 19.2px;
          text-align: left;
          color: #ffffff;
          transition: all 0.5s;
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 50px;
          max-width: 50px;
        }

        .text_area {
          margin-left: -20px;
          transition: all 0.5s;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 1vh;
          height: 100%;
          //   margin-top: -20px;
          // border: 1px solid white;

          > p:nth-child(1) {
            font-family: "Poppins", sans-serif;
            font-size: clamp(10px, 0.8vw, 16px);
            font-weight: 400;
            //   line-height: 13.2px;
            text-align: center;
            color: #95a4fc;
            margin: 0;
            transition: all 0.5s;
            @media screen and (max-width: 1010px) {
              font-size: 10px;
            }
          }

          > p:nth-child(2) {
            font-family: "Poppins", sans-serif;
            font-size: clamp(12px, 1.05vw, 20px);
            font-weight: 500;
            line-height: 16.8px;
            text-align: left;
            color: #ffffff;
            margin: 0;
            transition: all 0.5s;
            @media screen and (max-width: 1010px) {
              font-size: 12px;
            }
          }
        }
      }
      .popup-container {
        background: white;
        position: absolute;
        top: -90px;
        height: 50%;
        width: 70%;
        // max-width: 400px;
        min-width: 150px;
        min-height: 46px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px 0 15px;
        z-index: 2000;
        .logout_profile {
          // border: 1px solid black;
          background-color: #817eff;
          border-radius: 50%;
          font-family: "Poppins", sans-serif;
          // width: 30%;
          height: 80%;
          min-width: 33px;
          min-height: 33px;
          padding: 9px 8px 9px 9px;
          font-size: clamp(10px, 0.7vw, 18px);
          font-weight: 300;
          line-height: 19.2px;
          text-align: left;
          color: #ffffff;
          transition: all 0.5s;
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 100%;
          margin-right: 10px;
          aspect-ratio: 1/1;
          // max-width: 40px;
        }
        // padding: 10px 0;
        cursor: pointer;

        @media screen and (max-height: 2000px) {
          top: -48px;
        }
        @media screen and (max-height: 1000px) {
          top: -70px;
        }
        @media screen and (max-height: 990px) {
          top: -65px;
        }
        @media screen and (max-height: 970px) {
          top: -55px;
        }
        @media screen and (max-height: 960px) {
          top: -50px;
        }
        @media screen and (max-height: 950px) {
          top: -45px;
        }
      }
    }
  }
}

.dnone {
  display: none !important;
  transition: display 0.5s !important;
}

.all_managers {
  cursor: pointer;
  padding-left: 36px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 93%;
  margin-left: 0 auto;
  padding: 13px 8px;
  margin: 10px 5px;
  gap: 10px;
  border-radius: 5px;
  .all_managers_inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 5px;
  }
  .all_managers_inner_logo {
    background-color: rgba(167, 156, 239, 0.4);
    padding: 5px;
    border-radius: 5px;
  }
}
