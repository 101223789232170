.maintenace_approval {
  width: 100%;
  // position: absolute;
  // margin-left: 324px;
  top: 0;
  overflow: auto;
  // padding: 35px 34px 30px 35px;
  padding: 20px;
  background-color: #f7f9fb;
  height: 100%;
  overflow: hidden;
  .maintenance_approvalButton {
    padding: 10px 36px;
    background: rgb(255, 91, 92, 0.9);
    border-radius: 13px;
    color: white;
    cursor: pointer;
    &:hover {
      background: rgb(255, 91, 92);
    }
  }
  .maintenance_content_wrapper {
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    padding: 20px;
    // height: calc(100% - 25px);
    height: 100%;

    .header {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 30px;
      @media screen and (max-width: 930px) {
        margin-bottom: 5px;
        height: 35px;
      }

      > h1 {
        //styleName: H1;
        font-family: "Poppins", sans-serif;
        // font-size: 32px;
        font-size: max(20px, 1.4vw);
        font-weight: 400;
        line-height: 28.8px;
        text-align: left;
        color: #000000;
        margin-bottom: 0px;
      }
    }
    .maintenace_log_wrapper {
      margin-top: 20px;
      height: calc(100% - 60px);
      .maintance_log_headwrap {
        width: 100%;
        @media screen and (max-width: 930px) {
          flex-direction: column;
          align-items: flex-start !important;
          column-gap: 20px;
        }
      }
      .balance {
        width: 400px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        gap: 10px;
        background: var(--Primary-Light, rgba(247, 249, 251, 1));
        border-radius: 10px;
        @media screen and (max-width: 930px) {
          // flex-direction: column;
          margin-bottom: 20px;
        }

        .img_con {
          display: flex;
          align-items: center;
          padding: 3px;
          gap: 5px;

          p {
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 17.4px;
            text-align: left;
            margin: 0;
          }
        }
        h1 {
          font-family: "Poppins", sans-serif;
          // font-size: 24px;
          font-size: max(20px, 1.4vw);
          font-weight: 300;
          line-height: 18px;
          text-align: left;
        }
      }
      .statusWrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
      }
      .status_main {
        display: flex;
        gap: 10px;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        background-color: #ff5b5c;
        .status {
          width: fit-content;
          height: 21px;
          padding: 2px 8px 2px 8px;
          gap: 10px;
          border-radius: 5px;
          background-color: #ffd3cf;
          //styleName: small button;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 17.4px;
          text-align: left;
          color: #2d2d2d;
        }
        span {
          width: 28px;
          height: 22px;

          padding: 3px 6px;
          gap: 10px;
          border-radius: 5px;
          // background-color: rgba(129, 126, 255, 1);
          color: black;
          text-align: center;
          margin-left: 40px;
        }
      }

      .main_content {
        height: calc(100% - 120px);
        .main_table {
          height: calc(100% - 0px);
          @media screen and (min-width: 1920px) and (min-height: 1080px) {
            tbody tr td {
              font-size: 25px;
              padding: 35px 0px;
            }
            thead th div {
              font-size: 25px !important;
              padding: 25px 0px !important;
            }
          }
          thead {
            border-bottom: 1px solid #d9d9d9;
          }
        }
      }
    }
  }
}
